import type { DateRangePickerRendererCreator } from "./types";

type PickerChangeListener = EventListenerOrEventListenerObject;
type PickerElement = HTMLInputElement;

/*
 * Creates the render and dispose functions
 * This function is called once by the connector when the widget is created and is returning
 *  - the `render` function used to render the widget
 *  - the `dispose` function used to clean the changes made by the widget
 * It can also be used to keep references of objects that must be reused between renders
 */
export const createDateRangePickerRenderer: DateRangePickerRendererCreator = ({
  container,
}) => {
  const containerNode: Element =
    typeof container === "string"
      ? document.querySelector(container)!
      : container;

  const root = document.querySelector(".date-range-pickers");
  // root.className = "date-range-picker";

  let beginListener: PickerChangeListener;
  let endListener: PickerChangeListener;

  return {
    /*
     * The render function passed to the connector
     * This function is called when we need to render the widget.
     * The render appends when:
     * - the widget is added to InstantSearch
     * - we receive new results from Algolia
     */
    render(renderOptions, isFirstRender) {
      /*
       * `renderOptions` contains all options passed by the connector to the renderer, it contains everything needed for the rendering of the component
       */

      if (isFirstRender) {
        /*
         * When the widget is rendered for the first time `isFirstRender` is set to `true`
         * This is when we will create everything that must be reused between renders (containers, event listeners, etc.)
         */
        // containerNode.appendChild(root);

        // root.innerHTML = `
        //   <div>
        //     <label class="text-2xs font-medium" for="date-range-start">Date Range Start</label>
        //     <duet-date-picker identifier="date-range-start"></duet-date-picker>
        //   </div>
        //   <div>
        //     <label class="text-2xs font-medium" for="date-range-end">Date Range End</label>
        //     <duet-date-picker identifier="date-range-end"></duet-date-picker>
        //   </div>
        // `;

        const pickers = root.querySelectorAll(".date-range-picker");
        const beginPicker = pickers[0] as PickerElement;
        const endPicker = pickers[1] as PickerElement;

        const refine = (
          beginValue: string | undefined,
          endValue: string | undefined
        ) => {
          const min = beginValue
            ? new Date(beginValue).getTime() / 1000
            : undefined;
          const max = endValue
            ? new Date(endValue).getTime() / 1000
            : undefined;
          renderOptions.refine(min || max ? [min, max] : []);
        };

        beginListener = (event) => {
          if (
            endPicker.value &&
            new Date(event.target["value"]) > new Date(endPicker.value)
          ) {
            endPicker.value = "";
            refine(event.target["value"] || undefined, undefined);
          } else {
            refine(
              event.target["value"] || undefined,
              endPicker.value || undefined
            );
          }
        };

        endListener = (event) => {
          if (
            beginPicker.value &&
            new Date(event.target["value"]) < new Date(beginPicker.value)
          ) {
            beginPicker.value = "";
            refine(undefined, event.target["value"]);
          } else {
            refine(
              beginPicker.value || undefined,
              event.target["value"] || undefined
            );
          }
        };

        $(beginPicker).on(
          "apply.daterangepicker change cancel.daterangepicker",
          beginListener.bind(this)
        );
        $(endPicker).on(
          "apply.daterangepicker change cancel.daterangepicker",
          endListener.bind(this)
        );
      }
    },
    /*
     * The dispose function passed to the connector
     * This function is called when the widget is removed from InstantSearch.
     * It must be used to remove any changes made by the render function (DOM changes, global event listeners, etc.)
     */
    dispose() {
      const pickers = root.querySelectorAll(".date-range-picker");
      pickers[0].removeEventListener("apply.daterangepicker", beginListener);
      pickers[0].removeEventListener("change", beginListener);

      pickers[1].removeEventListener("apply.daterangepicker", endListener);
      pickers[1].removeEventListener("change", endListener);

      containerNode.removeChild(root);
    },
  };
};
