import React from "react";

import { request } from "../../../../../../lib/web";
import Delete from "../../../../../components/Delete";
import EditModal from "./components/EditModal";

type Props = {
  rule: Object;
  disabled?: boolean;
  isDragging?: boolean;
  index?: number;
  dragHandleProps?: any;
  onDelete: (rule: Object) => void;
  onUpdate: (rule: Object) => void;
};

const Rule = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      rule,
      disabled,
      isDragging,
      index,
      dragHandleProps,
      onDelete,
      onUpdate,
      ...dndProps
    }: Props,
    ref
  ) => {
    const handleDelete: React.MouseEventHandler<HTMLButtonElement> = (
      event
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (onDelete) onDelete(rule);
      request(
        window.location.origin,
        `/account/routers/rules/${rule["id"]}.json`,
        {},
        "DELETE"
      ).catch((error) => {
        console.error(error);
      });
    };

    return (
      <div
        data-key={rule["id"]}
        ref={ref}
        className={`flex flex-row items-center bg-gray-100 dark:bg-gray-400 p-4 rounded-md  ${
          isDragging ? "shadow-md" : ""
        }`}
        {...dndProps}
      >
        <div
          className={`grip mr-2 text-gray-400 dark:text-gray-700 ${
            rule["fallback"] ? "invisible" : ""
          }`}
          {...dragHandleProps}
        >
          <span>
            <i className="fas fa-grip-vertical fa-2x"></i>
          </span>
        </div>
        <div className="flex flex-row flex-grow items-center justify-between">
          <div className="max-w-3/4 truncate text-lg dark:text-gray-800 font-semibold">
            {rule["name"]}
          </div>
          <div className="flex flex-row items-center space-x-7">
            <div className="relative">
              <EditModal
                disabled={disabled}
                rule={rule}
                onUpdateRule={onUpdate}
              />
            </div>
            <div className="relative">
              <Delete disabled={disabled || rule["fallback"]} onClick={handleDelete} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Rule.defaultProps = {
  disabled: false,
  isDragging: false,
  index: undefined,
  dragHandleProps: {},
};

export default Rule;
