import React, { useContext, useEffect, useState } from "react";

import { request } from "../../../../../../../../../../../../lib/web";
import Clause from "./components/Clause";
import { AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import RouterTriggerContext from "../../../../../../../../../../../contexts/RouterTriggerContext";

type Props = {
  action: string;
  condition: Object;
  index: number;
  isLast: boolean;
  onAddCondition: React.MouseEventHandler<HTMLButtonElement>;
  onEmptyClauses: (condition: Object) => void;
  onAddClause?: React.MouseEventHandler<HTMLButtonElement>;
  onRemoveClause?: (clause: Object) => void;
  onChangeClause?: (_clause: Object) => void;
  canRemoveClause?: boolean;
};

const Condition = ({ condition, index, isLast, ...props }: Props) => {
  const triggerContext = useContext(RouterTriggerContext);
  const [clauses, setClauses] = useState(condition?.["clauses"] || []);

  useEffect(() => {
    if (clauses.length === 0) {
      if (props.onEmptyClauses) props.onEmptyClauses(condition);
    }
  }, [clauses]);

  useEffect(() => {
    if (condition?.["clauses"] && !isEmpty(condition?.["clauses"])) {
      setClauses(condition?.["clauses"]);
    }
  }, [condition])

  const handleRemoveClause = (clause: Object) => {
    console.log(clause)
    request(
      window.location.origin,
      `/account/routers/rules/conditions/clauses/${clause["id"]}.json`,
      {},
      "DELETE"
    )
      .then(() => {
        if (props.onRemoveClause) props.onRemoveClause(clause);
        setClauses((prev) => prev.filter((c) => c["id"] !== clause["id"]));
      })
      .catch(console.error);
  };

  const handleAddClause: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    request(
      window.location.origin,
      `/account/routers/rules/conditions/${condition["id"]}/clauses.json`,
      {
        routers_rules_conditions_clause: {
          field: null,
          operator: null,
          value: null,
        },
      },
      "POST"
    )
      .then(({ data }: AxiosResponse) => {
        setClauses((prev) => [...prev, data]);
        if (props.onAddClause) props.onAddClause(event);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (isEmpty(clauses)) return null;

  const objectType = triggerContext.triggerType === "Salesform" ? "Lead" : "Trigger"

  return (
    <div
      data-key={condition["id"]}
      className={`space-y-6 ${index > 0 ? "py-4 border-t" : "pb-4"}`}
    >
      <div className="space-y-2">
        <div className="font-semibold">
          {index === 0 ? (
            <span>Only {props.action} {objectType.toLowerCase()} if...</span>
          ) : (
            <span>OR  {props.action} {objectType.toLowerCase()} if...</span>
          )}
        </div>
        <div className="space-y-8 lg:space-y-2">
          {clauses.map((clause, index) => (
            <Clause
              key={clause["id"]}
              clause={clause}
              onChange={props.onChangeClause}
              onRemove={props.canRemoveClause ? handleRemoveClause : undefined}
            />
          ))}
        </div>
      </div>

      <div className="buttons">
        <button
          type="button"
          className="button align-middle"
          onClick={handleAddClause}
        >
          <span className="mr-2">
            <i className="fas fa-plus" />
          </span>
          <span>And</span>
        </button>
        {isLast && (
          <button
            type="button"
            className="button align-middle"
            onClick={props.onAddCondition}
          >
            <span className="mr-2">
              <i className="fas fa-plus" />
            </span>
            <span>Or</span>
          </button>
        )}
      </div>
    </div>
  );
};

Condition.defaultProps = {
  onAddClause: () => {},
  onRemoveClause: () => {},
  canRemoveClause: true,
};

export default Condition;
