import { Controller } from "@hotwired/stimulus";
import zxcvbn from "zxcvbn";
export default class extends Controller {
  static targets = ["field", "strengthIndicator"];

  toggleVisibility(event) {
    event.preventDefault();

    const newType = this.fieldTarget.type === "password" ? "text" : "password";

    this.fieldTarget.type = newType;
  }

  estimateStrength(e) {
    const result = zxcvbn(e.target.value);

    if (result.feedback && result.feedback.warning.length > 0) {
      this.strengthIndicatorTarget.innerText = `${result.feedback.warning}.`;
      this.strengthIndicatorTarget.classList.remove("hidden");
    } else {
      this.strengthIndicatorTarget.classList.add("hidden");
    }
  }
}
