export function jsonSyntax(json) {
  // from https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript
  if (typeof json != "string") json = JSON.stringify(json, undefined, 2);

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    // .replace(/{/g, "")
    // .replace(/},*/g, "");

  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      // number
      var style = "text-yellow-500";
      if (/^""$/.test(match)) {
        // empty string
        style = "text-green-dark";
      } else if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          // key
          style = "text-red";
          match = match.replace(/"/g, "");
        } else {
          // string
          style = "text-green-dark";
        }
      } else if (/true|false/.test(match)) {
        // boolean
        style = "text-blue";
      } else if (/null/.test(match)) {
        // null
        style = "text-pink-500";
      }
      return '<span class="' + style + '">' + match + "</span>";
    }
  );
}