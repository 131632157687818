import React, { ReactNode, useCallback, useState } from "react";
import { AsyncPaginate as Select } from "react-select-async-paginate";
import { get } from "@rails/request.js";

import { SelectOptionActionMeta } from "react-select";

type Props = {
  valueString: string | undefined;
  searchPath: string;
  searchParameters?: Object;
  value: any | undefined;
  placeholder: string;
  defaultOptions?: any[] | boolean;
  components?: any;
  filterOption?: (option: any) => boolean;
  isOptionDisabled?: (option: any) => boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  formatOptionLabel?: (option: any) => ReactNode;
  onChange: (value: any, actionMeta?: SelectOptionActionMeta<any>) => void;
};

const AsyncSelect = (props: Props) => {
  const [options, setOptions] = useState([]);

  const loadOptions = async (query, _loadedOptions, { page }) => {
    const parameters = new URLSearchParams(
      Object.entries({
        limit: 10,
        page,
        query: query || "",
        ...props.searchParameters,
      })
    ).toString();

    const response = await get(`${props.searchPath}?${parameters}`);

    if (response.ok) {
      const data = await response.json;

      if (page === 0) setOptions(data.results);
      else setOptions((prev) => prev.concat(data.results));

      return {
        options: data.results,
        hasMore: data.pagination.more,
        additional: {
          page: page + (data.pagination.more ? 1 : 0),
        },
      };
    } else {
      const error = await response.json;
      console.error(error);
    }
  };

  const findOptionsByValueString = useCallback(() => {
    const optionsAreGrouped = options.some(
      (optionOrGroup) => "options" in optionOrGroup
    );
    if (optionsAreGrouped) {
      const groups = options;
      return groups.flatMap(({ options }) => options).find(matchByValueString);
    } else return options.find(matchByValueString);
  }, [options, props.valueString]);

  const matchByValueString = ({ value, id }) =>
    value === props.valueString || id === props.valueString;

  return (
    <Select
      loadOptionsOnMenuOpen
      debounceTimeout={500}
      className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300"
      classNamePrefix="react-select"
      loadOptions={loadOptions}
      styles={{
        option: (provided, { isSelected, isFocused }) => ({
          ...provided,
          backgroundColor: isSelected
            ? "#1c4cc3"
            : isFocused
            ? "#448eef"
            : "transparent",
        }),
      }}
      additional={{ page: 0 }}
      {...props}
      value={props.valueString ? findOptionsByValueString() : props.value}
    />
  );
};

AsyncSelect.defaultProps = {
  defaultOptions: true,
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  searchParameters: {},
  valueString: null,
  value: null,
  components: null,
  placeholder: "Search and select...",
};

export default AsyncSelect;
