export default (hit, { html, components }) => {
  const { obfuscated_id } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/crm_triggers/${obfuscated_id}"
    >
      <div class="space-y-3">
        <div class="font-bold">
          ${components.Highlight({
            attribute: "name",
            hit,
          })}
        </div>
      </div>
    </a>
  `;
};
