import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../../../../../../../../components/DisclosureButton";
import Condition from "./components/Condition";
import { request } from "../../../../../../../../../../lib/web";
import { AxiosResponse } from "axios";
import { useComponentDidMount } from "../../../../../../../../../hooks";
import { isEmpty } from "lodash";
import TestRun from "./components/TestRun";
import TestingPrompt from "./components/TestingPrompt";
import Notice from "../../../../../../../../../../alerts/Notice";

type Props = {
  open: boolean;
  close: () => void;
  rule: Object;
};

const Conditions = ({ open, close, ...props }: Props) => {
  const [conditions, setConditions] = useState([]);
  const [clauseCount, setClauseCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestable, setIsTestable] = useState(false);
  const [testRun, setTestRun] = useState(undefined);
  const isComponentMounted = useComponentDidMount();

  useEffect(() => {
    const clauses = conditions.flatMap(({ clauses }) => clauses);
    setClauseCount(clauses.length);
    setIsTestable(
      clauses.every((clause) => !!clause.field && !!clause.operator)
    );
    setTestRun(undefined);
  }, [conditions]);

  const fetchConditions = () => {
    setIsLoading(true);

    request(
      window.location.origin,
      `/account/routers/rules/${props.rule["id"]}/conditions.json`,
      {},
      "GET"
    )
      .then(({ data }: AxiosResponse) => {
        setIsLoading(false);
        setConditions(data);
      })
      .catch((error) => {
        setIsLoading(false);

        console.error(error);
      });
  };

  useEffect(() => {
    if (open && props.rule["id"]) {
      fetchConditions();
    }
  }, [open, props.rule]);

  const handleAddCondition: React.MouseEventHandler<HTMLButtonElement> = (
    _event
  ) => {
    request(
      window.location.origin,
      `/account/routers/rules/${props.rule["id"]}/conditions.json`,
      {},
      "POST"
    )
      .then(({ data }: AxiosResponse) => {
        setConditions((prev) => [...prev, data]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveCondition = (condition) => {
    request(
      window.location.origin,
      `/account/routers/rules/conditions/${condition["id"]}.json`,
      {},
      "DELETE"
    )
      .then(() => {
        setConditions((prev) =>
          prev.filter((c) => c["id"] !== condition["id"])
        );
      })
      .catch(console.error);
  };

  const getState = () => {
    if (testRun?.["pass"]) {
      return "SUCCESS";
    } else return "DEFAULT";
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={getState()}
        content={<span>Conditions setup {"&"} testing</span>}
      />
      <Disclosure.Panel unmount={false} className="p-8">
        {isEmpty(conditions) && isLoading && (
          <i className="fas fa-circle-notch fa-spin fa-2x" />
        )}
        <div className="space-y-4">
          {!!testRun && (
            <TestRun testRun={testRun} action={props.rule["action"]} />
          )}
          <div className="space-y-2">
            {conditions.map((condition, index) => (
              <Condition
                key={condition["id"]}
                isLast={index === conditions.length - 1}
                index={index}
                condition={condition}
                action={props.rule["action"]}
                onAddCondition={handleAddCondition}
                onAddClause={fetchConditions}
                onChangeClause={fetchConditions}
                onRemoveClause={fetchConditions}
                onEmptyClauses={handleRemoveCondition}
                canRemoveClause={clauseCount > 1}
              />
            ))}
          </div>
          {conditions
            .flatMap((cond) => cond["clauses"])
            .some((clause) => clause.operator === "is_contained_in") && (
            <Notice>
              <p>
                To enter a list, wrap comma-separated values with square brackets.
                Words and symbols &#40;like emojis, etc.&#41; must
                be written in double quotation marks, but numbers should not be.
                We recommend avoiding nested lists, although they are valid.
              </p>
              <p>Some examples:</p>
              <ul className="list-disc list-inside">
                <li>{'["United States", "Canada", "Mexico"]'}</li>
                <li>{"[1, 2, 3]"}</li>
                <li>
                  {
                    '["Thing", ["List", "of", 4, "things"], "Other, final thing"]'
                  }
                </li>
              </ul>
            </Notice>
          )}
        </div>
        {!isLoading && isTestable && !testRun && (
          <TestingPrompt ruleId={props.rule["id"]} onTestRun={setTestRun} />
        )}
      </Disclosure.Panel>
    </>
  );
};

export default Conditions;
