import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { request } from "../../../../lib/web";
import { useComponentDidMount } from "../../../hooks";
import Rule from "./components/Rule";
import RouterImport from "./components/RouterImport";

type Props = {
  router: Object;
  onEmpty: (_) => void;
};

const ALPHA = Array.from(Array(26)).map((_e, i) => i + 65);
const ALPHABET = ALPHA.map((x) => String.fromCharCode(x));

function reorder<T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
): Array<T> {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const Rules = (props: Props) => {
  const [rules, setRules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isComponentMounted = useComponentDidMount();

  const updateSortOrders = () => {
    const idsInOrder = () => rules.map((rule) => rule.id);

    request(
      window.location.origin,
      `/account/routers/${props.router?.["id"]}/rules/reorder`,
      { ids_in_order: idsInOrder() },
      "POST"
    )
      // .then(console.log)
      .catch(console.error);
  };

  useEffect(() => {
    if (isComponentMounted && rules.length > 0) {
      updateSortOrders();
    } else if (isComponentMounted && rules.length === 0) {
      if (props.onEmpty) props.onEmpty(undefined);
    }
  }, [rules]);

  const fetchRules = () => {
    setIsLoading(true);
    request(
      window.location.origin,
      `/account/routers/${props.router?.["id"]}/rules.json`,
      {},
      "GET"
    )
      .then(({ data }: AxiosResponse) => {
        setRules(data);
        setIsLoading(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (props.router?.["id"]) {
      fetchRules();
    }
  }, [props.router?.["id"]]);

  const handleCreateRule: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    request(
      window.location.origin,
      `/account/routers/${props.router?.["id"]}/rules.json`,
      { routers_rule: { name: `Rule ${ALPHABET[rules.length - 1]}` } },
      "POST"
    )
      .then((_: AxiosResponse) => {
        fetchRules();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleDeleteRule = (rule) => {
    setRules((prev) => prev.filter((r) => r.id !== rule["id"]));
  };

  const handleUpdateRule = (rule) => {
    setRules((prev) => prev.map((r) => (r["id"] === rule["id"] ? rule : r)));
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }
    if (result.destination.index === result.source.index) {
      setIsDragging(false);
      return;
    }

    setRules((prev) =>
      reorder<Object[]>(prev, result.source.index, result.destination.index)
    );

    setIsDragging(false);
  };

  return (
    <>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId="rule-list">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-4"
              >
                {rules
                  .filter((rule) => !rule["fallback"])
                  .map((rule, index) => (
                    <div key={rule.id}>
                      <Draggable
                        key={rule.id}
                        draggableId={rule.id.toString()}
                        index={index}
                        isDragDisabled={rule["fallback"]}
                      >
                        {(provided, snapshot) => (
                          <Rule
                            key={rule.id}
                            rule={rule}
                            disabled={isLoading}
                            onDelete={handleDeleteRule}
                            onUpdate={handleUpdateRule}
                            isDragging={
                              snapshot.isDragging && !snapshot.isDropAnimating
                            }
                            dragHandleProps={provided.dragHandleProps}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          />
                        )}
                      </Draggable>
                    </div>
                  ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      {rules
        .filter((rule) => rule["fallback"])
        .map((rule) => (
          <Rule
            key={rule.id}
            rule={rule}
            disabled={isLoading}
            onDelete={handleDeleteRule}
            onUpdate={handleUpdateRule}
            isDragging={false}
            dragHandleProps={{}}
            index={undefined}
            ref={undefined}
          />
        ))}

      <div className="flex flex-column items-center justify-center space-x-2">
        <button
          type="button"
          disabled={isLoading}
          className={`${
            isLoading ? "disabled opacity-50 cursor-not-allowed" : ""
          } button`}
          onClick={handleCreateRule}
        >
          {isLoading ? (
            "Loading..."
          ) : (
            <div>
              <i className="fas fa-plus mr-3" />
              {rules.find((rule) => rule["fallback"])
                ? "Add Rule"
                : "Add Fallback Rule"}
            </div>
          )}
        </button>
        <RouterImport
          router={props.router}
          disabled={isLoading}
          onUpdateRouter={undefined}
        />
      </div>
    </>
  );
};

export default Rules;
