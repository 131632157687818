import React, { ReactNode } from "react";
import { Disclosure } from "@headlessui/react";

import StateIcon from "./StateIcon";

type Props = {
  open: boolean;
  content: ReactNode;
  state: string;
};

const DisclosureButton = ({ open, state, content }: Props) => (
  <Disclosure.Button
    className={`py-2.5 pl-2.5 pr-6 flex flex-row w-full rounded-md items-center transition-colors ${
      open ? "" : "hover:bg-neutral-200 dark:hover:bg-gray-400 dark:hover:text-gray-800" 
    }`}
  >
    <span className="text-neutral-600">
      <i
        className={`${
          open ? "rotate-180" : ""
        } fas fa-chevron-down fa-lg transition-transform transform`}
      />
    </span>
    <div className="w-full text-left">
      <div className="mx-3 font-semibold text-lg">{content}</div>
    </div>
    <StateIcon state={state} />
  </Disclosure.Button>
);

export default DisclosureButton;
