import { startOfDay, endOfDay } from "date-fns/fp";
import { zonedTimeToUtc, utcToZonedTime, toDate } from "date-fns-tz";
import { format } from 'date-fns-tz'
import dayjs from "dayjs";

import type { TimeSlot } from "../types";

export const convertTimeBetweenTimeZones = (
  time: Date | string,
  srcTimeZone: string,
  dstTimeZone: string
) => {
  const utcTime = zonedTimeToUtc(time, srcTimeZone);
  const targetTime = utcToZonedTime(utcTime, dstTimeZone);
  return targetTime;
};

// Note that timezone must be the timezone of the date argument. otherwise, it may not work.
export const forceTimeZone = (date: Date | string, timeZone: string) => convertTimeBetweenTimeZones(date, timeZone, timeZone)

// Split time slot if it spans multiple days
export const splitMultiDaySlot = (slot: TimeSlot): TimeSlot[] => {
  const startDate = toDate(slot.start_at, { timeZone: slot.time_zone });
  const endDate = toDate(slot.end_at, { timeZone: slot.time_zone })

  return startDate.getDate() === endDate.getDate()
    ? [slot]
    : // Otherwise we need to split the slot
    [
      {
        ...slot,
        start_at: slot.start_at,
        end_at: format(utcToZonedTime(endOfDay(zonedTimeToUtc(slot.start_at, slot.time_zone)), slot.time_zone), "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: slot.time_zone }),
      },
      {
        ...slot,
        start_at: format(utcToZonedTime(startOfDay(zonedTimeToUtc(slot.end_at, slot.time_zone)), slot.time_zone), "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: slot.time_zone }),
        end_at: slot.end_at,
      },
    ];
};

export const shiftTimeSlotTimeZone = (
  timeSlot: TimeSlot,
  timeZone: string
): TimeSlot => ({
  start_at: format(utcToZonedTime(zonedTimeToUtc(timeSlot.start_at, timeSlot.time_zone), timeZone), "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone }),
  end_at: format(utcToZonedTime(zonedTimeToUtc(timeSlot.end_at, timeSlot.time_zone), timeZone), "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone }),
  time_zone: timeZone,
});

export const minutesToString = (minutes: number) => {
  if (minutes === 1) return `1 minute`;
  if (minutes < 60) return `${minutes} minutes`;
  if (minutes === 60) return `1 hour`;
  if (minutes % 60 === 0) return `${minutes / 60} hours`;
  else return `${Math.floor(minutes / 60)} hours, ${minutes % 60} minutes`;
};

const padTimeUnit = (value: number): string =>
  value >= 10 ? value.toString() : value.toString().padStart(2, "0");

export const padTime = (hr: number, min: number): string =>
  `${padTimeUnit(hr)}:${padTimeUnit(min)}`;

export const timeStringToTimeTuple = (value: string): number[] =>
  value.split(":").map((s) => parseInt(s, 10));

export const timeTupleToTimeString = (tuple: number[]) =>
  tuple.map(padTimeUnit).join(":");

export const compareTimeTuples = (a: number[], b: number[]): -1 | 0 | 1 => {
  if (a[0] === b[0] && a[1] === b[1]) return 0;
  if (a[0] > b[0]) return 1;
  if (a[0] < b[0]) return -1;
  if (a[1] > b[1]) return 1;
  return -1;
};

export const compareTimeStrings = (a: string, b: string) =>
  compareTimeTuples(timeStringToTimeTuple(a), timeStringToTimeTuple(b));

const ISO8601_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;

// @see https://github.com/iamkun/dayjs/issues/651#issuecomment-763033265
// decorates dayjs in order to keep the utcOffset of the given date string
// ; natively dayjs auto-converts to local time & losing utcOffset info.
export function parseZone(
  date?: dayjs.ConfigType,
  format?: dayjs.OptionType,
  locale?: string,
  strict?: boolean
) {
  if (typeof date !== "string") {
    return dayjs(date, format, locale, strict);
  }
  const match = date.match(ISO8601_OFFSET_FORMAT);
  if (match === null) {
    return;
  }
  if (match[0] === "Z") {
    return dayjs(
      date,
      {
        utc: true,
        ...(format as Object),
      },
      locale,
      strict
    );
  }
  const [, dateTime, sign, tzHour, tzMinute] = match;
  const uOffset: number = parseInt(tzHour) * 60 + parseInt(tzMinute, 10);
  const offset = sign === "+" ? uOffset : -uOffset;

  return dayjs(
    dateTime,
    {
      $offset: offset,
      ...(format as Object),
    } as dayjs.OptionType,
    locale,
    strict
  );
}

