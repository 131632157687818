import React from "react";
import Select from "react-select";

type Props = {
  crmTrigger: Object;
  options: Object[];
  value: Object;
  onChange: (value: any) => void;
};

const EventTypeSelect = ({ crmTrigger, onChange, ...props }: Props) => {
  const htmlId = `crm_trigger_crm_event_type_${crmTrigger["id"]}`;

  return (
    <Select
      {...props}
      isClearable
      isSearchable={false}
      menuPosition="fixed"
      id={htmlId}
      styles={{
        option: (provided, { isSelected, isFocused }) => ({
          ...provided,
          backgroundColor: isSelected
            ? '#1c4cc3'
            : isFocused
            ? '#448eef'
            : "transparent",
        }),
      }}
      className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
      classNamePrefix="react-select"
      onChange={onChange}
    />
  );
};

export default EventTypeSelect;
