import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["shadowField"];

  connect() {
    this.shadowFieldTarget.addEventListener("change", (event) =>
      this.dispatch("change", { detail: event })
    );
  }

  clickShadowField(event) {
    // we have to stop safari from doing what we originally expected.
    event.preventDefault();

    // then we need to manually click the hidden checkbox or radio button ourselves.
    this.shadowFieldTarget.click();
  }
}
