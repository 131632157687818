import { Controller } from "@hotwired/stimulus";
import tippy, { animateFill } from "tippy.js";
import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";

export default class extends Controller {
  static targets = ["trigger", "content"];
  static values = {
    placement: { type: String, default: "top" },
    trigger: { type: String, default: "mouseenter" },
    hideOnClick: { type: Boolean, default: true },
  };

  initialize() {
    this.trigger = this.getTrigger();
    this.content = this.getContent();
    if (this.content) {
      this.initPopup();
      this.content.style.display = "none";
    }
  }

  contentTargetConnected(element) {
    this.content = element;
    if (this.popup) {
      this.popup.setContent(this.content.innerHTML || this.content.textContent);
    }
  }

  contentTargetDisconnected(element) {}

  initPopup() {
    this.popup = tippy(this.trigger, {
      animateFill: true,
      arrow: true,
      duration: [300, 0],
      hideOnClick: true,
      trigger: this.triggerValue,
      plugins: [animateFill],
      hideOnClick: this.hideOnClickValue,
      content: this.content.innerHTML || this.content.textContent,
      placement: this.placementValue,
      allowHTML: true,
    });
  }

  getContent() {
    if (this.hasContentTarget) {
      return this.contentTarget;
    } else {
      const dataContent = this.data.get("content");
      if (!dataContent) return dataContent;

      var content = document.createElement("div");
      content.innerHTML = this.data.get("content");

      return content;
    }
  }

  getTrigger() {
    if (this.hasTriggerTarget) {
      return this.triggerTarget;
    } else {
      return this.element;
    }
  }
}
