import React, { useEffect } from "react";
import Delete from "./Delete";
import StateIcon from "./StateIcon";

type Props = {
  title: string;
  details?: string;
  iconClassName: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  deleteProps?: Object;
  isCompleted?: boolean;
  isOpen?: boolean;
};

const WizardContainer = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <div
      data-stepid={props["data-stepid"]}
      data-testid="wizard-container"
      onClick={props.onClick}
      className={`w-full bg-white dark:bg-sealBlue-400 rounded-md ring-1 ring-black ring-opacity-5 shadow h-max transition transition-all ${
        !isOpen
          ? "hover:shadow-xl cursor-pointer opacity-50 hover:opacity-100"
          : ""
      }`}
    >
      <div
        className={`bg-blue-dark rounded-md p-8 flex flex-row text-white items-center relative ${
          isOpen ? "rounded-b-none" : ""
        }`}
      >
        <div className="bg-blue-darker rounded-md w-min p-4">
          <i className={props.iconClassName}></i>
        </div>
        <div className="text-white px-2 flex-grow">
          <h2 className="text-xl font-semibold">{props.title}</h2>
          <p className="font-light leading-normal max-w-prose">
            {props.details}
          </p>
        </div>
        <div className="flex flex-row space-x-4 items-center">
          {!isOpen && (
            <div>
              <StateIcon state={props.isCompleted ? "SUCCESS" : "DEFAULT"} />
            </div>
          )}
          <div className="w-11">
            {props.deleteProps && (
              <Delete
                {...props.deleteProps}
                buttonClassName="p-2 bg-white rounded-full w-11 h-11"
              />
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="space-y-4">
          <div className="py-7 px-8 space-y-7">
            <div className="space-y-4">{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

WizardContainer.defaultProps = {
  details: "",
  isOpen: false,
  isCompleted: false,
  onClick: () => {},
  onDelete: undefined,
};

export default WizardContainer;
