import React, { ReactNode } from "react";

type Props = {
  content?: ReactNode;
  children?: ReactNode;
};

const Notice = (props: Props) => (
  <div className="p-4 bg-yellow-light text-yellow-darker border-yellow-darker mt-2 rounded-sm shadow-sm">
    {props.content || props.children}
  </div>
);

Notice.defaultProps = {
  content: null,
  children: null
}

export default Notice;
