import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { request } from "../../../../../../../../lib/web";
import { get } from "@rails/request.js";

import WizardContainer from "../../../../../../../components/WizardContainer";
import Name from "./components/Name";
import Conditions from "./components/Conditions";
import Destination from "./components/Destination";
import Action from "./components/Action";
import RouterTriggerContext from "../../../../../../../contexts/RouterTriggerContext";
import TeamIdContext from "../../../../../../../contexts/TeamIdContext";
import PostProcessing from "./components/PostProcessing";

type Props = {
  rule: Object;
  onUpdate: (data: Object) => void;
};

const RuleWizard = (props: Props) => {
  const triggerContext = React.useContext(RouterTriggerContext);
  const [rule, setRule] = useState(props.rule);

  useEffect(() => {
    setRule(props.rule);
  }, [props.rule]);

  const fetchRule = async () => {
    const response = await get(`/account/routers/rules/${rule["id"]}.json`);
    if (response.ok) {
      const json = await response.json;
      setRule(json);
    }
  };

  const hasDestination =
    rule["destination_url"] || rule["destination_membership_id"];

  const handleUpdate = (update: Object) => {
    request(
      window.location.origin,
      `/account/routers/rules/${rule["id"]}.json`,
      { routers_rule: update },
      "PUT"
    )
      .then(({ data }) => {
        if (props.onUpdate) props.onUpdate(data);
        setRule(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleActionChange = (value) => {
    if (value !== rule["action"]) {
      let params: Object = { action: value };
      if (value === "ignore")
        params = {
          ...params,
          destination_lineup_id: null,
          destination_lineup: null,
          destination_membership_id: null,
          destination_membership: null,
          destination_event_type_id: null,
        };
      handleUpdate(params);
    }
  };

  const optionsProps = () => {
    if (rule["trigger_type"] === "Salesform") {
      return {
        field: "action",
        htmlPrefix: "rule",
        value: rule?.["action"],
        onChange: handleActionChange,
        options: [
          {
            label: "Process Lead",
            value: "process",
            help: (
              <>
                If this rule is satisfied,{" "}
                <span className="font-bold">
                  Salesform will process the Lead and send them to your
                  integrations and the destination you specify.
                </span>
              </>
            ),
          },
          {
            label: "Ignore Lead",
            value: "ignore",
            help: (
              <>
                If this rule is satisfied,{" "}
                <span className="font-bold">
                  Salesform will ignore the Lead and will not send them to a
                  lineup, team member or your integrations.
                </span>{" "}
                You may optionally specify a URL that they will be redirected
                to.
              </>
            ),
          },
        ],
      };
    } else if (rule["trigger_type"] === "CrmTrigger") {
      return {
        field: "action",
        htmlPrefix: "rule",
        value: rule?.["action"],
        onChange: handleActionChange,
        options: [
          {
            label: "Process Trigger",
            value: "process",
            help: (
              <>
                If this rule is satisfied,{" "}
                <span className="font-bold">
                  Salesform will process the trigger and its data according to
                  your configuration.
                </span>
              </>
            ),
          },
          {
            label: "Ignore Trigger",
            value: "ignore",
            help: (
              <>
                If this rule is satisfied,{" "}
                <span className="font-bold">
                  Salesform will ignore the trigger and will not process its
                  data.
                </span>{" "}
              </>
            ),
          },
        ],
      };
    }
  };

  return (
    <TeamIdContext.Provider value={rule["team_id"]}>
      <RouterTriggerContext.Provider
        value={{
          trigger: rule["trigger"],
          triggerType: rule["trigger_type"],
          routerCreatePath: `/account/${rule["trigger_type"]}s/${rule["trigger_id"]}/router.json`,
        }}
      >
        <WizardContainer
          isOpen={true}
          title="Rule Configuration"
          iconClassName="fas fa-cog fa-2x"
        >
          <Disclosure as="div" defaultOpen={!rule["name"]}>
            {(disclosureProps) => (
              <Name rule={rule} onUpdate={handleUpdate} {...disclosureProps} />
            )}
          </Disclosure>
          <Disclosure as="div" defaultOpen={!hasDestination}>
            {(disclosureProps) => (
              <Action
                {...disclosureProps}
                rule={rule}
                optionsProps={optionsProps()}
              />
            )}
          </Disclosure>
          {!(
            rule["trigger_type"] === "CrmTrigger" && rule["action"] === "ignore"
          ) && (
            <Disclosure as="div" defaultOpen={!hasDestination}>
              {(disclosureProps) => (
                <Destination
                  {...disclosureProps}
                  rule={rule}
                  onUpdate={handleUpdate}
                />
              )}
            </Disclosure>
          )}
          {rule["trigger_type"] === "Salesform" &&
            rule["action"] === "process" && (
              <Disclosure
                as="div"
                defaultOpen={!rule["post_processing_confirmed"]}
              >
                {(disclosureProps) => (
                  <PostProcessing
                    {...disclosureProps}
                    rule={rule}
                    fetchRule={fetchRule}
                    handleRuleUpdate={handleUpdate}
                  />
                )}
              </Disclosure>
            )}
          {!rule["fallback"] && (
            <Disclosure as="div" defaultOpen>
              {(disclosureProps) => (
                <Conditions {...disclosureProps} rule={rule} />
              )}
            </Disclosure>
          )}
        </WizardContainer>
      </RouterTriggerContext.Provider>
    </TeamIdContext.Provider>
  );
};

export default RuleWizard;
