import React from "react";
import MembershipSettingsModal from "./components/MembershipSettingsModal";

type Props = {
  membership: Object;
  index: number;
  isRoundRobin: boolean;
  onMembershipUpdate: (membership: Object) => void;
  onMembershipRemove: (membership: Object) => void;
};

const Membership = (props: Props) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleMembershipClick = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const isUpNext =
    props.isRoundRobin &&
    props.index === 0 &&
    props.membership["active"] &&
    props.membership["has_calendar_integration"];
  const isOnDeck =
    props.isRoundRobin &&
    props.index === 1 &&
    props.membership["active"] &&
    props.membership["has_calendar_integration"];

  const integrationMessage = () => {
    if (!props.membership["has_calendar_integration"])
      return "(Calendar not connected)";
    else return "";
  };

  const activeMessage = () => {
    if (!props.membership["active"]) return "(Not available)";
    else return "";
  };

  const roundRobinMessage = () => {
    if (isUpNext) {
      return "(Up Next)";
    } else if (isOnDeck) {
      return "(On Deck)";
    }
    return "";
  };

  const roundRobinIcon = () => {
    if (isUpNext) {
      return "fas fa-star";
    } else if (isOnDeck) {
      return "far fa-star";
    }
  };

  return (
    <>
      <div
        onClick={handleMembershipClick}
        className={`relative p-2 mr-3 ${
          !props.membership["active"] ? "opacity-50" : ""
        }`}
        data-controller="tooltip"
      >
        <div className="relative align-center inline-block">
          <img
            src={props.membership["profile_photo_url"]}
            className="h-12 w-12 rounded-full"
          />
        </div>
        {!props.membership["has_calendar_integration"] && (
          <div className="bg-white text-red text-center rounded-full border w-6 h-6 absolute bottom-0 right-0 left-0 mr-auto ml-auto">
            <i className="fas fa-exclamation"></i>
          </div>
        )}
        {props.isRoundRobin &&
          props.membership["active"] &&
          props.membership["has_calendar_integration"] &&
          (isUpNext || isOnDeck) && (
            <div className="bg-white text-yellow-dark text-center rounded-full border w-6 h-6 absolute bottom-0 right-0 left-0 mr-auto ml-auto">
              <i className={roundRobinIcon()}></i>
            </div>
          )}
        <span className="hidden" data-tooltip-target="content">
          {props.membership["full_name"]}{" "}
          {integrationMessage() || roundRobinMessage() || activeMessage()}
        </span>
      </div>
      <MembershipSettingsModal
        membership={props.membership}
        onMembershipUpdate={props.onMembershipUpdate}
        onMembershipRemove={props.onMembershipRemove}
        isOpen={modalOpen}
        onClose={handleModalClose}
      />
    </>
  );
};

export default Membership;
