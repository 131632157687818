import React from "react";
import { SelectOptionActionMeta } from "react-select";

import TeamIdContext from "../../../../../../../../../../contexts/TeamIdContext";
import AsyncSelect from "../../../../../../../../../../components/AsyncSelect";

type Props = {
  value: any;
  onChange: (value: any, actionMeta?: SelectOptionActionMeta<any>) => void;
  isOptionDisabled?: (option: any) => boolean;
  filterOption?: (option: any) => boolean;
  placeholder?: string;
};

const MembershipSelect = (props: Props) => {
  const teamId = React.useContext(TeamIdContext);
  return (
    <AsyncSelect
      searchPath={`/account/teams/${teamId}/memberships/search`}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      {...props}
    />
  );
};

MembershipSelect.defaultProps = {
  placeholder: "Choose team member...",
};

export default MembershipSelect;
