import React from "react";
import { SelectOptionActionMeta } from "react-select";

import AsyncSelect from "../../../../components/AsyncSelect";
import TeamIdContext from "../../../../contexts/TeamIdContext";

type Props = {
  onSelectOption: (data: Object) => void;
  filterOption: (option: Object) => boolean;
};

const MembershipSelect = ({ onSelectOption, filterOption }: Props) => {
  const teamId = React.useContext(TeamIdContext);

  const handleChange = (
    selection,
    { action }: SelectOptionActionMeta<Object>
  ) => {
    switch (action) {
      case "select-option":
        if (onSelectOption) onSelectOption(selection);
      default:
        return;
    }
  };

  return (
    <AsyncSelect
      value={null}
      searchPath={`/account/teams/${teamId}/memberships/search`}
      onChange={handleChange}
      filterOption={filterOption}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      placeholder="Choose team members for this lineup..."
    />
  );
};

export default MembershipSelect;
