import React from "react";

type Props = {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Edit = (props: Props) => {
  return (
    <button
      type={`button`}
      disabled={props.disabled}
      className={`${
        props.disabled ? "disabled opacity-30 cursor-not-allowed" : ""
      }`}
      data-controller="tooltip"
      aria-label="Edit"
      onClick={props.onClick}
    >
      <span className="text-blue-500">
        <i className="fal fa-edit fa-2x"></i>
      </span>
      <span className="hidden" data-tooltip-target="content">
        Edit
      </span>
    </button>
  );
};

Edit.defaultProps = {
  onClick: () => {},
  disabled: false,
};

export default Edit;
