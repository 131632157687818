import { Controller } from "@hotwired/stimulus";

import { connectSearchBox } from "instantsearch.js/es/connectors";
import { infiniteHits, stats } from "instantsearch.js/es/widgets";
import { dateRangePicker } from "./widgets/date_range_picker";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import {
  instantSearchRouter,
  initInstantSearch,
} from "./lib/initInstantSearch";
import initAutocomplete from "./lib/initAutocomplete";
import Templates from "./templates";

import { csrf } from "../../packs/lib/web";

export default class extends Controller {
  static values = {
    teamId: String,
    indexName: String,
    searchboxPlaceholder: String,
    dateRangePickerAttr: String,
    moreFilters: String,
  };

  widgets() {
    const indexName = this.indexNameValue;
    const virtualSearchBox = connectSearchBox(() => {});
    const widgets = [
      virtualSearchBox({}),
      stats({
        container: "#stats",
        templates: {
          text(data, { html }) {
            let count = "";

            if (data.hasManyResults) {
              count += `${data.nbHits} results `;
            } else if (data.hasOneResult) {
              count += `1 result `;
            } else {
              count += `no result `;
            }

            return html`
              <span>
                <span class="font-bold">${count}</span>
                found in ${data.processingTimeMS}ms
              </span>
            `;
          },
        },
      }),
      infiniteHits({
        container: "#hits",
        cssClasses: {
          item: ["mb-2", "dark:bg-sealBlue-400", "dark:text-white"],
          loadMore: ["dark:text-white", "dark:bg-sealBlue-300"],
          disabledLoadMore: [
            "dark:disabled:text-gray-400",
            "dark:disabled:bg-sealBlue-500",
          ],
        },
        templates: {
          empty(_results, { html }) {
            return html`<div class="shadow-sm text-lg bg-white p-6 h-full">
                No results.
              </div>
              .`;
          },
          item(hit, { html, components }) {
            return Templates[indexName](hit, { html, components });
          },
        },
      }),
    ];

    if (
      document.querySelector("#date-range") &&
      this.dateRangePickerAttrValue
    ) {
      widgets.push(
        dateRangePicker({
          container: "#date-range",
          attribute: this.dateRangePickerAttrValue,
        })
      );
    }

    return widgets;
  }

  connect() {
    const indexName = this.indexNameValue;

    fetch(
      `/account/teams/${this.teamIdValue}/secured_algolia_search_api_key${
        this.moreFiltersValue ? `?more_filters=${this.moreFiltersValue}` : ""
      }`
    )
      .then((response) => response.json())
      .then((auth) => {
        this.auth = auth;
        const now = new Date().getTime();
        setTimeout(() => {
          window.location.reload();
        }, this.auth.valid_until * 1000 - now);

        this.search = initInstantSearch({ indexName, auth });

        this.search.addWidgets(this.widgets());

        this.search.start();

        initAutocomplete({
          search: this.search,
          indexName,
          instantSearchRouter,
          placeholder: this.searchboxPlaceholderValue,
        });
      });
  }

  destroyApiKey() {
    if (this.auth)
      fetch(
        `/account/teams/${this.teamIdValue}/destroy_secured_algolia_search_api_key`,
        {
          method: "post",
          body: JSON.stringify({
            api_key: this.auth.api_key,
          }),
          headers: {
            "X-CSRF-Token": csrf(),
            "Content-Type": "application/json",
          },
        }
      );
  }

  disconnect() {
    this.destroyApiKey();
  }
}
