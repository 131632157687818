import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";

import { request } from "../../lib/web";
import WizardContainer from "../components/WizardContainer";
import Name from "./components/Name";
import Memberships from "./components/Memberships";
import TeamIdContext from "../contexts/TeamIdContext";

type Props = {
  onContainerClick: React.MouseEventHandler<HTMLDivElement>;
  isContainerOpen?: boolean;
  isCompleted?: boolean;
  teamId: string;
  lineup?: Object;
  lineupId?: string;
  onLineupChange?: (lineup: Object) => void;
};

const LineupWizard = (props: Props) => {
  const [lineup, setLineup] = useState(props.lineup);
  const [isLoading, setIsLoading] = useState(false);

  const isNewLineup = () => !lineup?.["id"];

  const fetchLineup = () => {
    setIsLoading(true);
    request(
      window.location.toString(),
      `/account/lineups/${props.lineupId}.json`,
      {},
      "GET"
    )
      .then(({ data }) => {
        setLineup(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // const url = new URL("../../../teams/${props.teamId}/salesforms/sync", window.location.href);
        // window.history.replaceState(window.history.state, document.title, url);
        setIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setLineup(props.lineup);
  }, [props.lineup]);

  useEffect(() => {
    if (props.lineupId) {
      fetchLineup();
    }
  }, [props.lineupId]);

  const createLineup = (params) => {
    request(
      window.location.toString(),
      `/account/teams/${props.teamId}/lineups.json`,
      { lineup: params },
      "POST"
    )
      .then(({ data }) => {
        setLineup(data);
        afterLineupCreated(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // const url = new URL("../../../teams/${props.teamId}/salesforms/sync", window.location.href);
        // window.history.replaceState(window.history.state, document.title, url);
        setIsLoading(false);
        console.error(error);
      });
  };

  const updateLineup = (updateParams: Object) => {
    request(
      window.location.toString(),
      `/account/lineups/${lineup["id"]}.json`,
      { lineup: updateParams },
      "PUT"
    )
      .then(({ data }) => {
        if (props.onLineupChange) props.onLineupChange(data);
        setLineup(data);
      })
      .catch(console.error);
  };

  const deleteLineup = () => {
    if (lineup) {
      request(
        window.location.toString(),
        `/account/lineups/${lineup["id"]}.json`,
        {},
        "DELETE"
      ).catch(console.error);
    }
  };

  const afterLineupCreated = (data) => {
    if (props.onLineupChange) props.onLineupChange(data);
    else setLineup(data);
    const url = new URL(
      window.location.href.includes(`/account/teams/${props.teamId}/lineups`)
        ? `../../../lineups/${data.id}`
        : `./${data.id}`,
      window.location.href
    );

    window.history.replaceState(window.history.state, document.title, url);
  };

  return (
    <TeamIdContext.Provider value={props.teamId}>
      <div className="flex flex-col items-center justify-center">
        {isLoading && !lineup ? (
          <i className="fas fa-circle-notch fa-spin fa-3x m-auto"></i>
        ) : (
          <>
            <WizardContainer
              title="Lineup"
              details={
                "Configure a lineup of team members for scheduling meetings and assigning inbound."
              }
              iconClassName="fas fa-users-class fa-2x"
              onClick={props.onContainerClick}
              isCompleted={props.isCompleted}
              isOpen={props.isContainerOpen}
              // deleteProps={{
              //   tooltipContent: "Delete Lineup",
              //   onClick: deleteLineup,
              // }}
              data-stepid="lineup-wizard"
            >
              <Disclosure
                as="div"
                defaultOpen={
                  !lineup?.["name"] &&
                  (lineup?.["can_current_user_edit"] || isNewLineup())
                }
              >
                {(disclosureProps) => (
                  <Name
                    {...disclosureProps}
                    open={
                      lineup?.["can_current_user_edit"] || isNewLineup()
                        ? disclosureProps.open
                        : false
                    }
                    lineup={lineup}
                    onSubmit={lineup?.["id"] ? updateLineup : createLineup}
                  />
                )}
              </Disclosure>
              {lineup?.["name"] && (
                <Disclosure as="div" defaultOpen>
                  {(disclosureProps) => (
                    <Memberships
                      {...disclosureProps}
                      lineup={lineup}
                      onUpdateLineup={updateLineup}
                      onAddMembership={updateLineup}
                      onRemoveMembership={fetchLineup}
                      onUpdateMembership={fetchLineup}
                    />
                  )}
                </Disclosure>
              )}
            </WizardContainer>
          </>
        )}
      </div>
    </TeamIdContext.Provider>
  );
};

LineupWizard.defaultProps = {
  lineup: undefined,
  lineupId: undefined,
  isCompleted: true,
  isContainerOpen: true,
  onContainerClick: () => {},
};

export default LineupWizard;
