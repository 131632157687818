import { AxiosResponse } from "axios";
import React, { useState } from "react";

type Props = {
  onButtonClick?: () => Promise<AxiosResponse<any>>;
  afterButtonClick?: () => void;
  showArrow?: boolean;
  buttonIcon?: string;
  buttonTooltip?: string;
};

const WizardStepArrow = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onButtonClick) {
      setIsLoading(true);
      props
        .onButtonClick()
        .then(() => {
          if (props.afterButtonClick) props.afterButtonClick();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-0.5 bg-gray-400 h-3" />
      {props.showArrow ? (
        <>
          <div className="w-0.5 bg-gray-400 h-8" />
          <div className="text-gray-400 -my-1.5 z-10">▼</div>
        </>
      ) : (
        <button
          type="button"
          data-controller="tooltip"
          data-tooltip-placement-value="bottom"
          onClick={handleButtonClick}
          className="h-8 w-8 transition duration-500 ease-in-out transform hover:scale-150 bg-white shadow rounded-full flex-col items-center justify-center border border-gray-400"
        >
          <span className="h-full w-full align-middle text-center text-gray-900">
            {isLoading ? (
              <i className="fal fa-circle-notch fa-spin fa-1x"></i>
            ) : (
              <i className={`fal ${props.buttonIcon} fa-1x`}></i>
            )}
          </span>
          <span className="hidden" data-tooltip-target="content">
            {props.buttonTooltip}
          </span>
        </button>
      )}
    </div>
  );
};

WizardStepArrow.defaultProps = {
  showArrow: true,
  buttonIcon: "",
  buttonTooltip: "",
};

export default WizardStepArrow;
