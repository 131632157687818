import React from "react";
import ReactDOM from "react-dom";
import TimeZoneSelect from "react-timezone-select";
import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";

import { timeZone } from "../../packs/lib/clock";

export default class extends Controller {
  static values = {
    selectedDate: String,
    timeZone: { type: String, default: timeZone() },
  };

  initialize() {
    this.initTimeZoneSelect();
  }

  connect() {
    this.renderTimeZoneSelect();
  }

  disconnect() {
    this.tearDownTimeZoneSelect();
  }

  timeZoneValueChanged(tzValue, prev) {
    if (!prev) return;
    if (this.selectedDateValue) {
      const update = dayjs(this.selectedDateValue).tz(tzValue, true);
      this.changeDate(update);
    }
    this.renderTimeZoneSelect();
  }

  getSelectedDate() {
    const searchParams = new URLSearchParams(location.search);
    const dateString = searchParams.get("date");
    if (dateString) {
      // todo some extra validation maybe.
      const utcOffsetAsDate = dayjs(dateString.slice(-4), "Hmm");
      const utcOffset = parseInt(
        dateString.substr(-5, 1) +
          (utcOffsetAsDate.hour() * 60 + utcOffsetAsDate.minute())
      );

      const day = dayjs(dateString.substr(0, 10)).utcOffset(utcOffset, true);
      return day.isValid() ? day.toISOString() : undefined;
    }
    return undefined;
  }

  changeDate(update) {
    const spec = "YYYY-MM-DD[T]HH:mm:ssZZ";
    console.log(update.format(spec));
    console.log(dayjs(this.selectedDateValue).format(spec));

    const url = new URL(location.href);
    const param = update.format(spec);
    url.searchParams.set("date", param);
    Turbo.visit(url.href, { action: "replace" });
  }

  initTimeZoneSelect() {
    if (this.data.get("initialized")) return;
    this.selectedDateValue = this.getSelectedDate();
    this.renderTimeZoneSelect();
    this.data.set("initialized", true);
  }

  tearDownTimeZoneSelect() {}

  renderTimeZoneSelect() {
    // Add the react-timezone-select
    const reactTimeZoneSelects = document.querySelectorAll(
      ".react_time_zone_select"
    );

    const handleChange = (tz) => {
      const selectedTimeZone = tz["value"] || tz;
      this.timeZoneValue = timeZone(selectedTimeZone);
    };

    Array.from(reactTimeZoneSelects).forEach((element) => {
      ReactDOM.render(
        <TimeZoneSelect
          value={timeZone()}
          onChange={handleChange}
          labelStyle="abbrev"
          className="react-select-container rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
          classNamePrefix="react-select"
          styles={{
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              backgroundColor: isSelected
                ? "#1c4cc3"
                : isFocused
                ? "#448eef"
                : "transparent",
            }),
          }}
        />,
        element
      );
    });
  }
}
