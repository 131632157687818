import React, { useContext } from "react";
import Select, { ActionMeta, components, OptionProps } from "react-select";
import { isEmpty, get, isArray, isBoolean } from "lodash";

import {
  ConditionsClause,
  ClauseVariableOption,
  ClauseVariableOptionGroup,
} from "../../../../../../../../../../../../../../../types";
import RouterTriggerContext from "../../../../../../../../../../../../../../contexts/RouterTriggerContext";

type Props = {
  clause: Object;
  options: ClauseVariableOptionGroup[];
  value: ClauseVariableOption;
  isLoading: boolean;
  onChange: (value: any) => void;
};

const Option = (props: OptionProps<any>) => {
  const triggerContext = useContext(RouterTriggerContext);
  const sample = JSON.parse(triggerContext.trigger?.["sample_data"]);
  const access = props.data.value;
  const preview = get(sample, access);

  const formatPreview = (preview: any) => {
    if (isArray(preview)) {
      return preview.join(", ");
    } else if (isBoolean(preview)) {
      return `${preview}`;
    } else if (!preview) {
      return "<no data>";
    } else {
      return preview;
    }
  };

  return (
    <components.Option {...props}>
      {props.children}
      {!isEmpty(sample) && (
        <>
          <span>: </span>
          <pre>{formatPreview(preview)}</pre>
        </>
      )}
    </components.Option>
  );
};

const FieldSelect = ({ clause, onChange, ...props }: Props) => {
  const htmlId = `routers/rules/conditions/clause_variable_${clause["id"]}`;

  const handleChange = (
    selection: ClauseVariableOption,
    _action: ActionMeta<ClauseVariableOption>
  ) => {
    if (onChange) onChange(selection);
  };

  const validationClasses = () => (props.value ? "" : "invisible");

  const validationContent = () =>
    `${props.value.value.includes(".trackers.") ? `Tracker` : `Their`} ${
      props.value.label
    } (${props.value.type})`;

  return (
    <div>
      <Select
        {...props}
        isClearable
        isSearchable
        components={{ Option }}
        // menuPosition=
        id={htmlId}
        className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300"
        classNamePrefix="react-select"
        placeholder="Choose field..."
        styles={{
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            backgroundColor: isSelected
              ? "#1c4cc3"
              : isFocused
              ? "#448eef"
              : "transparent",
          }),
        }}
        onChange={handleChange}
      />
      {props.value?.label && (
        <div
          className={`w-full m-auto mt-1 border rounded-md px-3 text-grey text-center ${validationClasses()}`}
        >
          {validationContent()}
        </div>
      )}
    </div>
  );
};

FieldSelect.defaultProps = {
  options: [],
  value: undefined,
  onChange: undefined,
};

export default FieldSelect;
