import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { routerId: String };

  connect() {
    this.dispatch("connect", { detail: { routerId: this.routerIdValue } });
    this.element
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => {
        checkbox.addEventListener("change", (_event) =>
          this.dispatch("checkboxChange")
        );
      });
  }

  disconnect() {
    this.dispatch('disconnect')
  }
}
