import { Controller } from "@hotwired/stimulus";

import consumer from "../channels/consumer";

export default class extends Controller {
  static values = { channel: String, params: Object };
  static targets = ["content"];

  subscribeToChannel(callbacks) {
    consumer.subscriptions.create(
      {
        channel: this.channelValue,
        ...this.paramsValue,
      },
      callbacks
    );
  }

  contentTargetConnected(element) {
    this.subscribeToChannel({
      received: (data) => {
        element.innerHTML = data;
      },
    });
  }
}
