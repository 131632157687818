import React, { ReactNode, useCallback, useState } from "react";
import { SelectOptionActionMeta } from "react-select";
import Createable from "react-select/creatable";
import { withAsyncPaginate } from "react-select-async-paginate";
import { get } from "@rails/request.js";

const Select = withAsyncPaginate(Createable);

type Props = {
  valueString?: string | undefined;
  nullOption?: string | boolean;
  searchPath: string;
  searchParameters?: Object;
  value?: any | undefined;
  placeholder: string;
  allowCreateWhileLoading?: boolean;
  createOptionPosition?: "first" | "last";
  defaultOptions?: any[] | boolean;
  isDisabled: boolean;
  components?: any;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  isValidNewOption?: (inputValue: string) => boolean;
  getNewOptionData?: (inputValue: string) => any;
  onCreateOption?: (inputValue: string) => void;
  filterOption?: (option: any) => boolean;
  isOptionDisabled?: (option: any) => boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => string;
  formatOptionLabel?: (option: any) => ReactNode;
  onChange: (value: any, actionMeta?: SelectOptionActionMeta<any>) => void;
};

const CreateableAsyncSelect = (props: Props) => {
  const [options, setOptions] = useState([]);
  const [cacheUniq, setCacheUniq] = useState(0);

  const loadOptions = async (query, _loadedOptions, { page }) => {
    const parameters = new URLSearchParams(
      Object.entries({
        limit: 10,
        page,
        query: query || "",
        ...props.searchParameters,
      })
    ).toString();

    const response = await get(`${props.searchPath}?${parameters}`);

    if (response.ok) {
      const data = await response.json;

      if (page === 0) setOptions(data.results);
      else setOptions((prev) => prev.concat(data.results));

      return {
        options: data.results,
        hasMore: data.pagination.more,
        additional: {
          page: page + (data.pagination.more ? 1 : 0),
        },
      };
    } else {
      const error = await response.json;
      console.error(error);
    }
  };

  const findOptionsByValueString = useCallback(() => {
    const optionsAreGrouped = options.some(
      (optionOrGroup) => "options" in optionOrGroup
    );
    if (optionsAreGrouped) {
      const groups = options;
      return groups.flatMap(({ options }) => options).find(matchByValueString);
    } else return options.find(matchByValueString);
  }, [options, props.valueString]);

  const matchByValueString = ({ value, id }) =>
    value === props.valueString || id === props.valueString;

  const handleCreateOption = useCallback(async (inputValue: string) => {
    if (props.onCreateOption) await props.onCreateOption(inputValue);
    setCacheUniq((prev) => prev + 1);
  }, []);

  return (
    <Select
      loadOptionsOnMenuOpen
      className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300"
      classNamePrefix="react-select"
      loadOptions={loadOptions}
      styles={{
        option: (provided, { isSelected, isFocused }) => ({
          ...provided,
          backgroundColor: isSelected
            ? "#1c4cc3"
            : isFocused
            ? "#448eef"
            : "transparent",
        }),
      }}
      additional={{ page: 0 }}
      debounceTimeout={500}
      cacheUniqs={[cacheUniq]}
      {...props}
      onCreateOption={handleCreateOption}
      value={props.valueString ? findOptionsByValueString() : props.value}
    />
  );
};

CreateableAsyncSelect.defaultProps = {
  allowCreateWhileLoading: false,
  createOptionPosition: "first",
  defaultOptions: true,
  isDisabled: false,
  formatCreateLabel: (inputValue: string) => `Create "${inputValue}"`,
  isValidNewOption: (inputValue: string) => true,
  getNewOptionData: undefined,
  onCreateOption: undefined,
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  nullOption: false,
  valueString: null,
  components: null,
  searchParameters: {},
  value: null,
  placeholder: "Search and select...",
};

export default CreateableAsyncSelect;
