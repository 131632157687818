import React, { useCallback, useEffect, useState } from "react";
import linkCRM from "@xkit-co/crm-link.js";
import I18n from "../../../javascript/i18n-js/index.js.erb"

const csrf = () => {
  return document
    .querySelector("meta[name='csrf-token']")
    .getAttribute("content");
};

const getToken = async (teamID) => {
  const options = { method: "POST", headers: { "X-CSRF-Token": csrf() } };
  const response = await fetch(
    `/account/teams/${teamID}/xkit/context`,
    options
  );
  const {
    context: { access_token: token },
    connection_id: connectionID,
  } = await response.json();

  return [token, connectionID];
};

const getObjects = async (teamID, slug) => {
  const options = { method: "GET", headers: { "X-CSRF-Token": csrf() } };
  const response = await fetch(
    `/account/teams/${teamID}/xkit/mapping${slug ? `?slug=${slug}` : ``}`,
    options
  );
  const { objects } = await response.json();

  return objects;
};

const createConnection = async (teamID, connectionID, slug) => {
  const body = JSON.stringify({ connection_id: connectionID, slug });
  const headers = {
    "X-CSRF-Token": csrf(),
    "Content-Type": "application/json",
  };
  const options = { method: "POST", body: body, headers: headers };

  const response = await fetch(
    `/account/teams/${teamID}/xkit/connection`,
    options
  );
  const {
    connection: { connection_id },
  } = await response.json();

  return connection_id;
};

const CrmLink = ({ teamID, platformDomain, ...props }) => {
  const [connectionID, setConnectionID] = useState(null);
  const [token, setToken] = useState(null);

  const handleClick = useCallback(async () => {
    try {
      const objects = await getObjects(teamID, props.slug);
      // if `connectionID` is null, it's the first time we setup the CRM and `linkCRM` will return a new one
      // if it exists, `linkCRM` returns the same ID
      const newOrExistingConnectionID = await linkCRM(platformDomain, token, {
        objects: objects,
        connectionID: connectionID,
        ...(props.slug ? { slug: props.slug } : {}),
      });

      // make sure we stored the ID correctly
      const storedConnectionID = await createConnection(
        teamID,
        newOrExistingConnectionID,
        props.slug
      );
      setConnectionID(storedConnectionID);
      if (!!props.afterClick) props.afterClick();
    } catch (error) {
      console.error(error);
    }
  }, [teamID, platformDomain, connectionID, token]);

  useEffect(() => {
    const fetch = async () => {
      await getObjects(teamID, props.slug); // pre-emptively fetch crm schema
      const [token, connectionID] = await getToken(teamID);
      setConnectionID(connectionID);
      setToken(token);
    };

    void fetch();
  }, [teamID, platformDomain]);

  const buttonText =
    props.buttonText ||
    (connectionID === null
      ? I18n.t("teams.edit.crm.create")
      : I18n.t("teams.edit.crm.configure"));
  return (
    <button onClick={handleClick} className="button">
      {buttonText}
    </button>
  );
};

export default CrmLink;
