import React from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../components/DisclosureButton";
import Switch from "../../components/Switch";

type Props = {
  open: boolean;
  close: () => void;
  onChange: (value: any) => void;
  crmTrigger: Object;
};

const Activate = ({ open, close, ...props }: Props) => {
  return (
    <>
      <DisclosureButton
        open={open}
        state={props.crmTrigger["active"] ? "SUCCESS" : "DEFAULT"}
        content={
          <span>
            Status:{" "}
            <span className="italic font-normal">
              {props.crmTrigger["active"] ? "Active" : "Disabled"}
            </span>
          </span>
        }
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <Switch
          defaultEnabled={props.crmTrigger["active"]}
          onChange={props.onChange}
          screenReader={
            props.crmTrigger["active"]
              ? `Disable ${props.crmTrigger['name']}`
              : `Activate ${props.crmTrigger['name']}`
          }
        />
      </Disclosure.Panel>
    </>
  );
};

export default Activate;
