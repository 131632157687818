import React, { useState } from "react";
import { AxiosResponse } from "axios";

import { request } from "../../../../../../../../../../../lib/web";

type Props = {
  ruleId: string;
  onTestRun: (testRun: any) => void;
};

const TestingPrompt = (props: Props) => {
  const [isTesting, setIsTesting] = useState(false);

  const handleTestLogic = () => {
    setIsTesting(true);
    request(
      window.location.origin,
      `/account/routers/rules/${props["ruleId"]}/test_runs.json`,
      { routers_rules_test_run: {} },
      "POST"
    )
      .then(({ data }: AxiosResponse) => {
        setIsTesting(false);
        if (props.onTestRun) props.onTestRun(data);
      })
      .catch((error) => {
        setIsTesting(false);
        console.error(error);
      });
  };

  return (
    <div className="border-t border-blue-300 bg-blue-100  dark:bg-blue-700 dark:border-blue-800 py-5 px-7 mt-4 -mx-16 -mb-15 rounded-b-md space-y-6">
      <div>
        <p className="text-lg font-medium">
          Nice work - now let's test it out!
        </p>
        <p>We'll test these conditions against the example data we received.</p>
      </div>
      <div>
        <button
          type="button"
          disabled={isTesting}
          className={`${
            isTesting ? "disabled opacity-50 cursor-not-allowed" : ""
          } button`}
          onClick={handleTestLogic}
        >
          {!isTesting ? (
            "Continue"
          ) : (
            <i className="fas fa-circle-notch fa-spin fa-2x" />
          )}
        </button>
      </div>
    </div>
  );
};

export default TestingPrompt;
