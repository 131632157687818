import React from "react";
import ReactDOM from "react-dom";

export default function mount(components = {}) {
  ["turbo:load", "turbo:frame-load"].forEach((eventType) =>
    document.addEventListener(eventType, (event) => {
      const mountPoints = (event.target as HTMLElement).querySelectorAll(
        "[data-react-component]"
      );
      mountPoints.forEach((mountPoint) => {
        const dataset = (mountPoint as HTMLElement).dataset;
        const componentName = dataset["reactComponent"];
        const Component = components[componentName];
        if (Component) {
          const props = JSON.parse(dataset["props"]);
          ReactDOM.render(<Component {...props} />, mountPoint);
        } else {
          console.error(
            "WARNING: No component found for: ",
            dataset.reactComponent,
            components
          );
        }
      });
    })
  );
}
