import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element
      .querySelectorAll(".wizard")
      .forEach((wizard, index, wizards) => {
        // all wizards on the page besides the current one at index
        const otherWizards = [...wizards].filter((_, i) => i !== index);

        // when this current wizard at index is clicked
        wizard.addEventListener("click", (event) => {
          // if it is being opened
          if (event.currentTarget.dataset.wizardIsOpenValue === "false")
            // close all other wizards
            otherWizards.forEach((wizard) =>
              wizard.setAttribute("data-wizard-is-open-value", false)
            );
        });
      });
  }
}
