import React, { useContext } from "react";
import { ActionMeta, components, OptionProps } from "react-select";
import { isEmpty, get, isArray } from "lodash";

import RouterTriggerContext from "../../../../../../../../../../../../contexts/RouterTriggerContext";
import CreateableAsyncSelect from "../../../../../../../../../../../../components/CreateableAsyncSelect";

type Props = {
  postProcessing: Object;
  searchPath: string;
  modelType: string;
  onChange: (value: any) => void;
};

const Option = (props: OptionProps<any>) => {
  const triggerContext = useContext(RouterTriggerContext);
  const sample = JSON.parse(triggerContext.trigger?.["sample_data"]);
  const access = props.data.value.replace("__sfrm_prospect.", "");
  const preview = get(sample, access);

  const formatPreview = (preview: any) =>
    isArray(preview) ? preview.join(", ") : preview;

  return (
    <components.Option {...props}>
      {props.children}
      {!isEmpty(sample) && props.data.value.startsWith("__sfrm_prospect.") && (
        <>
          <span>: </span>
          <pre>{formatPreview(preview) || "<no data>"}</pre>
        </>
      )}
    </components.Option>
  );
};

const ValueSelect = ({ postProcessing, onChange, ...props }: Props) => {
  const htmlId = `routers/rules/post_processing/value_${postProcessing?.["id"]}`;

  const handleChange = (selection: Object, _action: ActionMeta<Object>) => {
    if (onChange && postProcessing?.["value"] !== selection["value"])
      onChange(selection);
  };

  return (
    <div>
      <CreateableAsyncSelect
        onChange={handleChange}
        isDisabled={!postProcessing}
        getOptionLabel={(option) => option.text || option.label}
        getOptionValue={(option) => option.id}
        placeholder={
          postProcessing
            ? `Select/Enter a value to write to ${postProcessing["field"]}`
            : "Select/Enter a value"
        }
        isValidNewOption={(inputValue: string) => !!inputValue}
        formatCreateLabel={(inputValue: string) => (
          <div className="w-full h-full">
            <span className="text-green w-min">
              <i className="fas fa-plus-circle bg-white rounded-full mr-2" />
            </span>
            <span>{`Add Custom Value: "${inputValue}"`}</span>
          </div>
        )}
        components={{ Option }}
        onCreateOption={(value) => handleChange({ value }, undefined)}
        valueString={
          postProcessing?.["value"] ? `${postProcessing["value"]}` : null
        }
        searchParameters={{ model_type: props.modelType }}
        {...props}
      />
    </div>
  );
};

ValueSelect.defaultProps = {
  options: [],
  value: undefined,
  onChange: undefined,
};

export default ValueSelect;
