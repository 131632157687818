import React from "react";

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Close = (props: Props) => {
  return (
    <button type="button" data-controller="tooltip" onClick={props.onClick} aria-label="Close">
      <span className="">
        <i className="fal fa-times fa-2x"></i>
      </span>
      <span className="hidden" data-tooltip-target="content">
        Close
      </span>
    </button>
  );
};

Close.defaultProps = {
  onClick: () => {},
};

export default Close;
