import DisclosureController from "./disclosure_controller";

export default class extends DisclosureController {
  connect() {
    super.connect();

    document.addEventListener("turbo:submit-start", (event) => {
      if ([...this.element.querySelectorAll("form")].includes(event.target)) {
        this.stateValue = "loading";
      }
    });
  }
}
