import React, { useEffect, useState, useMemo } from "react";
import { ActionMeta } from "react-select";
import Select from "react-select/creatable";
import {
  ClauseValueOption,
  ClauseValueOptionGroup,
  ClauseVariableOption,
  ConditionsClause,
} from "../../../../../../../../../../../../../../../types";
import { debounce } from "lodash";

type Props = {
  options: (ClauseValueOption | ClauseValueOptionGroup)[];
  clause: Object;
  type: string;
  isList?: boolean;
  onChange: (value: string) => void;
  onSelect: (value: any) => void;
  onCreateOption: (value: string) => void;
  filterOption: (option: any, inputValue: string) => boolean;
  isLoading: boolean;
  value: any;
};

function maybeJSON(str) {
  if (!str) return false;
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

const ValueInput = ({
  clause,
  onChange,
  onSelect,
  type,
  isList,
  ...props
}: Props) => {
  const htmlId = useMemo(() => {
    return `routers/rules/conditions/clause_value_${clause["id"]}`;
  }, [clause]);

  const selectValuesFromProps = useMemo(() => {
    return props.options.flatMap((g: any) =>
      !!g.options
        ? g.options.map(({ value }) => value.toLowerCase())
        : g?.value?.toLowerCase()
    );
  }, [props.options]);

  const showInput = useMemo(() => {
    return (
      !props.options?.length ||
      type === "number" ||
      type === "date" ||
      (!!clause["value"] &&
        props.options
          .flatMap((g: any) => (!!g.options ? g.options : g))
          .find((o) => o.value === clause["value"]) === null)
    );
  }, [props.options, type, clause]);

  const showSelect = !showInput;

  const [inputValue, setInputValue] = useState(clause?.["value"]);

  const saveInput = async () => {
    if (onChange && clause["value"]?.toString() !== inputValue) {
      await onChange(inputValue);
    }
  };

  const debouncedSaveInput = debounce(saveInput, 3000);

  useEffect(() => {
    // Call the debounced function every time inputValue changes
    debouncedSaveInput();

    // Cancel the debounce if the user starts typing again
    return () => {
      debouncedSaveInput.cancel();
    };
  }, [inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (
    selection: ClauseValueOption,
    _action: ActionMeta<ClauseVariableOption>
  ) => {
    if (onSelect) onSelect(selection);
  };

  const inputType = () => {
    if (isList) return "text";
    switch (type) {
      case "date":
        return "date";
      case "number":
        return "number";
      case "text":
        return "text";
      default:
        return "text";
    }
  };

  const isValid = () => {
    if (!props.value?.label || !props.value?.value || !props.value)
      return false;

    if (
      isList &&
      (maybeJSON(props.value.label) instanceof Array ||
        (props.value.value.startsWith("rule#") &&
          (props.value.value.endsWith("_ids") ||
            props.value.value.endsWith("_emails"))))
    )
      return true;
    if (
      !isList &&
      inputType() === "number" &&
      !isNaN(parseInt(props.value.value, 10) || parseFloat(props.value.value))
    )
      return true;
    if (
      !isList &&
      inputType() === "text" &&
      (props.value.value instanceof String ||
        typeof props.value.value === "string")
    )
      return true;
    if (!isList && inputType() === "date") return true;
    else return false;
  };

  const validationClasses = () => {
    if (!props.value?.label || !props.value?.value || !props.value)
      return "invisible";
    if (isValid()) return "text-green-dark border-green-dark";
    else return "text-red border-red";
  };

  const validationContent = () => {
    if (!props.value?.label || !props.value?.value) return null;
    const base = `${props.value.label} `;
    if (isList) return base + `(${isValid() ? "valid" : "invalid"} list)`;
    if (inputType() === "number")
      return base + `(${isValid() ? "valid" : "invalid"} number)`;
    if (inputType() === "text")
      return base + `(${isValid() ? "valid" : "invalid"} text)`;
    if (inputType() === "date")
      return base + `(${isValid() ? "valid" : "invalid"} date)`;
  };

  if (showInput) {
    return (
      <div>
        <input
          id={htmlId}
          className={
            // ${readOnly ? "disabled" : ""}
            `block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900`
          }
          type={inputType()}
          name="routers_rules_conditions_clause[value]"
          defaultValue={(clause["value"] || "").toString() || undefined}
          onChange={handleChange}
          placeholder={isList ? "Enter a list" : "Enter a value"}
        />
        {props.value?.label && (
          <div
            className={`w-full m-auto mt-1 border rounded-md px-3 text-grey text-center ${validationClasses()}`}
          >
            {validationContent()}
          </div>
        )}
      </div>
    );
  }

  if (showSelect) {
    return (
      <div>
        <Select
          {...props}
          isClearable
          isSearchable
          id={htmlId}
          createOptionPosition="first"
          isValidNewOption={(inputValue: string) =>
            !!inputValue &&
            !selectValuesFromProps.includes(inputValue.toLowerCase())
          }
          className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
          classNamePrefix="react-select"
          styles={{
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              backgroundColor: isSelected
                ? "#1c4cc3"
                : isFocused
                ? "#448eef"
                : "transparent",
            }),
          }}
          placeholder={
            isList ? "Enter or select a list" : "Enter or select a value"
          }
          onChange={handleSelectChange}
        />
        {props.value?.label && (
          <div
            className={`w-full m-auto mt-1 border rounded-md px-3 text-grey text-center ${validationClasses()}`}
          >
            {validationContent()}
          </div>
        )}
      </div>
    );
  }
};

ValueInput.defaultProps = {
  options: [],
  type: "text",
  value: undefined,
  onChange: undefined,
  onSelect: undefined,
  onCreateOption: undefined,
  isList: false,
};

export default ValueInput;
