import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";

import { request } from "../../lib/web";
import WizardStepArrow from "../components/WizardStepArrow";
import RouterTriggerContext from "../contexts/RouterTriggerContext";
import TeamIdContext from "../contexts/TeamIdContext";
import CrmTriggerWizard from "../CrmTriggerWizard";
import RouterWizard from "../RouterWizard";

type Props = {
  teamId: string;
  crmTriggerId?: string;
};

const RoutableCrmTriggerWizard = (props: Props) => {
  const [crmTrigger, setCrmTrigger] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(null);

  const fetchCrmTrigger = () => {
    setIsLoading(true);
    request(
      window.location.toString(),
      `/account/crm_triggers/${props.crmTriggerId}.json`,
      {},
      "GET"
    )
      .then(({ data }) => {
        setCrmTrigger(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // const url = new URL("../../../teams/${props.teamId}/salesforms/sync", window.location.href);
        // window.history.replaceState(window.history.state, document.title, url);
        setIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (props.crmTriggerId) {
      fetchCrmTrigger();
    }
  }, [props.crmTriggerId]);

  const handleContainerClick: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const container = event.currentTarget as HTMLDivElement;
    setStep(container.dataset.stepid);
  };

  useEffect(() => {
    if (
      !crmTrigger ||
      !crmTrigger["name"] ||
      !crmTrigger["crm_event_type"] ||
      !crmTrigger["crm_object_type"] ||
      !crmTrigger["active"]
      // ||
      // !JSON.parse(crmTrigger["sample_data"])
    ) {
      setStep("crm-trigger-wizard");
    } else {
      setStep("router-wizard");
    }
  }, [crmTrigger]);

  return (
    <TeamIdContext.Provider value={props.teamId}>
      <div className="flex flex-col items-center justify-center">
        {isLoading && !crmTrigger ? (
          <i className="fas fa-circle-notch fa-spin fa-3x m-auto"></i>
        ) : (
          <>
            <CrmTriggerWizard
              teamId={props.teamId}
              crmTrigger={crmTrigger}
              onCrmTriggerChange={setCrmTrigger}
              onContainerClick={handleContainerClick}
              isCompleted={
                crmTrigger &&
                crmTrigger["name"] &&
                crmTrigger["crm_event_type"] &&
                crmTrigger["crm_event_type"] &&
                crmTrigger["active"]
                // !isEmpty(JSON.parse(form["sample_data"]))
              }
              isContainerOpen={step === "crm-trigger-wizard"}
            />
            <RouterTriggerContext.Provider
              value={
                crmTrigger
                  ? {
                      trigger: crmTrigger,
                      triggerType: "CrmTrigger",
                      routerCreatePath: `/account/crm_triggers/${crmTrigger["id"]}/router.json`,
                    }
                  : undefined
              }
            >
              {crmTrigger?.["crm_object_type"] &&
                crmTrigger?.["crm_event_type"] && (
                  <WizardStepArrow
                    onButtonClick={undefined}
                    afterButtonClick={fetchCrmTrigger}
                    showArrow={!!crmTrigger?.["router"]}
                    buttonIcon="fa-route"
                    buttonTooltip="Add Routing"
                  />
                )}
              {crmTrigger?.["crm_object_type"] &&
                crmTrigger?.["crm_event_type"] && (
                  <RouterWizard
                    router={crmTrigger?.["router"]}
                    onContainerClick={handleContainerClick}
                    isCompleted={false}
                    isContainerOpen={step === "router-wizard"}
                    onRouterDelete={(_) => {
                      setCrmTrigger((prev) => ({ ...prev, router: null }));
                    }}
                    onRouterCreate={fetchCrmTrigger}
                  />
                )}
            </RouterTriggerContext.Provider>
          </>
        )}
      </div>
    </TeamIdContext.Provider>
  );
};

RoutableCrmTriggerWizard.defaultProps = {
  crmTrigger: undefined,
  crmTriggerId: undefined,
};

export default RoutableCrmTriggerWizard;
