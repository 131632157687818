import React, { useEffect } from 'react';

type Props = {
  client_id: string,
  client_secret: string,
  extension_id: string,
}

function ExtensionMessenger(props: Props) {
  useEffect(() => {
  }, [props]);
  return (
    null
  );
}

export default ExtensionMessenger;