export default (hit, { html, components }) => {
  const { obfuscated_id } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/event_types/${obfuscated_id}"
    >
      <div class="space-y-3">
        <div class="font-bold">
          ${components.Highlight({
            attribute: "title",
            hit,
          })}
        </div>
        <div class="text-2xs">
          ${components.Highlight({
            attribute: "formatted_length",
            hit,
          })}
        </div>
      </div>
    </a>
  `;
};
