import { Disclosure } from "@headlessui/react";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";

import { request } from "../../lib/web";
import WizardContainer from "../components/WizardContainer";
import TeamIdContext from "../contexts/TeamIdContext";
import Activate from "./components/Activate";
import CrmTriggerOptions from "./components/CrmTriggerOptions";
import Name from "./components/Name";
import SampleData from "./components/SampleData";

type Props = {
  onContainerClick: React.MouseEventHandler<HTMLDivElement>;
  isContainerOpen: boolean;
  isCompleted: boolean;
  teamId: string;
  crmTrigger?: Object;
  crmTriggerId?: string;
  onCrmTriggerChange: (crmTrigger: Object) => void;
};

const CrmTriggerWizard = (props: Props) => {
  const [crmTrigger, setCrmTrigger] = useState(props.crmTrigger);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCrmTrigger = (crmTriggerId: string) => {
    setIsLoading(true);
    request(
      window.location.toString(),
      `/account/crm_triggers/${crmTriggerId}.json`,
      {},
      "GET"
    )
      .then(({ data }) => {
        setCrmTrigger(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // const url = new URL("../../../teams/${props.teamId}/salesforms/sync", window.location.href);
        // window.history.replaceState(window.history.state, document.title, url);
        setIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    setCrmTrigger(props.crmTrigger);
  }, [props.crmTrigger]);

  useEffect(() => {
    if (props.crmTriggerId) {
      fetchCrmTrigger(props.crmTriggerId);
    }
  }, [props.crmTriggerId]);

  const createCrmTrigger = (params) => {
    request(
      window.location.toString(),
      `/account/teams/${props.teamId}/crm_triggers.json`,
      { crm_trigger: params },
      "POST"
    )
      .then(({ data }) => {
        setCrmTrigger(data);
        afterCrmTriggerCreated(data);
        setIsLoading(false);
      })
      .catch((error) => {
        // const url = new URL("../../../teams/${props.teamId}/salesforms/sync", window.location.href);
        // window.history.replaceState(window.history.state, document.title, url);
        setIsLoading(false);
        console.error(error);
      });
  };

  const afterCrmTriggerCreated = (data) => {
    if (props.onCrmTriggerChange) props.onCrmTriggerChange(data);
    else setCrmTrigger(data);
    const url = new URL(
      window.location.href.includes(
        `/account/teams/${props.teamId}/crm_triggers/new`
      )
        ? `../../../crm_triggers/${data.id}`
        : `./${data.id}`,
      window.location.href
    );

    window.history.replaceState(window.history.state, document.title, url);
  };

  const updateCrmTrigger = (updateParams: Object) => {
    request(
      window.location.toString(),
      `/account/crm_triggers/${crmTrigger["id"]}.json`,
      { crm_trigger: updateParams },
      "PUT"
    )
      .then(({ data }) => {
        if (props.onCrmTriggerChange) props.onCrmTriggerChange(data);
        else setCrmTrigger(data);
      })
      .catch(console.error);
  };

  const deleteCrmTrigger = () => {
    if (crmTrigger) {
      request(
        window.location.toString(),
        `/account/crm_triggers/${crmTrigger["id"]}.json`,
        {},
        "DELETE"
      ).catch(console.error);
    }
  };

  const afterCrmTriggerError = (error) => {
    console.error(error);
  };

  return (
    <WizardContainer
      title="Trigger"
      details={"Configure Salesform to react to changes in your CRM."}
      iconClassName="fas fa-satellite-dish fa-2x"
      onClick={props.onContainerClick}
      isCompleted={props.isCompleted}
      isOpen={props.isContainerOpen}
      data-stepid="crm-trigger-wizard"
    >
      <Disclosure as="div" defaultOpen={!crmTrigger?.["name"]}>
        {(disclosureProps) => (
          <Name
            {...disclosureProps}
            crmTrigger={crmTrigger}
            onSubmit={crmTrigger?.["id"] ? updateCrmTrigger : createCrmTrigger}
          />
        )}
      </Disclosure>
      {crmTrigger?.["name"] && (
        <Disclosure
          as="div"
          defaultOpen={
            !crmTrigger?.["crm_object_type"] || !crmTrigger?.["crm_event_type"]
          }
        >
          {(disclosureProps) => (
            <CrmTriggerOptions
              {...disclosureProps}
              crmTrigger={crmTrigger}
              onChange={updateCrmTrigger}
            />
          )}
        </Disclosure>
      )}
      {crmTrigger?.["crm_object_type"] && crmTrigger?.["crm_event_type"] && (
        <Disclosure as="div" defaultOpen={isEmpty(crmTrigger?.["sample_data"])}>
          {(disclosureProps) => (
            <SampleData
              {...disclosureProps}
              crmTrigger={crmTrigger}
              onUpdate={updateCrmTrigger}
            />
          )}
        </Disclosure>
      )}
      {crmTrigger?.["crm_object_type"] && crmTrigger?.["crm_event_type"] && (
        <Disclosure as="div" defaultOpen={!crmTrigger?.["active"]}>
          {(disclosureProps) => (
            <Activate
              {...disclosureProps}
              crmTrigger={crmTrigger}
              onChange={(active) => updateCrmTrigger({ active })}
            />
          )}
        </Disclosure>
      )}
    </WizardContainer>
  );
};

CrmTriggerWizard.defaultProps = {
  crmTrigger: undefined,
  crmTriggerId: undefined,
};

export default CrmTriggerWizard;
