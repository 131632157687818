import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["input", "submitButton"]

  connect() {
    this.inputTarget.onchange = () => {
      this.revealElement(this.submitButtonTarget)
    }

    this.inputTarget.addEventListener('input', () => {
      this.revealElement(this.submitButtonTarget)
    })

    this.inputTarget.onblur = (event) => {
      if (event.relatedTarget !== this.submitButtonTarget) {
        this.inputTarget.value = this.inputTarget.defaultValue
        this.hideElement(this.submitButtonTarget)
      }
    }
  }

  isInvisible(element) {
    return element.className.includes("invisible")
  }

  hideElement(element) {
    if (!this.isInvisible(element)) element.className = `invisible ${element.className}`;
  }

  revealElement(element) {
    if (this.isInvisible(element)) element.className = element.className.replace("invisible", "");
  }
}