import React from "react";
import { Turbo } from "@hotwired/turbo-rails";
import ReactDOM from "react-dom";
import ReactCalendar from "react-calendar";
import { Controller } from "@hotwired/stimulus";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";

import { timeZone } from "../../packs/lib/clock";
import { convertTimeBetweenTimeZones } from "../../packs/lib/time";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export default class extends Controller {
  static values = {
    selectedDate: String,
    lookaheadDays: Number,
    rescheduleMode: Boolean,
  };

  initialize() {
    this.initCalendar();
  }

  disconnect() {
    this.tearDownCalendar();
  }

  selectedDateValueChanged() {
    this.renderCalendar();
  }

  getSelectedDate() {
    const searchParams = new URLSearchParams(location.search);
    const dateString = searchParams.get("date");
    if (dateString) {
      // todo some extra validation maybe.
      const utcOffsetAsDate = dayjs(dateString.slice(-4), "Hmm");
      const utcOffset = parseInt(
        dateString.substr(-5, 1) +
          (utcOffsetAsDate.hour() * 60 + utcOffsetAsDate.minute())
      );

      const day = dayjs(dateString.substr(0, 10)).utcOffset(utcOffset, true);
      return day.isValid() ? day.toISOString() : undefined;
    }
    return undefined;
  }

  initCalendar() {
    if (this.data.get("initialized")) return;

    this.selectedDateValue = this.getSelectedDate();
    this.renderCalendar();

    if (this.selectedDateValue) {
      // scroll timeslots into view
      document.getElementById("time_slots").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    // Mark that we've been initialized
    this.data.set("initialized", true);
  }

  tearDownCalendar() {
    this.data.set("initialized", false);
  }

  changeDate(update) {
    const spec = "YYYY-MM-DD[T]HH:mm:ssZZ";
    if (
      !this.selectedDateValue ||
      update.format(spec) !==
        dayjs(this.selectedDateValue).tz(timeZone(), true).format(spec)
    ) {
      const url = new URL(location.href);
      const param = update.format(spec);
      url.searchParams.set("date", param);
      Turbo.visit(url.href, { action: "replace" });
    }
  }

  renderCalendar() {
    const reactCalendars = document.querySelectorAll(".react_calendar");

    const handleChange = (date) => {
      const update = dayjs(date)
        .hour(0)
        .minute(0)
        .second(0)
        .tz(timeZone(), true);
      this.changeDate(update);
    };

    Array.from(reactCalendars).forEach((reactCalendar) =>
      ReactDOM.render(
        <ReactCalendar
          className="sm:max-w-full md:max-w-md md:ml-4 w-max md:leading-10 leading-8 m-auto mt-4 sm:mt-0"
          defaultValue={
            this.selectedDateValue
              ? convertTimeBetweenTimeZones(
                  this.selectedDateValue,
                  dayjs.tz.guess(),
                  timeZone()
                )
              : undefined
          }
          calendarType="US"
          defaultView="month"
          onClickDay={(date, _e) => handleChange(date)}
          minDate={new Date()}
          maxDate={
            this.rescheduleModeValue
              ? null
              : addDays(new Date(), this.lookaheadDaysValue)
          }
          // tileDisabled={tileDisabled}
        />,
        reactCalendar
      )
    );
  }
}
