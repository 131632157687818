import React, { useState, useEffect, useContext } from "react";

import Delete from "../../../../../../../../../../../../../components/Delete";
import FieldSelect from "./components/FieldSelect";
import OperatorSelect from "./components/OperatorSelect";
import ValueInput from "./components/ValueInput";
import {
  ClauseOperatorOption,
  ClauseValueOption,
  ClauseVariableOption,
} from "../../../../../../../../../../../../../../types";
import { request } from "../../../../../../../../../../../../../../lib/web";
import { AxiosResponse } from "axios";
import { capitalize } from "lodash";

type Props = {
  clause: Object;
  onChange: (clause: Object) => void;
  onRemove: (clause: Object) => void;
};

const Clause = ({ clause, ...props }: Props) => {
  const [fieldOptions, setFieldOptions] = useState({
    data: [],
    loading: false,
  });
  const [operatorOptions, setOperatorOptions] = useState({
    data: [],
    loading: false,
  });
  const [valueOptions, setValueOptions] = useState({
    data: [],
    loading: false,
  });
  const [selectedFieldOption, setSelectedFieldOption] = useState(null);
  const [selectedOperatorOption, setSelectedOperatorOption] = useState(null);
  const [selectedValueOption, setSelectedValueOption] = useState(null);

  const clausePath = `/account/routers/rules/conditions/clauses/${clause["id"]}`;

  const fetchClauseOptions = async (optionsEndpoint: string) =>
    request(window.location.origin, clausePath + optionsEndpoint, "", "GET");

  const initOptions = (
    optionsSetter: React.Dispatch<
      React.SetStateAction<{
        data: any[];
        loading: boolean;
      }>
    >,
    optionsEndpoint: string
  ) => {
    optionsSetter((prev) => ({ ...prev, loading: true }));
    fetchClauseOptions(optionsEndpoint)
      .then(({ data }: AxiosResponse) =>
        optionsSetter({ data, loading: false })
      )
      .catch(console.error);
  };

  useEffect(() => {
    initOptions(setFieldOptions, `/field_options.json`);
    initOptions(setOperatorOptions, `/operator_options.json`);
    initOptions(setValueOptions, `/value_options.json`);
  }, []);

  useEffect(() => {
    initOptions(setValueOptions, `/value_options.json`);
  }, [clause]);

  useEffect(() => {
    setSelectedFieldOption(
      fieldOptions.data
        ?.flatMap((g) => g?.options)
        .find((o) => o?.value === clause["field"])
    );
  }, [fieldOptions]);

  useEffect(() => {
    const foundValue = valueOptions.data
      .flatMap((g) => (!!g.options ? g.options : g))
      .find((o) => o.value === clause["value"]);
    if (foundValue) setSelectedValueOption(foundValue);
    else
      setSelectedValueOption({
        value: clause["value"],
        label: clause["value"],
        type: selectedOperatorOption?.label?.endsWith("(List)") ? "list" : "*",
      });
  }, [valueOptions]);

  useEffect(() => {
    const foundValue = valueOptions.data
      .flatMap((g) => (!!g.options ? g.options : g))
      .find((o) => o.value === clause["value"]);

    if (!!clause["value"] && !foundValue) {
      const value = clause["value"];
      setValueOptions((prev) => ({
        ...prev,
        data: [
          {
            label: "Custom Values",
            options: (
              prev.data.find((g) => g.label === "Custom Values")?.options || []
            ).concat([{ value, label: value, type: "*" }]),
          },
          ...prev.data.filter((g) => g.label !== "Custom Values"),
        ],
      }));
    }
  }, [clause]);

  useEffect(() => {
    setSelectedOperatorOption(
      operatorOptions?.data.find((o) => o?.value === clause["operator"])
    );
  }, [operatorOptions]);

  const handleRemove: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (props.onRemove) props.onRemove(clause);
  };

  const handleClauseUpdate = async (update) => {
    // if (props.onUpdate) props.onUpdate(data);
    await request(
      window.location.origin,
      `/account/routers/rules/conditions/clauses/${clause["id"]}.json`,
      { routers_rules_conditions_clause: update },
      "PUT"
    );
    if (props.onChange) props.onChange(update);
  };

  const handleFieldChange = async (selection: ClauseVariableOption) => {
    setSelectedFieldOption(selection);
    if (selection) {
      setSelectedValueOption(null);
      const valueInput = document.getElementById(
        `routers/rules/conditions/clause_value_${clause["id"]}`
      ) as HTMLInputElement;
      valueInput.value = "";
      await handleClauseUpdate({ value: null });
    }
    if (selection && selectedOperatorOption) {
      if (
        (selectedOperatorOption.type !== "*" &&
          selectedOperatorOption.type !== selection.type) ||
        (selectedOperatorOption.value === "is_contained_in" &&
          selection.type === "list")
      ) {
        setSelectedOperatorOption(null);
        await handleClauseUpdate({ operator: null });
      }
    }
    handleClauseUpdate({ field: selection ? selection.value : null })
      .then(() => {
        setValueOptions((prev) => ({ ...prev, loading: true }));
        fetchClauseOptions("/value_options.json")
          .then(({ data }: AxiosResponse) => {
            if (data !== valueOptions) {
              setValueOptions({ data, loading: false });
              setSelectedValueOption(null);
            }
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  const handleOperatorChange = async (selection: ClauseOperatorOption) => {
    setSelectedOperatorOption(selection);
    if (selection) {
      if (
        selection.type !== "*" &&
        selection.type !== selectedFieldOption.type
      ) {
        if (
          !(selection.type === "text" && selectedFieldOption.type === "select")
        ) {
          setSelectedFieldOption(null);
          await handleClauseUpdate({ field: null });
        }
      }
      if (
        selection.value.includes("null") ||
        selection.value.includes("empty")
      ) {
        setSelectedValueOption(null);
        await handleClauseUpdate({ value: null });
      }
    }
    await handleClauseUpdate({ operator: selection ? selection.value : null });
  };

  const handleValueChange = async (value) => {
    await handleClauseUpdate({ value });
  };

  const handleValueSelect = async (selection: ClauseValueOption) => {
    setSelectedValueOption(selection);
    await handleClauseUpdate({ value: selection ? selection.value : null });
  };

  const filterOperatorOption = ({ data }: { data: ClauseOperatorOption }) => {
    if (!selectedFieldOption) return true;

    if (selectedFieldOption.type === "boolean" && data.type !== "boolean")
      return false;
    if (selectedFieldOption.type === "list" && data.value === "is_contained_in")
      return false;
    if (selectedFieldOption.type === "select") {
      if (data.value.startsWith("string")) return true;
    }
    if (data.type === "*") return true;
    if (selectedFieldOption.type !== data.type) return false;
    return true;
  };

  const filterValueOption = (
    { data }: { data: ClauseValueOption },
    inputValue: string
  ) => {
    if (!!inputValue) {
      const containsInputValue =
        data.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        data.label.toLowerCase().includes(inputValue.toLowerCase());
      if (selectedOperatorOption.value === "is_contained_in")
        return containsInputValue && data.type === "list";
      return containsInputValue;
    } else if (selectedOperatorOption.value === "is_contained_in")
      return data.type === "list";

    return true;
  };

  return (
    <div data-key={clause["id"]} className="grid grid-cols-12">
      <div className="col-span-11">
        <div className="space-y-2 lg:space-y-0 lg:flex lg:flex-row lg:space-x-2">
          <div className="flex-auto lg:w-1/3">
            <FieldSelect
              clause={clause}
              options={fieldOptions.data}
              isLoading={fieldOptions.loading}
              value={selectedFieldOption}
              onChange={handleFieldChange}
            />
          </div>
          <div className="flex-auto lg:w-1/3">
            <OperatorSelect
              clause={clause}
              options={operatorOptions.data}
              isLoading={operatorOptions.loading}
              onChange={handleOperatorChange}
              value={selectedOperatorOption}
              filterOption={filterOperatorOption}
            />
          </div>
          <div
            className={`flex-auto lg:w-1/3 ${
              selectedFieldOption?.type === "boolean" ||
              selectedOperatorOption?.value.includes("null") ||
              selectedOperatorOption?.value.includes("empty")
                ? "invisible"
                : ""
            }`}
          >
            <ValueInput
              clause={clause}
              type={selectedFieldOption?.type || "text"}
              isList={selectedOperatorOption?.value === "is_contained_in"}
              onChange={handleValueChange}
              options={valueOptions.data}
              isLoading={valueOptions.loading}
              value={selectedValueOption}
              onCreateOption={(value) =>
                handleClauseUpdate({ value }).catch(console.error)
              }
              filterOption={filterValueOption}
              onSelect={handleValueSelect}
            />
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="m-auto w-min">
          {props.onRemove && (
            <Delete tooltipContent="Remove" onClick={handleRemove} />
          )}
        </div>
      </div>
    </div>
  );
};

Clause.defaultProps = {};

export default Clause;
