import React from "react";

type Props = {
  buttonClassName?: string;
  tooltipContent?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Delete = (props: Props) => {
  return (
    <button
      className={
        props.buttonClassName +
        ` ${props.disabled ? "disabled opacity-30 cursor-not-allowed" : ""}`
      }
      disabled={props.disabled}
      type="button"
      data-controller="tooltip"
      aria-label={props.tooltipContent}
      onClick={props.onClick}
    >
      <span className="text-red-500">
        <i className="fal fa-trash-alt fa-2x"></i>
      </span>
      <span className="hidden" data-tooltip-target="content">
        {props.tooltipContent}
      </span>
    </button>
  );
};

Delete.defaultProps = {
  disabled: false,
  buttonClassName: "",
  tooltipContent: "Delete",
  onClick: () => {},
};

export default Delete;
