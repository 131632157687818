import React, { useState } from "react";
import { request } from "../../lib/web";

import WizardContainer from "../components/WizardContainer";
import RouterTriggerContext from "../contexts/RouterTriggerContext";
import Rules from "./components/Rules";

type Props = {
  onContainerClick: React.MouseEventHandler<HTMLDivElement>;
  onRouterDelete: React.MouseEventHandler<HTMLButtonElement>;
  onRouterCreate: React.MouseEventHandler<HTMLButtonElement>;
  isContainerOpen: boolean;
  router: Object;
  isCompleted: boolean;
};

const RouterWizard = (props: Props) => {
  const triggerContext = React.useContext(RouterTriggerContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteRouter: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (props.onRouterDelete) props.onRouterDelete(event);
    request(
      window.location.origin,
      `/account/routers/${props.router?.["id"]}.json`,
      {},
      "DELETE"
    ).catch(console.error);
  };

  if (
    !triggerContext?.trigger ||
    !triggerContext?.trigger?.["sample_data"] ||
    !props.router
  )
    return null;
  return (
    <>
      <WizardContainer
        title="Route"
        details="Send leads on a route, which will end once a rule is true."
        iconClassName="fas fa-route fa-2x"
        onClick={props.onContainerClick}
        data-stepid="router-wizard"
        isCompleted={props.isCompleted}
        isOpen={props.isContainerOpen}
        // deleteProps={{
        //   tooltipContent: "Remove Routing",
        //   onClick: handleDeleteRouter,
        // }}
      >
        <Rules router={props.router} onEmpty={handleDeleteRouter} />
      </WizardContainer>
      <div>
        <a className="button-secondary text-lg" href={`${props.router?.['url'].replace('.json', '')}/execution_logs`}>
          <i className="fas fa-layer-group mr-3" />
          View Logs
        </a>
      </div>
    </>
  );
};

export default RouterWizard;
