export default (hit, { html, components }) => {
  const {
    obfuscated_id,
    email,
    source,
    name,
    assigned_team_membership,
    data: { full_name, company_name },
  } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/prospects/${obfuscated_id}"
    >
      <div class="space-y-3">
        ${name
          ? html`<div class="font-bold">
              ${components.Highlight({
                attribute: "name",
                hit,
              })}
            </div>`
          : ""}
        ${company_name
          ? html`<div class="text-2xs font-bold">
              ${components.Highlight({
                attribute: "data.company_name",
                hit,
              })}
            </div>`
          : ""}
        ${email
          ? html`<div class="italic">
              ${components.Highlight({ attribute: "email", hit })}
            </div>`
          : ""}
        ${source
          ? html`<div class="text-2xs">
              <div class="w-20 inline-block">Source:</div>
              <span>
                ${components.Highlight({
                  attribute: "source.label",
                  hit,
                })}${" "}
                [${components.Highlight({
                  attribute: "source.type",
                  hit,
                })}]
              </span>
            </div>`
          : ""}
        ${assigned_team_membership
          ? html`<div class="text-2xs">
              <div class="w-20 inline-block">Assignee:</div>
              <div class="inline-block">
                ${components.Highlight({
                  attribute: "assigned_team_membership.full_name",
                  hit,
                })}${" "}(
                <span class="italic">
                  ${components.Highlight({
                    attribute: "assigned_team_membership.email",
                    hit,
                  })}
                </span>
                )
              </div>
            </div>`
          : ""}
      </div>
    </a>
  `;
};
