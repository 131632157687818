import React, { Fragment } from "react";

type Props = {
  field?: string;
  htmlPrefix?: string;
  value?: string;
  onToggle: (value: string) => void;
  options: { value: any; label: string; help?: any }[];
};

const ButtonToggles = (props: Props) => {
  return (
    <div>
      {props.options.map((option) => (
        <Fragment key={`${props.htmlPrefix}_${props.field}_${option.value}`}>
          <label
            data-key={`${props.htmlPrefix}_${props.field}_${option.value}`}
            className="btn-toggle"
            data-controller="fields--button-toggle tooltip"
          >
            <input
              data-fields--button-toggle-target="shadowField"
              type="radio"
              value={option.value}
              defaultChecked={props.value === option.value}
              name={`${props.htmlPrefix}[${props.field}]`}
              id={`${props.htmlPrefix}_${props.field}_${option.value}`}
            />
            <button
              type="button"
              className="button-alternative mb-1.5 mr-1"
              data-action="fields--button-toggle#clickShadowField"
              onClick={(event) => {
                const input = event.currentTarget
                  .previousElementSibling as HTMLInputElement;
                props.onToggle(input.value);
              }}
            >
              {option.label}
            </button>
            {!!option.help && (
              <span className="hidden" data-tooltip-target="content">
                {option.help}
              </span>
            )}
          </label>
          &nbsp;
        </Fragment>
      ))}
    </div>
  );
};

export default ButtonToggles;
