import React, { useEffect, useState } from "react";
import { Dialog as Modal } from "@headlessui/react";
import { formatISO9075, parseJSON } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import Close from "../../../../components/Close";
import AsyncSelect from "../../../../components/AsyncSelect";

type Props = {
  lineup: Object;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onUpdateLineup: (data: Object) => void;
};

const GroupLineupSettings = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
    if (props.onOpen) props.onOpen();
  };
  const handleClose = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleSelectGroupHost = (selection) => {
    if (props.onUpdateLineup) {
      props.onUpdateLineup({
        group_host_id: selection.id,
      });
    }
  };

  const filterGroupHostOption = (option) =>
    option.value !== props.lineup["group_host_id"];

  return (
    <>
      <button type="button" className="button space-x" onClick={handleOpen}>
        <span className="text-white-500">
          <i className="fas fa-cog"></i>
        </span>
        <span>Group Lineup Settings</span>
      </button>
      <Modal
        as="div"
        className="relative z-20 transition-all"
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Modal.Panel className="w-full max-w-md h-full transform overflow-hidden rounded-md bg-gray-100 dark:bg-sealBlue-200 text-left align-middle shadow-xl transition-all flex flex-col">
              <div className="flex flex-row items-center justify-between p-3 pr-5 bg-neutral-50 dark:bg-gray-600 border border-solid border-gray-300 dark:border-gray-500 rounded-t-md w-full">
                <Modal.Title as="h2" className="truncate w-3/4 font-semibold">
                  Group Lineup Settings{" "}
                  <div className="text-xs italic font-light">
                    last updated:{" "}
                    {formatISO9075(
                      utcToZonedTime(
                        parseJSON(props.lineup["updated_at"]),
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    )}
                  </div>
                </Modal.Title>
                <Close onClick={handleClose} />
              </div>
              <div className="p-5 h-full overflow-auto flex-auto">
                <div className="space-y-1">
                  <label className="block text-sm font-medium dark:text-white text-gray-700">
                    Default Assignee/Host
                  </label>
                  <div className="space-y-2">
                    <p className="text-xs text-gray-500">
                      This person will be the default host for meetings booked
                      through this Group Lineup. Additionally, if an inbound
                      lead is routed to this lineup, they will be assigned to
                      this person.
                    </p>
                    <AsyncSelect
                      value={
                        props.lineup["group_host"]
                          ? {
                              id: props.lineup["group_host"]["id"],
                              text: props.lineup["group_host"]["full_name"],
                            }
                          : null
                      }
                      searchPath={`/account/lineups/${props.lineup["id"]}/memberships/search`}
                      onChange={handleSelectGroupHost}
                      filterOption={filterGroupHostOption}
                      getOptionLabel={(option) => option.text}
                      getOptionValue={(option) => option.id}
                      placeholder="Choose a default host/assignee..."
                    />
                  </div>
                </div>
              </div>
            </Modal.Panel>
          </div>
        </div>
      </Modal>
    </>
  );
};

GroupLineupSettings.defaultProps = {
  isOpen: false,
  onOpen: undefined,
  onClose: undefined,
};

export default GroupLineupSettings;
