import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "label",
    "alternateLabel",
    "chevron",
    "stateText",
    "stateIcon",
    "panel",
  ];
  static classes = [
    "buttonClosed",
    "loadingStateText",
    "loadingStateIcon",
    "successStateText",
    "successStateIcon",
    "defaultStateText",
    "defaultStateIcon",
    "warningStateText",
    "warningStateIcon",
  ];
  static values = {
    state: String,
    isOpen: { type: Boolean, default: false },
  };

  initialize() {}

  connect() {
    this.disabled = this.element.attributes.disabled;

    if (this.disabled) {
      this.chevronTarget.hidden = true;
      this.buttonTarget.classList.remove(...this.buttonClosedClasses);
      this.buttonTarget.classList.add("cursor-default");
      return;
    }
  }

  open() {
    if (this.disabled) return;

    this.isOpenValue = true;
  }

  close() {
    if (this.disabled) return;

    this.isOpenValue = false;
  }

  toggleIsOpenValue() {
    if (this.disabled) return;

    this.isOpenValue = !this.isOpenValue;
  }

  isOpenValueChanged(value, _prevValue) {
    this.rotateChevron(value);
    this.setButtonClass(value);
    this.togglePanel(value);
    this.toggleLabel(value);
  }

  stateValueChanged(value, _prevValue) {
    this.setStateClasses(value);
  }

  rotateChevron(isOpen) {
    if (isOpen) this.chevronTarget.classList.add("rotate-180");
    else this.chevronTarget.classList.remove("rotate-180");
  }

  setButtonClass(isOpen) {
    if (isOpen) this.buttonTarget.classList.remove(...this.buttonClosedClasses);
    else this.buttonTarget.classList.add(...this.buttonClosedClasses);
  }

  togglePanel(isOpen) {
    if (!this.hasPanelTarget) return;

    this.buttonTarget.setAttribute("aria-expanded", isOpen);
    this.panelTarget.hidden = !isOpen;
  }

  toggleLabel(isOpen) {
    if (!this.hasAlternateLabelTarget) return;

    if (isOpen) {
      this.labelTarget.classList.add("hidden");
      this.alternateLabelTarget.classList.remove("hidden");
    } else {
      this.labelTarget.classList.remove("hidden");
      this.alternateLabelTarget.classList.add("hidden");
    }
  }

  setStateClasses(state) {
    if (
      !this.hasStateValue ||
      !this.hasStateTextTarget ||
      !this.hasStateIconTarget
    )
      return;

    switch (state) {
      case "loading":
        this.stateTextTarget.className = this.loadingStateTextClasses.join(" ");
        this.stateIconTarget.className = this.loadingStateIconClasses.join(" ");
        return;
      case "success":
        this.stateTextTarget.className = this.successStateTextClasses.join(" ");
        this.stateIconTarget.className = this.successStateIconClasses.join(" ");
        return;
      default:
        this.stateTextTarget.className = this.defaultStateTextClasses.join(" ");
        this.stateIconTarget.className = this.defaultStateIconClasses.join(" ");
    }
  }
}
