import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../../components/DisclosureButton";
import MembershipSelect from "./components/MembershipSelect";
import Options from "../../../components/Options";
import GroupLineupSettings from "./components/GroupLineupSettings";
import RoundRobinLineupSettings from "./components/RoundRobinLineupOptions";
import Membership from "./components/Membership";

type Props = {
  lineup: Object;
  onUpdateLineup: (data: Object) => void;
  onAddMembership: (data: Object) => void;
  onRemoveMembership: () => void;
  onUpdateMembership: (data?: Object) => void;
  open: boolean;
  close: () => void;
};

const Memberships = ({ open, close, ...props }: Props) => {
  const [openSettingsModal, setOpenSettingsModal] = useState(null);

  const [lineupMemberships, setLineupMemberships] = useState(
    props.lineup["memberships"]
  );

  useEffect(() => {
    setLineupMemberships(props.lineup["memberships"]);
  }, [props.lineup]);

  const handleMembershipSelect = (selection) => {
    if (props.onAddMembership)
      props.onAddMembership({
        memberships_attributes: [{ team_membership_id: selection.id }],
      });
  };

  const handleSchedulingTypeChange = (value) => {
    if (value !== props.lineup["scheduling_type"]) {
      if (props.onUpdateLineup)
        props.onUpdateLineup({ scheduling_type: value });
      setOpenSettingsModal(value);
    }
  };

  const handleMembershipUpdate = (membership) => {
    if (props.onUpdateMembership) {
      setLineupMemberships((prev) =>
        prev.map((m) => {
          if (m.id === membership.id) {
            return membership;
          }
          return m;
        })
      );
      props.onUpdateMembership();
    }
  };

  const handleMembershipRemove = (membership) => {
    if (props.onRemoveMembership) {
      setLineupMemberships((prev) =>
        prev.filter((m) => !m.id === membership.id)
      );
      props.onRemoveMembership();
    }
  };

  const maybeRoundRobinSort =
    props.lineup["scheduling_type"] === "group"
      ? () => 0
      : (a, b) =>
          props.lineup["sequence"].indexOf(a.id) -
          props.lineup["sequence"].indexOf(b.id);

  const filterOption = (option) =>
    !lineupMemberships
      .map((membership) => membership.team_membership_id)
      .includes(option.value);

  return (
    <>
      <DisclosureButton
        open={open}
        state={"SUCCESS"}
        content={<span>Members</span>}
      />
      <Disclosure.Panel unmount={false} className="p-8">
        <div className="space-y-8">
          {props.lineup["can_current_user_edit"] && (
            <MembershipSelect
              onSelectOption={handleMembershipSelect}
              filterOption={filterOption}
            />
          )}
          <div className="grid grid-cols-1 gap-y gap-x md:grid-cols-2">
            <div className="md:col-span-1 pr-0 md:pr-10 md:border-r flex flex-col items-center space-y-4">
              <div>
                <label
                  className="block font-semibold"
                  htmlFor="lineup_scheduling_type"
                >
                  Lineup Type
                </label>
                <div className="mt-5">
                  <Options
                    onChange={handleSchedulingTypeChange}
                    field="scheduling_type"
                    htmlPrefix="lineup"
                    value={props.lineup?.["scheduling_type"]}
                    options={[
                      (props.lineup["can_current_user_edit"] ||
                        props.lineup?.["scheduling_type"] == "group") && {
                        label: "Group",
                        value: "group",
                        disabled: !props.lineup["can_current_user_edit"],
                        help: (
                          <>
                            Meetings will be created with all Members invited at
                            the same time. Assignments will be given to the
                            default assignee/host
                            {props.lineup["group_host"] && (
                              <>
                                ,{" "}
                                <span className="font-semibold">
                                  {props.lineup["group_host"]["full_name"]}
                                </span>
                              </>
                            )}
                            .
                          </>
                        ),
                      },
                      (props.lineup["can_current_user_edit"] ||
                        props.lineup?.["scheduling_type"] == "round_robin") && {
                        label: "Round Robin",
                        value: "round_robin",
                        disabled: !props.lineup["can_current_user_edit"],
                        help: "Meetings and assignments will be cycled between individual Members based on round robin rules.",
                      },
                    ].filter((option) => !!option)}
                  />
                </div>
              </div>
              <div>
                {props.lineup["scheduling_type"] === "group" &&
                  props.lineup["can_current_user_edit"] && (
                    <GroupLineupSettings
                      lineup={props.lineup}
                      isOpen={openSettingsModal === "group"}
                      onOpen={() => setOpenSettingsModal("group")}
                      onClose={() => setOpenSettingsModal(null)}
                      onUpdateLineup={props.onUpdateLineup}
                    />
                  )}
                {props.lineup["scheduling_type"] === "round_robin" &&
                  props.lineup["can_current_user_edit"] && (
                    <RoundRobinLineupSettings
                      lineup={props.lineup}
                      isOpen={openSettingsModal === "round_robin"}
                      onOpen={() => setOpenSettingsModal("round_robin")}
                      onClose={() => setOpenSettingsModal(null)}
                      onUpdateLineup={props.onUpdateLineup}
                    />
                  )}
              </div>
            </div>
            <div className="sm:col-span-1 pr-0 ">
              <label
                className="block font-semibold"
                htmlFor="lineup_scheduling_type"
              >
                Lineup Members
              </label>
              <div className="mt-5 flex flex-row flex-wrap overflow-hidden">
                {lineupMemberships
                  .filter((m) => m["active"])
                  .sort(maybeRoundRobinSort)
                  .map((membership, index) => (
                    <Membership
                      key={membership.id}
                      isRoundRobin={
                        props.lineup["scheduling_type"] === "round_robin"
                      }
                      index={index}
                      membership={membership}
                      onMembershipUpdate={handleMembershipUpdate}
                      onMembershipRemove={handleMembershipRemove}
                    />
                  ))}
                {lineupMemberships
                  .filter((m) => !m["active"])
                  .map((membership, index) => (
                    <Membership
                      key={membership.id}
                      isRoundRobin={
                        props.lineup["scheduling_type"] === "round_robin"
                      }
                      index={index}
                      membership={membership}
                      onMembershipUpdate={handleMembershipUpdate}
                      onMembershipRemove={handleMembershipRemove}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default Memberships;
