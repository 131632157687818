import { Controller } from "@hotwired/stimulus";
import { csrf } from "../../packs/lib/web";

export default class extends Controller {
  static targets = ["topicField", "descriptionField"];
  static values = {
    teamId: String,
    eventTypeId: String,
    bookingId: String,
    membershipIds: Array,
    prospectIds: Array,
  };

  connect() {
    if (!this.element.closest("[data-controller='modal']"))
      this.element.dataset.turbo = false;
  }

  setEventTypeId({ detail: { event } }) {
    this.eventTypeIdValue = $(event.target).select2("data")[0].id;
  }

  setMembershipIds({ detail: { event } }) {
    this.membershipIdsValue = $(event.target)
      .select2("data")
      .map(({ id }) => id);
  }

  setProspectIds({ detail: { event } }) {
    this.prospectIdsValue = $(event.target)
      .select2("data")
      .map(({ id }) => id);
  }

  eventTypeIdValueChanged(value, _prevValue) {
    if (
      !value ||
      !this.membershipIdsValue?.length ||
      !this.prospectIdsValue?.length
    )
      return;

    this.updateTargetFields();
  }

  membershipIdsValueChanged(value, _prevValue) {
    if (
      !value?.length ||
      !this.eventTypeIdValue ||
      !this.prospectIdsValue?.length
    )
      return;

    this.updateTargetFields();
  }

  prospectIdsValueChanged(value, _prevValue) {
    if (
      !value?.length ||
      !this.eventTypeIdValue ||
      !this.membershipIdsValue?.length
    )
      return;

    this.updateTargetFields();
  }

  async getTemplates() {
    const options = { method: "GET", headers: { "X-CSRF-Token": csrf() } };
    let response;
    if (!this.hasBookingIdValue)
      response = await fetch(
        `/account/teams/${
          this.teamIdValue
        }/bookings/build_templates?booking[event_type_id]=${
          this.eventTypeIdValue
        }${this.membershipIdsValue.map(
          (id) => `&booking[membership_ids][]=${id}`
        )}${this.prospectIdsValue.map(
          (id) => `&booking[prospect_ids][]=${id}`
        )}`,
        options
      );
    else
      response = await fetch(
        `/account/bookings/${
          this.bookingIdValue
        }/build_templates?booking[event_type_id]=${
          this.eventTypeIdValue
        }${this.membershipIdsValue.map(
          (id) => `&booking[membership_ids][]=${id}`
        )}${this.prospectIdsValue.map(
          (id) => `&booking[prospect_ids][]=${id}`
        )}`,
        options
      );
    return await response.json();
  }

  async updateTargetFields() {
    const { topic, description } = await this.getTemplates();
    if (!!topic) this.topicFieldTarget.value = topic;
    if (!!description) {
      this.descriptionFieldTarget.value = description;
      const ckEditor = this.descriptionFieldTarget.closest(
        '[data-controller="fields--ckeditor"]'
      );

      if (ckEditor) {
        const ckEditorController =
          this.application.getControllerForElementAndIdentifier(
            ckEditor,
            "fields--ckeditor"
          );
        ckEditorController.plugin.setData(description);
      }
    }
  }
}
