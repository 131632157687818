import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    lineupId: String,
    membershipId: String,
    eventTypeId: String,
    utcDate: String,
  };

  subscribeToChannel() {
    const self = this;
    let params = { event_type_id: this.eventTypeIdValue };
    if (this.hasLineupIdValue && !!this.lineupIdValue)
      params = { ...params, lineup_id: this.lineupIdValue };
    else if (this.hasMembershipIdValue && !!this.membershipIdValue)
      params = { ...params, membership_id: this.membershipIdValue };
    else return;

    consumer.subscriptions.create(
      {
        channel: "BookingLinks::CalendarChannel",
        ...params,
      },
      {
        received(data) {
          const { date } = data;
          if (
            !date ||
            !self.hasUtcDateValue ||
            !self.utcDateValue === date
          )
            return;

          get(window.location.href, { responseKind: "turbo-stream" });
        },
      }
    );
  }

  connect() {
    this.subscribeToChannel();
  }
}
