import React, { useEffect, useState } from "react";
import { Dialog as Modal } from "@headlessui/react";
import { formatISO9075, parseJSON } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import Close from "../../../../../../components/Close";
import Switch from "../../../../../../components/Switch";
import Error from "../../../../../../../alerts/Error";
import Notice from "../../../../../../../alerts/Notice";
import { request } from "../../../../../../../lib/web";
import Options from "../../../../../../components/Options";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";

type Props = {
  membership: Object;
  onMembershipUpdate: (membership: Object) => void;
  onMembershipRemove: (membership: Object) => void;
  isOpen: boolean;
  onClose: () => void;
};

const MembershipSettingsModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleUpdateMembership = (params) => {
    setError(null);
    request(
      window.location.origin,
      `/account/lineups/memberships/${props.membership["id"]}.json`,
      { lineups_membership: params },
      "PUT"
    )
      .then(({ data }) => {
        props.onMembershipUpdate(data);
      })
      .catch(({ response }: { response: AxiosResponse }) => {
        setError(response.data.base);
      });
  };

  const handleRemoveMembership = () => {
    request(
      window.location.origin,
      `/account/lineups/memberships/${props.membership["id"]}.json`,
      {},
      "DELETE"
    )
      .then(() => {
        props.onMembershipRemove(props.membership);
      })
      .catch(({ response }: { response: AxiosResponse }) => {
        setError(response.data.base);
      });
  };

  const canEditPermissions = () => props.membership["can_current_user_admin"];
  const canEditRoundRobinPrefs = () =>
    props.membership["can_current_user_edit"];
  const canEditAvailability = () =>
    props.membership["can_current_user_edit"] ||
    props.membership["is_current_user"];

  return (
    <Modal
      as="div"
      className="relative z-10 transition-all"
      open={isOpen}
      onClose={handleClose}
    >
      <Modal.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex h-full items-center justify-center p-4 text-center">
          <Modal.Panel className="w-5/6 h-full transform overflow-hidden rounded-md bg-gray-100 dark:bg-sealBlue-200 text-left align-middle shadow-xl transition-all flex flex-col">
            <div className="flex flex-row items-center justify-between p-3 pr-5 bg-neutral-50 dark:bg-gray-600 border border-solid border-gray-300 dark:border-gray-500 rounded-t-md w-full">
              <Modal.Title as="h2" className="truncate w-3/4 font-semibold">
                Lineup Settings for {props.membership["full_name"]}
                <div className="text-xs italic font-light">
                  last updated:{" "}
                  {formatISO9075(
                    utcToZonedTime(
                      parseJSON(props.membership["updated_at"]),
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                  )}
                </div>
              </Modal.Title>
              <Close onClick={handleClose} />
            </div>
            <div className="p-5 relative h-full overflow-auto flex-auto">
              <Error content={error} onDismiss={() => setError(null)} />
              <div className="h-15/16 grid grid-cols-1 gap-y gap-x md:grid-cols-2">
                <div className="md:col-span-1 pr-0 md:pr-10 md:border-r flex flex-col items-center space-y-4">
                  <div className="w-full">
                    <label
                      className="block text-sm font-medium dark:text-white text-gray-700"
                      htmlFor="lineups_membership_roles"
                    >
                      Permissions
                    </label>
                    <div className="mt-1.5">
                      <Options
                        onChange={(role) => {
                          if (
                            props.membership["roles"] !== (!!role ? [role] : [])
                          )
                            handleUpdateMembership({
                              roles: !!role ? [role] : [],
                            });
                        }}
                        value={props.membership["roles"][0] || ""}
                        field="roles"
                        htmlPrefix="lineups_membership"
                        options={[
                          (!props.membership["roles"][0] ||
                            canEditPermissions()) && {
                            label: "Viewer",
                            value: "",
                            disabled: !canEditPermissions(),
                            help: (
                              <>
                                {props.membership["first_name"]} will be able
                                to:
                                <ul className="list-disc list-inside">
                                  <li>View this lineup</li>
                                  <li>View other members of this lineup</li>
                                  <li>Set their own availability status</li>
                                </ul>
                              </>
                            ),
                          },
                          (props.membership["roles"][0] == "editor" ||
                            canEditPermissions()) && {
                            label: "Editor",
                            value: "editor",
                            disabled: !canEditPermissions(),
                            help: (
                              <>
                                {props.membership["first_name"]} will have{" "}
                                <i>Viewer</i> perimissions, and will also be
                                able to:
                                <ul className="list-disc list-inside">
                                  <li>Change this lineup's name</li>
                                  <li>
                                    Modify this lineup's type and settings
                                  </li>
                                  <li>Add and remove lineup members</li>
                                  <li>
                                    Make changes to the settings of other lineup
                                    members (excluding permissions)
                                  </li>
                                </ul>
                              </>
                            ),
                          },
                          (props.membership["roles"][0] == "admin" ||
                            canEditPermissions()) && {
                            label: "Administrator",
                            value: "admin",
                            disabled: !canEditPermissions(),
                            help: (
                              <>
                                {props.membership["first_name"]} will have{" "}
                                <i>Viewer</i> and <i>Editor</i> perimissions,
                                and will also be able to:
                                <ul className="list-disc list-inside">
                                  <li>Set the permissions of other members</li>
                                  <li>Destroy this lineup</li>
                                </ul>
                              </>
                            ),
                          },
                        ].filter((option) => !!option)}
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-500"></p>
                  </div>
                </div>
                <div className="md:col-span-1 pr-0 flex flex-col items-center space-y-4">
                  <div className="w-full space-y-3">
                    <label className="text-sm font-medium dark:text-white text-gray-700">
                      Availability
                    </label>
                    <p className="mt-1.5 text-xs text-gray-500">
                      Set whether or not{" "}
                      {props.membership["is_current_user"]
                        ? "you are"
                        : `${props.membership["first_name"]} is`}{" "}
                      available for joining meetings or handling assignments. If{" "}
                      {props.membership["is_current_user"] ? "you" : "they"} are
                      not available,{" "}
                      {props.membership["is_current_user"] ? "you" : "they"}{" "}
                      will not be included in group meetings and will be
                      excluded from Round Robin.
                    </p>
                    {!props.membership["has_calendar_integration"] && (
                      <Notice>
                        Please{" "}
                        {props.membership["is_current_user"]
                          ? ""
                          : `have ${props.membership["first_name"]} `}
                        connect{" "}
                        {props.membership["is_current_user"] ? "your" : "their"}{" "}
                        work account, so leads sent to this Lineup can book a
                        time with{" "}
                        {props.membership["is_current_user"] ? "you" : "them"}{" "}
                        or be assigned to{" "}
                        {props.membership["is_current_user"] ? "you" : "them"}.
                      </Notice>
                    )}
                    <div>
                      <div className="inline-block align-middle">
                        <Switch
                          disableInteraction={!canEditAvailability()}
                          defaultChecked={props.membership["active"]}
                          screenReader={
                            canEditAvailability
                              ? props.membership["active"]
                                ? `Mark ${props.membership["first_name"]} as unavailable`
                                : `Mark ${props.membership["first_name"]} as available`
                              : props.membership["active"]
                              ? `${props.membership["first_name"]} is available`
                              : `${props.membership["first_name"]} is unavailable`
                          }
                          onChange={(active) =>
                            handleUpdateMembership({ active })
                          }
                        />
                      </div>
                      <span className="inline-block ml-4 text-sm font-normal dark:text-white text-gray-700">
                        {props.membership["active"]
                          ? "Available"
                          : "Not Available"}
                      </span>
                    </div>
                  </div>
                  {props.membership["lineup"]["scheduling_type"] ===
                    "round_robin" && (
                    <div className="w-full space-y-3 border-t pt-5">
                      <label className="text-sm font-medium dark:text-white text-gray-700">
                        Round Robin Settings
                      </label>
                      <div>
                        <label className="text-sm font-medium dark:text-white text-gray-700">
                          Weighting
                        </label>
                        <div className="mt-1.5">
                          <input
                            className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full  disabled:cursor-not-allowed"
                            type="number"
                            min="0"
                            max="999"
                            disabled={!canEditRoundRobinPrefs()}
                            id="lineups_membership_round_robin_weight"
                            name="lineups_membership[round_robin_weight]"
                            onChange={debounce((e) => {
                              const round_robin_weight = e.target.value;
                              if (
                                props.membership["round_robin_weight"] !==
                                round_robin_weight
                              )
                                handleUpdateMembership({
                                  round_robin_weight,
                                });
                            }, 500)}
                            defaultValue={
                              props.membership["round_robin_weight"]
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label className="text-sm font-medium dark:text-white text-gray-700">
                          Meeting Count Adjustment
                        </label>
                        <div className="mt-1.5">
                          <input
                            className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full disabled:cursor-not-allowed"
                            type="number"
                            disabled={!canEditRoundRobinPrefs()}
                            id="lineups_membership_bookings_count_adjustment"
                            name="lineups_membership[bookings_count_adjustment]"
                            onChange={debounce((e) => {
                              const bookings_count_adjustment = e.target.value;
                              if (
                                props.membership[
                                  "bookings_count_adjustment"
                                ] !== bookings_count_adjustment
                              )
                                handleUpdateMembership({
                                  bookings_count_adjustment,
                                });
                            }, 500)}
                            defaultValue={
                              props.membership["bookings_count_adjustment"]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="ml-auto p-3 buttons">
              <a
                href={`/account/memberships/${props.membership["team_membership_id"]}`}
                className="button"
              >
                View{" "}
                {props.membership["is_current_user"]
                  ? "your"
                  : `${props.membership["first_name"]}'s`}{" "}
                page
              </a>
              {props.membership["can_current_user_edit"] && (
                <button
                  onClick={handleRemoveMembership}
                  className="button-negative"
                >
                  Remove {props.membership["first_name"]} from Lineup
                </button>
              )}
            </div>
          </Modal.Panel>
        </div>
      </div>
    </Modal>
  );
};

export default MembershipSettingsModal;
