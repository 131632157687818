import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import historyRouter from "instantsearch.js/es/lib/routers/history";

export const instantSearchRouter = historyRouter();

export function initInstantSearch({
  indexName: indexName = "",
  auth: auth = undefined,
}) {
  const searchClient = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    auth?.api_key
  );

  return instantsearch({
    searchClient,
    indexName,
    routing: instantSearchRouter,
  });
}
