import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import DisclosureButton from "../../../components/DisclosureButton";
import EventTypeSelect from "./components/EventTypeSelect";
import ObjectTypeSelect from "./components/ObjectTypeSelect";

type Props = {
  crmTrigger: Object;
  onChange: (value: any) => void;
  open: boolean;
  close: () => void;
};

const EVENT_TYPE_OPTIONS = [
  { label: "is created", value: "create" },
  { label: "is updated", value: "update" },
  // { label: "is deleted", value: "delete" },
];

const CrmTriggerOptions = ({ open, close, ...props }: Props) => {
  const [objectTypeOptions, setObjectTypeOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const findEventTypeOption = (eventType: string) => {
    return EVENT_TYPE_OPTIONS.find((option) => option.value === eventType);
  };

  const findObjectTypeOption = (objectType: string) => {
    return objectTypeOptions.find((option) => option.value === objectType);
  };

  const [eventTypeSelection, setEventTypeSelection] = React.useState(
    findEventTypeOption(props.crmTrigger["crm_event_type"])
  );
  const [objectTypeSelection, setObjectTypeSelection] = React.useState(
    findObjectTypeOption(props.crmTrigger["crm_object_type"])
  );

  // TODO: this is kinda gross looking and definitely should be refactored
  useEffect(() => {
    setIsLoadingOptions(true);
    fetch(
      `/account/crm_triggers/${props.crmTrigger["id"]}/object_type_options.json`
    )
      .then((resp) => resp.json())
      .then((options) => {
        setObjectTypeOptions(options);
        setObjectTypeSelection(
          options.find(
            (option) => option.value === props.crmTrigger["crm_object_type"]
          )
        );
        setIsLoadingOptions(false);
      })
      .catch((err) => {
        setIsLoadingOptions(false);
        console.error(err);
      });
  }, []);

  const onEventTypeSelectChange = (selection: any) => {
    if (selection) {
      if (props.onChange)
        props.onChange({
          crm_event_type: selection.value,
        });
      setEventTypeSelection(selection);
    }
  };

  const onObjectTypeSelectChange = (selection: any) => {
    if (selection) {
      if (props.onChange)
        props.onChange({
          crm_object_type: selection.value,
        });
      setObjectTypeSelection(selection);
    }
  };

  const getState = () => {
    if (!eventTypeSelection || !objectTypeSelection) return "DEFAULT";
    return "SUCCESS";
  };

  const handleContinueClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    close();
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={getState()}
        content={
          <span>
            Trigger Conditon
            <span className="italic font-normal">
              {!!objectTypeSelection && !!eventTypeSelection
                ? `: when ${objectTypeSelection.label} ${eventTypeSelection.label}`
                : ""}
            </span>
          </span>
        }
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <div className="flex flex-row space-x-2 items-center">
          <div className="flex-shrink-0 font-semibold">
            Fire whenever {"a(n)"}
          </div>
          <ObjectTypeSelect
            crmTrigger={props.crmTrigger}
            isLoading={isLoadingOptions}
            onChange={onObjectTypeSelectChange}
            options={objectTypeOptions}
            value={objectTypeSelection}
          />
          <EventTypeSelect
            crmTrigger={props.crmTrigger}
            onChange={onEventTypeSelectChange}
            options={EVENT_TYPE_OPTIONS}
            value={eventTypeSelection}
          />
        </div>
        {!!eventTypeSelection && !!objectTypeSelection && (
          <button
            type="button"
            className="button"
            onClick={handleContinueClick}
          >
            Continue
          </button>
        )}
      </Disclosure.Panel>
    </>
  );
};

export default CrmTriggerOptions;
