import React, { useState, useEffect } from "react";

import Checkbox from "./components/base/Checkbox";
import AvailabilityRange from "./components/AvailabilityRange";
import { Row, RowControl } from "./components/Row";
import { request } from "../lib/web";
import {
  padTime,
  timeStringToTimeTuple,
  compareTimeStrings,
} from "../lib/time";
import { useComponentDidMount } from "../wizards/hooks";

type Props = {
  schedule: {
    id: string;
    membership_id: string;
    availability: {
      [key: number]: { start_at: string; end_at: string }[];
    };
  };
};

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const AvailabilityPicker = ({ schedule }: Props) => {
  const [availability, setAvailability] = useState(schedule.availability);
  const [overlapError, setOverlapError] = useState({
    day: undefined,
    endIndex: undefined,
    startIndex: undefined,
  });

  const componentIsMounted = useComponentDidMount;

  const validate = () => {
    let invalid = false;
    let error = {
      day: undefined,
      startIndex: undefined,
      endIndex: undefined,
    };
    Object.entries(availability).forEach(([day, ranges]) => {
      ranges.forEach((range, index, array) => {
        if (index < array.length - 1) {
          const nextRange = array[index + 1];

          if (compareTimeStrings(range["end_at"], nextRange["start_at"]) >= 0) {
            invalid = true;
            error = {
              day: parseInt(day, 10),
              endIndex: index,
              startIndex: index + 1,
            };
          }
        }
        return !invalid;
      });
      return !invalid;
    });

    setOverlapError(error);
    return !invalid;
  };

  const update = () => {
    request(
      window.location.toString(),
      `/account/schedules/${schedule.id}`,
      { schedule: { availability } },
      "PUT"
    ).catch(console.error);
  };

  useEffect(() => {
    if (componentIsMounted && validate()) {
      update();
    }
  }, [availability]);

  const addAvailabilityPeriod = (weekdayIndex) => () =>
    setAvailability((prev) => {
      const ranges = prev[weekdayIndex];
      const lastRange = ranges[ranges.length - 1];
      const [endHr, endMin] = timeStringToTimeTuple(lastRange["end_at"]);

      return {
        ...prev,
        [weekdayIndex]: [
          ...ranges,
          {
            start_at: padTime(
              endHr < 23 ? endHr + 1 : endHr,
              endHr < 23 ? endMin : endMin + 30
            ),
            end_at: padTime(
              endHr < 23 ? endHr + 2 : 0,
              endHr < 23 ? endMin : 0
            ),
          },
        ],
      };
    });

  const removeAvailabilityPeriod = (weekdayIndex, rangeIndex) => () =>
    setAvailability((prev) => {
      const ranges = prev[weekdayIndex];
      return {
        ...prev,
        [weekdayIndex]: [
          ...ranges.slice(0, rangeIndex),
          ...ranges.slice(rangeIndex + 1),
        ],
      };
    });

  const onAvailabilityRangeChange = (weekdayIndex, rangeIndex, range) =>
    setAvailability((prev) => ({
      ...prev,
      [weekdayIndex]: [
        ...prev[weekdayIndex].slice(0, rangeIndex),
        range,
        ...prev[weekdayIndex].slice(rangeIndex + 1),
      ],
    }));

  const onCheckboxChange = ({
    name,
    checked,
  }: EventTarget & HTMLInputElement) => {
    !checked
      ? setAvailability((prev) => ({ ...prev, [name]: [] }))
      : setAvailability((prev) => ({
          ...prev,
          [name]: [{ start_at: "09:00", end_at: "17:00" }],
        }));
  };

  return (
    <div>
      {DAYS_OF_WEEK.map((dow, i) => {
        if (availability[i].length === 0)
          return (
            <Row key={`dow${i}`}>
              <div className="w-20">
                <Checkbox
                  label={<abbr title={dow}>{dow.substring(0, 3)}</abbr>}
                  name={i.toString()}
                  checked={availability[i].length > 0}
                  onChange={onCheckboxChange}
                />
              </div>
            </Row>
          );
        const [hd, ...tl]: {
          start_at: string;
          end_at: string;
        }[] = availability[i];
        return (
          <div key={`dow${i}`}>
            <Row>
              <div className="w-20">
                <Checkbox
                  label={<abbr title={dow}>{dow.substring(0, 3)}</abbr>}
                  name={i.toString()}
                  checked={availability[i].length > 0}
                  onChange={onCheckboxChange}
                />
              </div>
              <AvailabilityRange
                name={`dow${i}-range${0}`}
                range={hd}
                errors={
                  i === overlapError.day && {
                    start_at: overlapError.startIndex === 0,
                    end_at: overlapError.endIndex === 0,
                  }
                }
                onChange={(r) => onAvailabilityRangeChange(i, 0, r)}
              />
              <RowControl
                hidden={!(tl.length === 0 && hd["end_at"] !== "00:00")}
                onClick={addAvailabilityPeriod(i)}
              >
                <i className="ti ti-plus" />
              </RowControl>
            </Row>
            {tl.map((range, ri) => (
              <Row key={`dow${i}-range${ri}`}>
                <div className="w-20" />
                <AvailabilityRange
                  name={`dow${i}-range${ri + 1}`}
                  range={range}
                  errors={
                    i === overlapError.day && {
                      start_at: overlapError.startIndex === ri + 1,
                      end_at: overlapError.endIndex === ri + 1,
                    }
                  }
                  onChange={(r) => onAvailabilityRangeChange(i, ri + 1, r)}
                />
                <RowControl
                  hidden={
                    !(range["end_at"] !== "00:00" && ri === tl.length - 1)
                  }
                  onClick={addAvailabilityPeriod(i)}
                >
                  <i className="ti ti-plus" />
                </RowControl>
                <RowControl onClick={removeAvailabilityPeriod(i, ri + 1)}>
                  <i className="ti ti-minus" />
                </RowControl>
              </Row>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default AvailabilityPicker;
