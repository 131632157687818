import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../components/DisclosureButton";

type Props = {
  open: boolean;
  onSubmit: (data: Object) => void;
  close: () => void;
  lineup: Object;
};

const Name = ({ open, close, ...props }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSubmit)
      props.onSubmit({ name: event.target["lineup[name]"].value });
    close();
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={props.lineup?.["name"] ? "SUCCESS" : "DEFAULT"}
        content={
          props.lineup?.["name"] ? (
            <span>
              Name:{" "}
              <span className="font-light italic">
                {props.lineup?.["name"]}
              </span>
            </span>
          ) : (
            <span>Name</span>
          )
        }
      />
      {open && (
        <Disclosure.Panel unmount className="p-8 space-y-4">
          <form className="form" onSubmit={handleSubmit}>
            {/* <Error content={error} /> */}
            <div className="space-y-1">
              <input
                required
                disabled={isSubmitting}
                defaultValue={props.lineup?.["name"]}
                className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
                placeholder="Enter a name for this lineup"
                type="text"
                name="lineup[name]"
                id="lineup_name"
              />
            </div>
            <div className="buttons">
              <input
                type="submit"
                value="Save"
                disabled={isSubmitting}
                className={`${
                  isSubmitting ? "disabled opacity-50 cursor-not-allowed" : ""
                } button`}
              />
            </div>
          </form>
        </Disclosure.Panel>
      )}
    </>
  );
};

export default Name;
