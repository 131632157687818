import React, { useState } from "react";

type Props = {
  rule: Object;
  optional?: boolean;
  onSubmit: (data: Object) => void;
};

const UrlForm = ({ rule, onSubmit, optional }: Props) => {
  const [url, setUrl] = useState(rule["destination_url"]);

  const htmlId = `routers/rule_destination_url${rule["id"]}`;

  return (
    <form className="flex flex-row space-x-2" onSubmit={onSubmit}>
      <div>
        <input
          id={htmlId}
          required={!optional}
          className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
          type="url"
          name="routers_rule[destination_url]"
          defaultValue={(rule["destination_url"] || "").toString()}
          onChange={(event) => {
            setUrl(event.target.value.trim());
          }}
          placeholder={optional ? "(Optional) Enter redirect URL..." : "Enter URL..."}
        />
      </div>
      <div className="flex-shrink-0">
        <input
          type="submit"
          value="Save"
          disabled={url === rule["destination_url"]}
          className={`${
            url === rule["destination_url"]
              ? "disabled opacity-50 cursor-not-allowed invisible"
              : ""
          } button`}
        />
      </div>
    </form>
  );
};

UrlForm.defaultProps = {
  optional: false,
}

export default UrlForm;
