export default (hit, { html, components }) => {
  const { obfuscated_id, memberships } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/lineups/${obfuscated_id}"
    >
      <div class="space-y-3">
        <div class="font-bold">
          ${components.Highlight({
            attribute: "name",
            hit,
          })}
        </div>
        <div class="text-2xs">
          ${components.Highlight({
            attribute: "scheduling_type",
            hit,
          })}${' '}
          (${memberships.length} Member${memberships.length != 1 ? "s" : ""})
        </div>
      </div>
    </a>
  `;
};
