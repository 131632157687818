import React from "react";

import TeamIdContext from "../../../../../../../../../../contexts/TeamIdContext";
import AsyncSelect from "../../../../../../../../../../components/AsyncSelect";

type Props = {
  value: any;
  onChange: (value: any) => void;
};

const EventTypeSelect = (props: Props) => {
  const teamId = React.useContext(TeamIdContext);

  return (
    <AsyncSelect
      defaultOptions={[{ text: "No Meeting", id: null }]}
      searchPath={`/account/teams/${teamId}/event_types/search`}
      placeholder="Choose event type..."
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.id}
      {...props}
    />
  );
};

export default EventTypeSelect;
