import { Controller } from "@hotwired/stimulus";

// adapted from https://github.com/stimulus-components/stimulus-clipboard/blob/master/src/index.ts
export default class extends Controller {
  static targets = ["button", "source"];
  static values = {
    copyType: { type: String, default: "textContent" },
    successContent: String,
    successDuration: {
      type: Number,
      default: 2000,
    },
  };

  connect() {
    if (!this.hasButtonTarget) return;

    this.originalContent = this.buttonTarget.innerHTML;
  }

  copy(event) {
    event.preventDefault();
    let text;
    if (this.copyTypeValue === "textContent") {
      text = this.sourceTarget.textContent;
    } else if (this.copyTypeValue === "innerHTML") {
      text = this.sourceTarget.innerHTML;
    } else if (this.copyTypeValue == "value") {
      text = this.sourceTarget.value;
    }
    navigator.clipboard.writeText(text).then(() => this.copied());
  }

  copied() {
    if (!this.hasButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.buttonTarget.innerHTML = this.successContentValue;

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
    }, this.successDurationValue);
  }
}
