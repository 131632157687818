import React, { useState } from "react";
import { Switch } from "@headlessui/react";

type Props = {
  disableInteraction?: boolean;
  screenReader?: string;
  onChange?: (value: boolean) => void;
  defaultChecked?: boolean;
  bgColors?: string[];
  disabledBgColors?: string[];
};

const _Switch = (props: Props) => {
  const [checked, setChecked] = useState(props.defaultChecked);

  const bgColor = checked ? props.bgColors[0] : props.bgColors[1];
  const disabledBgColor = checked
    ? props.disabledBgColors[0]
    : props.disabledBgColors[1];

  const handleChange = (value: boolean) => {
    setChecked(value);
    if (props.onChange) props.onChange(value);
  };

  return (
    <Switch
      checked={checked}
      disabled={props.disableInteraction}
      onChange={handleChange}
      className={`${
        props.disableInteraction ? disabledBgColor : bgColor
      }  disabled:pointer-events-none disabled:cursor-not-allowed
    relative inline-flex h-9 w-18 shrink-0 cursor-pointer rounded-full border-2 
    border-transparent transition-colors duration-200 ease-in-out focus:outline-none 
    focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">{props.screenReader}</span>
      <span
        aria-hidden="true"
        className={`${checked ? "translate-x-9" : "translate-x-0"} ${
          props.disableInteraction ? "opacity-75 cursor-not-allowed" : ""
        }
      pointer-events-none inline-block h-8 w-8 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

_Switch.defaultProps = {
  disableInteraction: false,
  defaultChecked: false,
  onChange: (_v) => {},
  screenReader: "Toggle switch",
  bgColors: ["bg-blue", "bg-neutral-500"],
  disabledBgColors: ["bg-blue-300", "bg-neutral-300"],
};

export default _Switch;
