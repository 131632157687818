import fromUnixTime from "date-fns/fromUnixTime";
import format from "date-fns/format";
import { sortBy } from "lodash";

export default (hit, { html, components }) => {
  const { obfuscated_id, timestamp, executed_rules } = hit;

  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/routers/execution_logs/${obfuscated_id}"
    >
      <div class="space-y-3">
        <fieldset class="p-2">
          <legend class="text-2xs font-medium">Executed Rules</legend>
          <div class="space-y-2">
            ${sortBy(executed_rules, ["sort_order"]).map((rule) => {
              return html`<div
                class="border rounded px-2 w-max
                ${rule.pass
                  ? "border-green bg-green-light text-green-darker"
                  : "bg-yellow-light border-yellow-dark text-yellow-dark"}"
              >
                ${rule.name}
              </div>`;
            })}
          </div>
        </fieldset>
        <div class="text-2xs">
          <div class="inline-block w-20">Person:</div>
          <div class="inline-block">
            ${html`${components.Highlight({
              attribute: "data.email",
              hit,
            })}`}
          </div>
        </div>
        <div class="text-2xs">
          <div class="inline-block w-20">Timestamp:</div>
          ${format(fromUnixTime(timestamp), "MMM dd, yyyy @ h:mm:ss a")}
        </div>
      </div>
    </a>
  `;
};
