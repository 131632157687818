import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["steps", "prevButton", "nextButton", "progressBar", "progressButtons", "finalButtons", "finishButton"];
  static values = { progressIndex: Number }

  initialize() {
    this.finishButtonTarget.addEventListener("click", this.deleteProgressCookie)
    this.onboardingSteps = [...this.stepsTarget.children]
    // This makes sure the user (particularly admins) dont get stuck at a step that is hidden from the UI
    this.progressIndexValue = Math.min(this.onboardingSteps.length - 1, this.getProgressCookie()) || 0;
    this.showCurrentStep()
  }

  showCurrentStep() {
    this.stepsTarget.hidden = true
    this.onboardingSteps.forEach((onboardingStep, index) => {
      onboardingStep.hidden = index !== this.progressIndexValue;
    })
    if (this.progressIndexValue === 0) {
      this.hideElement(this.prevButtonTarget)
    } else {
      this.revealElement(this.prevButtonTarget)
    }

    if (this.progressIndexValue === this.stepsTarget.children.length - 1) {
      this.hideElement(this.nextButtonTarget)
    } else {
      this.revealElement(this.nextButtonTarget)
    }

    if (this.progressIndexValue === this.stepsTarget.children.length - 1) {
      this.progressButtonsTarget.hidden = true;
      this.finalButtonsTarget.hidden = false;
    } else {
      this.progressButtonsTarget.hidden = false;
      this.finalButtonsTarget.hidden = true;
    }

    this.progressBarTarget.style.width = `${((this.progressIndexValue + 1) / this.stepsTarget.children.length) * 100}%`
    this.progressBarTarget.textContent = `${this.progressIndexValue + 1} / ${this.stepsTarget.children.length}`
    this.stepsTarget.hidden = false
    this.setProgressCookie()
  }

  next() {
    if (this.progressIndexValue < this.stepsTarget.children.length - 1) {
      this.progressIndexValue++;
      this.showCurrentStep()
    }
  }

  previous() {
    if (this.progressIndexValue > 0) {
      this.progressIndexValue--;
      this.showCurrentStep();
    }
  }

  isInvisible(element) {
    return element.className.includes("invisible")
  }

  hideElement(element) {
    if (!this.isInvisible(element)) element.className = `invisible ${element.className}`;
  }

  revealElement(element) {
    if (this.isInvisible(element)) element.className = element.className.replace("invisible", "");
  }

  setProgressCookie() {
    let date = new Date();
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = "sfrm_onboarding_progress=" + this.progressIndexValue + ";" + expires;
  }

  getProgressCookie() {
    const name = "sfrm_onboarding_progress=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
  }

  deleteProgressCookie() {
    document.cookie = "sfrm_onboarding_progress=;";
  }
}