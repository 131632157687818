import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import linkCRM from "@xkit-co/crm-link.js";

import consumer from "../../channels/consumer";

export default class extends Controller {
  static values = {
    slug: String,
    teamId: String,
    platformDomain: String,
  };

  async getXkitContext(teamID) {
    const response = await post(`/account/teams/${teamID}/xkit/context`);
    if (response.ok) {
      const {
        context: { access_token: token },
        connection_id: connectionID,
      } = await response.json;

      return [token, connectionID];
    } else {
      console.error(response);
    }
  }

  async getObjects(teamID, slug) {
    const response = await get(
      `/account/teams/${teamID}/xkit/mapping?slug=${slug}`
    );

    if (response.ok) {
      const { objects } = await response.json;

      return objects;
    }
  }

  async createConnection(teamID, connectionID, slug) {
    const body = JSON.stringify({ connection_id: connectionID, slug });
    const options = { body: body, contentType: "application/json" };

    const response = await post(
      `/account/teams/${teamID}/xkit/connection`,
      options
    );

    if (response.ok) {
      const {
        connection: { connection_id },
      } = await response.json;

      return connection_id;
    }
  }

  setXkitContext() {
    this.getXkitContext(this.teamIdValue).then(([token, connectionId]) => {
      this.token = token;
      this.connectionId = connectionId;
    });
  }

  connect() {
    this.connectionId = null;
    this.token = null;
    this.setXkitContext();
    consumer.subscriptions.create(
      {
        channel: "Xkit::ConnectionChannel",
        team_id: this.teamIdValue,
      },
      {
        received(_data) {
          if (window.location.pathname.includes("/edit"))
            window.location.reload();
        },
      }
    );
  }

  platformDomainValueChanged(_value, previousValue) {
    if (!previousValue) return;
    this.setXkitContext();
  }

  teamIdValueChanged(_value, previousValue) {
    if (!previousValue) return;
    this.setXkitContext();
  }

  async show(options = { afterFinish: () => {} }) {
    try {
      const objects = await this.getObjects(this.teamIdValue, this.slugValue);
      // if `connectionID` is null, it's the first time we setup the CRM and `linkCRM` will return a new one
      // if it exists, `linkCRM` returns the same ID
      const newOrExistingConnectionID = await linkCRM(
        this.platformDomainValue,
        this.token,
        {
          objects,
          connectionID: this.connectionId,
          preselectCRMSlug: this.slugValue || undefined,
        }
      );

      // make sure we stored the ID correctly
      this.connectionId = await this.createConnection(
        this.teamIdValue,
        newOrExistingConnectionID,
        this.slugValue
      );
    } catch (error) {
      console.error(error);
    }
    options.afterFinish();
  }
}
