import React from "react";

import TimePicker from "./TimePicker";
import { padTime, timeStringToTimeTuple } from "../../lib/time";

type Props = {
  errors?: { start_at: boolean; end_at: boolean };
  name: string;
  range: { start_at: string; end_at: string };
  onChange: (range: { start_at: string; end_at: string }) => void;
};

const AvailabilityRange = ({ range, onChange, errors, ...props }: Props) => {
  const onStartAtChange = (value: string) => {
    const [startHr, startMin] = timeStringToTimeTuple(value);
    const [endHr, endMin] = timeStringToTimeTuple(range["end_at"]);

    if (startHr === endHr && !(startMin < endMin)) {
      onChange({
        ...range,
        start_at: value,
        end_at: padTime(
          startHr < 23 ? startHr + 1 : 0,
          startHr < 23 ? startMin : 0
        ),
      });
    } else if (startHr > endHr)
      onChange({
        ...range,
        start_at: value,
        end_at: padTime(
          startHr < 23 ? startHr + 1 : 0,
          startHr < 23 ? startMin : 0
        ),
      });
    else onChange({ ...range, start_at: value });
  };

  const onEndAtChange = (value: string) => {
    const [startHr, startMin] = timeStringToTimeTuple(range["start_at"]);
    const [endHr, endMin] = timeStringToTimeTuple(value);

    if (endHr === 0 && endMin === 0) onChange({ ...range, end_at: value });
    else if (endHr === startHr && !(endMin > startMin)) {
      onChange({
        ...range,
        start_at: padTime(endHr > 0 ? endHr - 1 : 23, endHr > 0 ? endMin : 0),
        end_at: value,
      });
    } else if (endHr < startHr)
      onChange({
        ...range,
        start_at: padTime(endHr > 0 ? endHr - 1 : 23, endHr > 0 ? endMin : 0),
        end_at: value,
      });
    else onChange({ ...range, end_at: value });
  };

  return (
    <>
      <TimePicker
        name={`${props.name}-start_at`}
        error={errors["start_at"]}
        errorMessage="Working periods cannot overlap"
        value={range["start_at"]}
        onChange={onStartAtChange}
      />
      <span className="m-0 py-0 px-3">to</span>
      <TimePicker
        name={`${props.name}-end_at`}
        error={errors["end_at"]}
        errorMessage="Working periods cannot overlap"
        value={range["end_at"]}
        onChange={onEndAtChange}
      />
    </>
  );
};

AvailabilityRange.defaultProps = {
  errors: { start_at: false, end_at: false },
};

export default AvailabilityRange;
