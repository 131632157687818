import { Controller } from "@hotwired/stimulus";
import consumer from "../../../../channels/consumer";
import { jsonSyntax } from "./lib/syntax";

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// Honestly, not sure how I feel about using action cable to get this behavior, maybe there is a better way to do it?
// --kwasi

export default class extends Controller {
  static values = { salesformId: String, inspectorContent: String };
  static targets = ["inspector", "hiddenInput"];

  subscribeToEnrichmentTestingChannel() {
    const self = this;

    consumer.subscriptions.create(
      {
        channel: "Enrichment::TestingChannel",
        salesform_id: self.salesformIdValue,
      },
      {
        received(data) {
          self.inspectorContentValue = data;
        },
      }
    );
  }

  connect() {
    this.form = this.element;
    this.subscribeToEnrichmentTestingChannel();
    this.setInspectorContent();
  }

  inspectorContentValueChanged(value, _prevValue) {
    this.setInspectorContent(value);
    this.setHiddenInputValue(value);
  }

  setInspectorContent(content = this.inspectorContentValue) {
    if (!this.hasInspectorTarget) return;

    this.inspectorTarget.innerHTML = isJson(content)
      ? jsonSyntax(JSON.stringify(JSON.parse(content), null, 2))
      : content;
  }

  setHiddenInputValue(value) {
    if (!this.hasHiddenInputTarget || !isJson(value)) return;

    this.hiddenInputTarget.setAttribute("value", value);
  }

  clearSampleData(_event) {
    this.setHiddenInputValue("{}");
    this.setInspectorContent("Nothing yet...");
  }
}
