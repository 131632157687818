import React, { ReactNode, useContext } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../../../../../../../../components/DisclosureButton";
import Options from "../../../../../../../../../components/Options";
import RouterTriggerContext from "../../../../../../../../../contexts/RouterTriggerContext";

type Props = {
  open: boolean;
  close: () => void;
  rule: Object;
  optionsProps: {
    field?: string;
    htmlPrefix?: string;
    value?: string;
    onChange: (value: string) => void;
    options: { value: any; label: string; help?: ReactNode }[];
  };
};

const Action = ({ open, ...props }: Props) => {
  const triggerContext = useContext(RouterTriggerContext);
  const objectType =
    triggerContext.triggerType === "Salesform" ? "Lead" : "Trigger";

  return (
    <>
      <DisclosureButton
        open={open}
        state="SUCCESS"
        content={
          <>
            Action:{" "}
            <span className="font-light italic">
              {props.rule["action"]
                .trim()
                .replace(/^\w/, (c) => c.toUpperCase())}{" "}
              {objectType}
            </span>
          </>
        }
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <div className="grid grid-cols-2 gap-x-5">
          <div className="col-span-1">
            <Options {...props.optionsProps} />
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default Action;
