import { Disclosure } from "@headlessui/react";
import { get, isArray, isEmpty } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { parseJSON, formatISO9075 } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import RouterTriggerContext from "../../../../../../../../../../contexts/RouterTriggerContext";
import { request } from "../../../../../../../../../../../lib/web";
import Debugger from "../../../../../../../../../../components/Debugger";
import { jsonSyntax } from "../../../../../../../../../../lib/syntax";

type Props = {
  action: string;
  testRun: {
    rule_id: string;
    pass: boolean;
    data: any;
    matching_clauses: Array<Object>;
    failing_clauses: Array<Object>;
  };
};

const TestRun = ({ testRun, action }: Props) => {
  const triggerContext = useContext(RouterTriggerContext);
  const componentRef = useRef<HTMLDivElement>();
  const [fieldOptions, setFieldOptions] = React.useState(null);

  const rulePath = `/account/routers/rules/${testRun.rule_id}`;

  useEffect(() => {
    if (testRun) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [testRun]);

  useEffect(() => {
    if (testRun) {
      request(
        window.location.origin,
        `${rulePath}/field_options.json`,
        {},
        "GET"
      )
        .then(({ data }) => {
          setFieldOptions(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const bgColor = testRun.pass ? "bg-green-dark" : "bg-yellow-dark";
  const faIcon = testRun.pass ? "fa-check-circle" : "fa-exclamation-circle";
  const objectType =
    triggerContext.triggerType === "Salesform" ? "Lead" : "Trigger";

  const renderClauses = () => {
    return testRun[testRun.pass ? "matching_clauses" : "failing_clauses"].map(
      (clause) => {
        const fieldLabel = fieldOptions
          ?.flatMap((g) => g?.options)
          .find((o) => o?.value === clause["field"])?.["label"];
        const access = clause["field"];
        const sample = JSON.parse(triggerContext.trigger?.["sample_data"]);

        const compare = (operator, value, data) => {
          switch (operator) {
            case "string_matches":
              return value === data;
            case "string_not_matches":
              return value !== data;
            case "string_contains":
              return value.toLowerCase().includes(data.toLowerCase());
            case "string_not_contains":
              return !value.toLowerCase().includes(data.toLowerCase());
            case "string_starts_with":
              return value.toLowerCase().startsWith(data.toLowerCase());
            case "string_not_starts_with":
              return !value.toLowerCase().startsWith(data.toLowerCase());
            case "string_ends_with":
              return value.toLowerCase().endsWith(data.toLowerCase());
            case "string_not_ends_with":
              return !value.toLowerCase().endsWith(data.toLowerCase());
            case "number_equal":
              return value === data;
            case "number_not_equal":
              return value !== data;
            case "number_greater_than":
              return value > data;
            case "number_greater_than_or_equal":
              return value >= data;
            case "number_less_than":
              return value < data;
            case "number_less_than_or_equal":
              return value <= data;
            case "date_equal":
              return new Date(value).getTime() === new Date(data).getTime();
            case "date_not_equal":
              return new Date(value).getTime() !== new Date(data).getTime();
            case "date_greater_than":
              return new Date(value).getTime() > new Date(data).getTime();
            case "date_greater_than_or_equal":
              return new Date(value).getTime() >= new Date(data).getTime();
            case "date_less_than":
              return new Date(value).getTime() < new Date(data).getTime();
            case "date_less_than_or_equal":
              return new Date(value).getTime() <= new Date(data).getTime();
            case "array_empty":
              return isEmpty(data);
            case "array_not_empty":
              return !isEmpty(data);
            case "array_contains":
              return value && data.includes(value);
            case "boolean_true":
              return !!value === true;
            case "boolean_false":
              return !!value === false;
            case "is_contained_in":
              return value && value.includes(data);
            case "is_null":
              return data === null;
            case "is_not_null":
              return data !== null;
            default:
              return false;
          }
        };

        const format = (data: any) =>
          isArray(data) ? (
            <ul className="list-disc">
              {data.map((item) => (
                <li
                  className={
                    testRun.pass &&
                    (!clause["value"]
                      ? ""
                      : compare(clause["operator"], clause["value"], item))
                      ? "font-bold"
                      : ""
                  }
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <span
              className={
                testRun.pass &&
                (!clause["value"]
                  ? ""
                  : compare(clause["operator"], clause["value"], data))
                  ? "font-bold"
                  : ""
              }
            >
              {[null, '', undefined].includes(data) ? '<no data>' : data}
            </span>
          );

        return (
          <div key={clause["id"]} className="grid grid-cols-5 border-b py-2 ">
            <div className="col-span-2">{fieldLabel}: </div>
            <div className={`col-span-3 `}>
              {format(get(sample, access)) || "<no data>"}
            </div>
          </div>
        );
      }
    );
  };

  return (
    <div
      ref={componentRef}
      className={`w-full rounded-md ring-1 ring-black ring-opacity-5 shadow`}
    >
      <div
        className={`flex ${bgColor} rounded-t-md text-white flex-row space-x-4 py-4 px-2.5`}
      >
        <div>
          <span>
            <i className={`fas ${faIcon} fa-lg  ${bgColor} rounded-full`} />
          </span>
        </div>

        <div>
          <h1 className="text-lg">
            Your rule{" "}
            {testRun.pass ? (
              <span className="font-bold">would</span>
            ) : (
              <>
                would <span className="font-bold">not</span>
              </>
            )}{" "}
            have {action.endsWith("e") ? `${action}d` : `${action}ed`} this{" "}
            {objectType.toLowerCase()}
          </h1>
          <div>
            If this was unexpected, edit your conditions below and retest.
          </div>
        </div>
      </div>

      <Disclosure as="div" className="dark:bg-neutral-800" defaultOpen={!testRun.pass}>
        {(disclosureProps) => (
          <>
            <Disclosure.Button className="w-full flex flex-row justify-between py-4 px-2.5">
              <div className="flex flex-row space-x-4 text-left">
                <div>
                  <span className="text-black">
                    <i className="fas fa-brackets-curly fa-lg" />
                  </span>
                </div>
                <div>
                  <p className="font-bold text-sm">SAMPLE DATA</p>
                  <p className="text-xs text-gray">
                    Created -{" "}
                    {formatISO9075(
                      utcToZonedTime(
                        parseJSON(testRun["created_at"]),
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    )}
                  </p>
                </div>
              </div>
              <div>
                <span className="text-neutral-600">
                  <i
                    className={`${
                      disclosureProps.open ? "rotate-180" : ""
                    } fas fa-chevron-down fa-lg transition-transform transform`}
                  />
                </span>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel
              as="div"
              unmount={false}
              className="p-5 space-y-2 border-t"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y">
                <div className="md:col-span-1 md:border-r mr-3 pr-3 space-y-2">
                  <div className="uppercase font-bold text-xs">
                    Items that {testRun.pass ? "matched" : "didn't match"}
                  </div>
                  <div className="space-y-3 max-h-64 overflow-auto">
                    {!!fieldOptions ? renderClauses() : null}
                  </div>
                </div>
                <div className="md:col-span-1 space-y-2">
                  <div className="uppercase font-bold text-xs">Raw Data</div>
                  <div>
                    <Debugger
                      id="test-run-data"
                      syntaxHighlighter={jsonSyntax}
                      height="250px"
                      data={JSON.stringify(
                        { ...testRun, data: JSON.parse(testRun["data"]) },
                        null,
                        2
                      )}
                    />
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default TestRun;
