import React, { useEffect } from "react";
import { SelectOptionActionMeta } from "react-select";

import TeamIdContext from "../../../../../../../../../../contexts/TeamIdContext";
import CreateableAsyncSelect from "../../../../../../../../../../components/CreateableAsyncSelect";
import { request } from "../../../../../../../../../../../lib/web";
import { AxiosResponse } from "axios";
import LineupModal from "./LineupModal";

type Props = {
  value: any;
  onChange: (value: any, actionMeta?: SelectOptionActionMeta<any>) => void;
  isOptionDisabled?: (option: any) => boolean;
  filterOption?: (option: any) => boolean;
  placeholder?: string;
};

const LineupSelect = ({ value, ...props }: Props) => {
  const teamId = React.useContext(TeamIdContext);
  const [lineupCreateModalOpen, setLineupCreateModalOpen] = React.useState(
    false
  );
  const [createdLineup, setCreatedLineup] = React.useState(null);
  const [selectValue, setSelectValue] = React.useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const handleAddLineup = (lineupName: any) => {
    request(
      window.location.origin,
      `/account/teams/${teamId}/lineups.json`,
      { lineup: { name: lineupName } },
      "POST"
    )
      .then(({ data }: AxiosResponse) => {
        setLineupCreateModalOpen(true);
        setCreatedLineup(data);
        // // hacky way to get new lineup into select
        props.onChange({ text: data.name, id: data.id });
      })
      .catch(console.error);
  };

  return (
    <>
      <CreateableAsyncSelect
        searchPath={`/account/teams/${teamId}/lineups/search`}
        // we also check option.label so we can render the create/add option properly
        getOptionLabel={(option) => option.text || option.label}
        getOptionValue={(option) => option.id}
        placeholder="Choose lineup..."
        isValidNewOption={(inputValue: string) => !!inputValue}
        formatCreateLabel={(inputValue: string) => (
          <div className="w-full h-full">
            <span className="text-green w-min">
              <i className="fas fa-plus-circle bg-white rounded-full mr-2" />
            </span>
            <span>{`Add Lineup: "${inputValue}"`}</span>
          </div>
        )}
        onCreateOption={handleAddLineup}
        value={selectValue}
        {...props}
      />
      <LineupModal
        lineup={createdLineup}
        isOpen={lineupCreateModalOpen}
        onClose={() => {
          setLineupCreateModalOpen(false);
          setCreatedLineup(null);
        }}
      />
    </>
  );
};

export default LineupSelect;
