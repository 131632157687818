import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { AsyncPaginate as Select } from "react-select-async-paginate";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../components/DisclosureButton";
import { AxiosResponse } from "axios";
import { request } from "../../../lib/web";
import { jsonSyntax } from "../../lib/syntax";
import Debugger from "../../components/Debugger";

type Props = {
  open: boolean;
  close: () => void;
  crmTrigger: Object;
  onUpdate: (data: object) => void;
};

const SampleData = ({ open, close, ...props }: Props) => {
  const [sampleData, setSampleData] = useState(
    JSON.parse(props.crmTrigger?.["sample_data"])
  );
  const [isLoading, setIsLoading] = useState(false);

  const getState = () => {
    if (isLoading) return "LOADING";
    else if (!isEmpty(sampleData)) return "SUCCESS";
    else return "DEFAULT";
  };

  const loadOptions = async (_search, _loadedOptions, { page }) => {
    setIsLoading(true);
    const { data }: AxiosResponse = await request(
      window.location.origin,
      `/account/crm_triggers/${props.crmTrigger["id"]}/search_sample_data_options`,
      {},
      "GET"
    );
    setIsLoading(false);

    return {
      options: data.results,
      hasMore: data.pagination.more,
      additional: {
        page: page + (data.pagination.more ? 1 : 0),
      },
    };
  };

  const handleContinue = () => {
    if (props.onUpdate) {
      setIsLoading(true);
      props.onUpdate({
        sample_data: JSON.stringify(sampleData || {}),
      });
      setIsLoading(false);
    }
    close();
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={getState()}
        content={<span>Select Testing Data from Your CRM</span>}
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <Select
          defaultOptions
          // value={sampleData.toString()}
          styles={{
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              backgroundColor: isSelected
                ? '#1c4cc3'
                : isFocused
                ? '#448eef'
                : "transparent",
            }),
          }}
          className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
          classNamePrefix="react-select"
          loadOptions={loadOptions}
          placeholder={
            isLoading
              ? "Loading data from your CRM (this may take a few moments)..."
              : "Select..."
          }
          additional={{
            page: 1,
          }}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          onChange={({ value }, _meta) => setSampleData(value)}
        />
        <Debugger
          id="crm-trigger-sample-data-debugger"
          syntaxHighlighter={isEmpty(sampleData) ? undefined : jsonSyntax}
          data={
            isEmpty(sampleData)
              ? "Nothing yet..."
              : JSON.stringify(sampleData, null, 2)
          }
        />
        <div className="buttons">
          <button className="button" onClick={handleContinue}>
            {!isEmpty(sampleData) ? "Continue" : "Skip"}
          </button>
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default SampleData;
