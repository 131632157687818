import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from "../../../../../../../../../../../components/AsyncSelect";
import { post, put } from "@rails/request.js";
import { useComponentDidMount } from "../../../../../../../../../../../hooks";
import { debounce } from "lodash";
import ValueSelect from "./components/ValueSelect";

type Props = {
  postProcessing: Object;
  rule: Object;
  modelType: "prospect" | "booking";
  fetchPostProcessings: Function;
};

const Form = (props: Props) => {
  const rulePostProcessingsPath = `/account/routers/rules/${props.rule["id"]}/post_processings`;

  const [postProcessingField, setPostProcessingField] = useState(
    props.postProcessing?.["field"]
  );

  const [postProcessingValue, setPostProcessingValue] = useState(
    props.postProcessing?.["value"]
  );

  const [fieldErrors, setFieldErrors] = useState([]);

  const isComponentMounted = useComponentDidMount();

  const handleFieldChange = (value, action) => {
    setPostProcessingField(value.value);
  };

  const handlePostProcessingValueChange = (selection) => {
    setPostProcessingValue(selection.value);
  };

  const createOrUpdate = useCallback(async () => {
    setFieldErrors([]);
    const field = postProcessingField;
    const value = postProcessingValue;
    const model_type = props.modelType;

    const options = {
      body: JSON.stringify({
        routers_rules_post_processing: { field, value, model_type },
      }),
      contentType: "application/json",
      responseKind: "json",
    };

    let response;

    if (props.postProcessing) {
      const postProcessingPath = `/account/routers/rules/post_processings/${props.postProcessing["id"]}`;
      response = await put(`${postProcessingPath}.json`, options);
    } else response = await post(`${rulePostProcessingsPath}.json`, options);

    if (response.ok) {
      if (!props.postProcessing) {
        setPostProcessingField(null);
        setPostProcessingValue(null);
      }
      if (props.fetchPostProcessings) props.fetchPostProcessings();
    } else {
      setFieldErrors((await response.json)["field"]);
    }
  }, [postProcessingField, postProcessingValue, props.modelType]);

  useEffect(() => {
    if (postProcessingField && isComponentMounted) createOrUpdate();
  }, [postProcessingField, postProcessingValue, createOrUpdate]);

  return (
    <form className="form">
      <div
        className={`space-y-2 lg:space-y-0 lg:flex lg:flex-row lg:space-x-2 ${
          fieldErrors.length ? "border rounded border-red p-1" : ""
        }`}
      >
        <div className="lg:w-1/2">
          <AsyncSelect
            valueString={postProcessingField}
            searchPath={`${rulePostProcessingsPath}/field_options`}
            searchParameters={{ model_type: props.modelType }}
            onChange={handleFieldChange}
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.id}
            placeholder="Select a field"
          />
        </div>
        <div className="lg:w-1/2">
          <ValueSelect
            postProcessing={props.postProcessing}
            modelType={props.modelType}
            searchPath={`${rulePostProcessingsPath}/value_options`}
            onChange={handlePostProcessingValueChange}
          />
        </div>
      </div>
      {!!fieldErrors.length && (
        <div className="mt-1.5 text-xs text-red">
          {fieldErrors.map((error) => (
            <p key={postProcessingField}>
              {postProcessingField} {error}
            </p>
          ))}
        </div>
      )}
    </form>
  );
};

export default Form;
