import React, { ReactNode } from "react";

type Props = {
  content: ReactNode;
  simple?: boolean;
  onDismiss?: (args0?: any) => void;
};

const Error = ({ content, onDismiss, simple }: Props) => {
  const spacingStyles = simple ? '' : 'px-5 py-4 mb-3'
  const borderStyles = simple ? '' : 'rounded-md border border-red'
  const backgroundStyles = simple ? '' : 'bg-red-light'
  const textStyles = simple ? 'text-red' : 'text-red-darker'

  return content ? (
    <div className={`flex flex-row justify-between ${backgroundStyles} ${borderStyles} ${spacingStyles}`}>
      <h3 className={`text-sm font-light ${textStyles}`}>{content}</h3>
      {onDismiss && (
        <span className={`text-sm ${textStyles}`} onClick={onDismiss}>
          <i className="fal fa-times"></i>
        </span>
      )}
    </div>
  ) : null;
};

Error.defaultProps = {
  onDismiss: undefined,
  simple: false
};

export default Error;
