export default (hit, { html, components }) => {
  const { obfuscated_id } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/memberships/${obfuscated_id}"
    >
      <div class="space-y-3">
        <div class="font-bold">
          ${components.Highlight({
            attribute: "full_name",
            hit,
          })}
        </div>
        <div class="italic">
          ${components.Highlight({ attribute: "email", hit })}
        </div>
      </div>
    </a>
  `;
};
