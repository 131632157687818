import React, { HTMLAttributes } from "react";

type OptionAttributes = HTMLAttributes<HTMLOptionElement> & {
  label?: string;
  text?: string;
};

type Props = {
  name: string;
  value: string;
  multiple?: boolean;
  onChange: (value: string) => void;
  options: OptionAttributes[];
  error?: boolean;
};

const Select = ({
  options,
  onChange,
  error,
  ...selectProps
}: Props) => {
  const handleChange = ({ target }) => onChange(target.value);

  return (
    <select
      {...selectProps}
      className={error
        ? "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md cursor-pointer pr-10 border-red text-red-darker placeholder-red focus:outline-none focus:ring-red focus:border-red dark:bg-sealBlue-300 dark:text-sealBlue-900"
        : "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md cursor-pointer focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
      }
      onChange={handleChange}
      autoComplete={selectProps.name}
    >
      {options.map(({ text, ...attrs }: OptionAttributes) => (
        <option {...attrs}>{text || attrs.label}</option>
      ))}
    </select>
  );
};

Select.defaultProps = {
  multiple: false,
  error: false,
};

export default Select;
