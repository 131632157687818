export default (hit, { html, components }) => {
  const { obfuscated_id, name, page_url, form_url } = hit;
  return html`
    <a
      class="text-black no-underline hover:no-underline w-full"
      href="/account/salesforms/${obfuscated_id}"
    >
      <div class="space-y-3">
        ${name
          ? html`<div class="font-bold">
              ${components.Highlight({
                attribute: "name",
                hit,
              })}
            </div>`
          : ""}
        ${page_url || form_url
          ? html`<div class="italic">
              ${components.Highlight({
                attribute: page_url ? "page_url" : "form_url",
                hit,
              })}
            </div>`
          : ""}
      </div>
    </a>
  `;
};
