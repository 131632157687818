import Autosave from "stimulus-rails-autosave";
import { request } from "../packs/lib/web";

export default class extends Autosave {
  static values = {
    delay: Number,
    fieldIds: { type: Array, default: [] },
  };

  connect() {
    super.connect();
    this.fieldIdsValue.forEach((fieldId) => {
      const field = document.getElementById(fieldId);
      if (field) field.addEventListener("keyup", this.save);
    });
  }

  save(event) {
    if (!window._rails_loaded) return;
    const modelName = event.target.getAttribute("name").split("[")[0];
    const fieldName = event.target.getAttribute("name").match(/\[(.*?)\]/)[1];

    request(
      window.location.origin,
      new URL(this.element.action).pathname,
      {
        [modelName]: {
          [fieldName]: event.target.value,
        },
      },
      "PUT"
    )
      .then(() => {
        // TODO: show a checkmark or something if successful
      })
      .catch(
        // TODO: visually indicate that the save failed
        console.error
      );
  }
}
