import CRMLinkController from "../../../xkit/crm_link_controller";

export default class extends CRMLinkController {
  async confirmTrackers() {
    document.querySelector("#confirm_trackers_form").requestSubmit();
  }

  async show() {
    await super.show({ afterFinish: this.confirmTrackers });
  }
}
