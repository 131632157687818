import { Controller } from "@hotwired/stimulus";
import { debounce, isEmpty } from "lodash";
import { get, put } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "routerSearchInput",
    "routerSearchResults",
    "importableRules",
    "importButton",
  ];
  static values = {
    teamId: String,
    triggerType: String,
    currentRouterId: String,
    selectedRouterIds: Array,
    selectedRuleIds: Array,
    confirmationMessage: String,
  };

  connect() {
    const controller = this;
    this.routerSearchPath = `/account/teams/${this.teamIdValue}/routers/search`;

    this.searchRouters().then(this.renderRouterSearchResults.bind(controller));

    this.routerSearchInputTarget.addEventListener(
      "input",
      debounce((event) => {
        this.searchRouters(event.target.value).then(
          this.renderRouterSearchResults.bind(controller)
        );
      }, 100)
    );
  }

  selectedRouterIdsValueChanged(value, _prevValue) {
    this.routerSearchResultsTarget
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => {
        const routerId = checkbox.value;
        checkbox.checked = value.includes(routerId);
      });
  }

  selectedRuleIdsValueChanged(value, _prevValue) {
    this.importableRulesTarget
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => {
        checkbox.checked = value.includes(checkbox.value);
      });

    if (this.hasImportButtonTarget) {
      if (isEmpty(value)) this.importButtonTarget.classList.add("hidden");
      else {
        this.importButtonTarget.textContent = `Import ${value.length} Rule${
          value.length !== 1 ? "s" : ""
        }`;
        this.importButtonTarget.classList.remove("hidden");
      }
    }
  }

  async searchRouters(query = "") {
    const response = await get(
      `${this.routerSearchPath}?query=${query}` +
        `&limit=100` +
        `&trigger_type=${this.triggerTypeValue}` +
        `&excluded_ids[]=${this.currentRouterIdValue}`
    );

    return response.json;
  }

  async appendRouterRules(routerId) {
    const indexRouterRulesPath = `/account/routers/${routerId}/rules/importable`;
    await get(indexRouterRulesPath, { responseKind: "turbo-stream" });
  }

  handleRouterCheckboxChange(event) {
    const checkbox = event.target;
    const routerId = checkbox.value;
    if (checkbox.checked && !this.selectedRouterIdsValue.includes(routerId)) {
      this.selectedRouterIdsValue =
        this.selectedRouterIdsValue.concat(routerId);
      this.appendRouterRules(routerId);
    } else {
      // remove the router form selectedRouterIds
      this.selectedRouterIdsValue = this.selectedRouterIdsValue.filter(
        (id) => id !== routerId
      );

      this.importableRulesTarget
        .querySelectorAll(`[data-id="importable_rules_router_${routerId}"]`)
        .forEach((node) => {
          const routerRuleCheckboxes = node.querySelectorAll(
            'input[type="checkbox"]'
          );

          // collect rule ids that need to be removed from selectedRuleIds
          const ruleIdsToRemove = [...routerRuleCheckboxes].map(
            (checkbox) => checkbox.value
          );

          // remove rules from selectedRuleIds
          this.selectedRuleIdsValue = this.selectedRuleIdsValue.filter(
            (ruleId) => ruleIdsToRemove.indexOf(ruleId) < 0
          );

          // remove router's rules from UI
          node.remove();
        });
    }
  }

  handleRuleCheckboxChange(event) {
    const checkbox = event.target;
    if (checkbox.checked && !this.selectedRuleIdsValue.includes(checkbox.value))
      this.selectedRuleIdsValue = this.selectedRuleIdsValue.concat(
        checkbox.value
      );
    else
      this.selectedRuleIdsValue = this.selectedRuleIdsValue.filter(
        (id) => id !== checkbox.value
      );
  }

  renderRouterSearchResults({ results }) {
    if (!this.hasRouterSearchResultsTarget) return;

    const resultsHTML = results
      .map(
        (router) => `
    <label class="relative flex items-start">
      <div class="flex items-center p-2">
        <input style="transform: scale(1.75)" type="checkbox" value="${router.id}" class="focus:ring-blue h-4 w-4 text-blue border-gray-300 rounded scale-150">
      </div>
      <div class="ml-2.5 text-lg">
        <div class="select-none">${router.text}</div>
      </div>
    </label>
    `
      )
      .join("");

    this.routerSearchResultsTarget.innerHTML = `${resultsHTML}`;
    this.routerSearchResultsTarget
      .querySelectorAll("input[type='checkbox']")
      .forEach((checkbox) => {
        checkbox.addEventListener(
          "change",
          this.handleRouterCheckboxChange.bind(this)
        );
        checkbox.checked = this.selectedRouterIdsValue.includes(checkbox.value);
      });
  }

  handleImportableConnect(event) {
    const ruleset = event.target;
    const { routerId } = event.detail;
    ruleset.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      const ruleId = checkbox.value;
      checkbox.addEventListener(
        "change",
        this.handleRuleCheckboxChange.bind(this)
      );
      if (
        this.selectedRuleIdsValue.includes(ruleId) ||
        this.selectedRouterIdsValue.includes(routerId)
      ) {
        checkbox.checked = true;
        this.selectedRuleIdsValue = this.selectedRuleIdsValue.concat(ruleId);
      }
    });
  }

  handleRuleCheckboxChange(event) {
    const checkbox = event.target;
    if (checkbox.checked && !this.selectedRuleIdsValue.includes(checkbox.value))
      this.selectedRuleIdsValue = this.selectedRuleIdsValue.concat(
        checkbox.value
      );
    else
      this.selectedRuleIdsValue = this.selectedRuleIdsValue.filter(
        (id) => id !== checkbox.value
      );
  }

  async importRules(event) {
    if (
      !window.confirm(this.confirmationMessageValue)
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }

    const ruleIds = this.selectedRuleIdsValue;
    const count = ruleIds.length;

    const modalTrigger = document.getElementById(
      `rule_import_modal_trigger_router_${this.currentRouterIdValue}`
    );
    if (modalTrigger)
      modalTrigger.innerHTML = `<i class='fas fa-circle-notch fa-spin mr-3'></i>Importing ${count} Rule${
        count !== 1 ? "s" : ""
      }...`;

    const response = await put(
      `/account/routers/${this.currentRouterIdValue}/import_rules`,
      {
        body: JSON.stringify({ router: { rule_ids: ruleIds } }),
        responseKind: "turbo-stream",
      }
    );

    if (response.ok) {
      this.dispatch("close", { prefix: "modal" });
    } else {
      const error = await response.json;
      console.error(error);
    }
  }
}
