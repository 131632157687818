import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static values = { prospectId: String, sidekiqJobId: String };

  subscribeToChannel() {
    if (!this.hasProspectIdValue) return;
    const self = this;

    consumer.subscriptions.create(
      {
        channel: "Enrichment::ProspectChannel",
        prospect_id: self.prospectIdValue,
      },
      {
        received(data) {
          if (!self.hasSidekiqJobIdValue) return;
          if (self.sidekiqJobIdValue == data.jid)
            self.refreshIcon.classList.remove("fa-spin");
        },
      }
    );
  }

  connect() {
    this.refreshIcon = this.element.firstElementChild;
    this.subscribeToChannel();
  }

  async requestRefresh() {
    this.refreshIcon.classList.add("fa-spin");
    const response = await patch(
      `/account/prospects/${this.prospectIdValue}/refresh_enrichment.json`,
      { responseKind: "json" }
    );
    if (response.ok) {
      const { jid } = await response.json;
      this.sidekiqJobIdValue = jid;
    } else console.error(response);
  }
}
