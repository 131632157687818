import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"]
  connect() {
    if (this.inIframe()) {
      this.buttonTarget.className = this.buttonTarget.className.replace("hidden", "")
      this.buttonTarget.addEventListener("click", () => {
        var target = parent.postMessage ? parent : (parent.document.postMessage ? parent.document : undefined);
        target.postMessage("close_sfrm_popup", "*")
      })
    }
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}