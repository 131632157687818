import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../../../../../../../../components/DisclosureButton";
import { useComponentDidMount } from "../../../../../../../../../hooks";
import UrlForm from "./components/UrlForm";
import DestinationTypeSelect from "./components/DestinationTypeSelect";
import EventTypeSelect from "./components/EventTypeSelect";
import MembershipSelect from "./components/MembershipSelect";
import LineupSelect from "./components/LineupSelect";
import Notice from "../../../../../../../../../../alerts/Notice";
import Error from "../../../../../../../../../../alerts/Error";
import Options from "../../../../../../../../../components/Options";
import RouterTriggerContext from "../../../../../../../../../contexts/RouterTriggerContext";
import { debounce } from "lodash";

type Props = {
  open: boolean;
  close: () => void;
  rule: Object;
  onUpdate: (data: Object) => void;
};

const Destination = ({ open, close, ...props }: Props) => {
  const triggerContext = React.useContext(RouterTriggerContext);

  const getDestinationTypeSelectValue = () => {
    if (props.rule["destination_url"])
      return { label: "Redirect to URL", value: "url" };
    else if (props.rule["destination_membership_id"])
      return { label: "Assign Team Member", value: "membership" };
    else if (props.rule["destination_lineup_id"])
      return { label: "Send to Lineup", value: "lineup" };
    else return null;
  };

  const [nylasAuthUrl, setNylasAuthUrl] = useState(null);

  const [destinationTypeSelectValue, setDestinationTypeSelectValue] =
    useState(null);
  const [membershipSelectValue, setMembershipSelectValue] = useState(
    props.rule["destination_membership"]
      ? {
          text: props.rule["destination_membership"]["name"],
          id: props.rule["destination_membership"]["id"],
        }
      : null
  );

  const [lineupSelectValue, setLineupSelectValue] = useState(
    props.rule["destination_lineup"]
      ? {
          text: props.rule["destination_lineup"]["name"],
          id: props.rule["destination_lineup"]["id"],
        }
      : null
  );

  const [eventTypeSelectValue, setEventTypeSelectValue] = useState(
    props.rule["destination_event_type"]
      ? {
          text: props.rule["destination_event_type"]["title"],
          id: props.rule["destination_event_type"]["id"],
        }
      : { text: "No Meeting", id: null }
  );

  const [afterBookingRedirectUrl, setAfterBookingRedirectUrl] = useState(
    props.rule["after_booking_redirect_url"] || ""
  );

  const [invalidAfterBookingRedirectUrl, setInvalidAfterBookingRedirectUrl] =
    useState(undefined);

  const isComponentMounted = useComponentDidMount();

  const [error, setError] = useState(null);

  const fetchNylasAuthUrl = async () => {
    const response = await fetch(`/nylas_accounts/authorization_url`);
    const authUrl = await response.text();
    setNylasAuthUrl(authUrl);
  };

  useEffect(() => {
    void fetchNylasAuthUrl();
  }, []);

  useEffect(() => {
    setDestinationTypeSelectValue(getDestinationTypeSelectValue());
  }, [props.rule]);

  useEffect(() => {
    if (
      props.rule["destination_membership"] &&
      props.rule["destination_event_type"]
    ) {
      const membership = props.rule["destination_membership"];
      if (membership && !membership?.["has_calendar_integration"]) {
        setError(
          membership["is_current_user"] ? (
            <>
              You have not connected your calendar yet! You must have a calendar
              integration in order to host meetings.
              <p>
                <a href={nylasAuthUrl}>Connect your calendar now!</a> (we
                promise it will only take a few seconds)
              </p>
            </>
          ) : (
            <>
              This team member has not connected their calendar yet! They must
              have a calendar integration in order to host meetings.
            </>
          )
        );
      }
    }
  }, [props.rule, nylasAuthUrl]);

  useEffect(() => {
    if (isComponentMounted && membershipSelectValue) {
      if (props.onUpdate)
        props.onUpdate({
          ...props.rule,
          destination_membership_id: membershipSelectValue.id,
          destination_membership: {
            name: membershipSelectValue.text,
            id: membershipSelectValue.id,
          },
          after_booking_redirect_url: afterBookingRedirectUrl,
          destination_lineup_id: null,
          destination_lineup: null,
          destination_url: null,
        });
    }
  }, [membershipSelectValue]);

  useEffect(() => {
    if (isComponentMounted && lineupSelectValue) {
      if (props.onUpdate)
        props.onUpdate({
          ...props.rule,
          destination_membership_id: null,
          destination_membership: null,
          destination_lineup_id: lineupSelectValue.id,
          after_booking_redirect_url: afterBookingRedirectUrl || null,
          destination_lineup: {
            name: lineupSelectValue.text,
            id: lineupSelectValue.id,
          },
          destination_url: null,
        });
    }
  }, [lineupSelectValue]);

  useEffect(() => {
    if (isComponentMounted && !invalidAfterBookingRedirectUrl) {
      if (props.onUpdate)
        props.onUpdate({
          ...props.rule,
          after_booking_redirect_url: afterBookingRedirectUrl || null,
        });
    }
  }, [afterBookingRedirectUrl]);

  useEffect(() => {
    if (isComponentMounted && eventTypeSelectValue) {
      if (props.onUpdate)
        props.onUpdate({
          ...props.rule,
          destination_event_type_id: eventTypeSelectValue.id,
          destination_event_type: eventTypeSelectValue.id
            ? {
                title: eventTypeSelectValue.text,
                id: eventTypeSelectValue.id,
              }
            : null,
        });
      close();
    }
  }, [eventTypeSelectValue]);

  const handleDestinationTypeSelectChange = (selection: any) => {
    if (selection !== destinationTypeSelectValue) {
      setDestinationTypeSelectValue(selection);
      setMembershipSelectValue(null);
      setLineupSelectValue(null);
      setEventTypeSelectValue(null);
    }
  };

  const handleMembershipSelectChange = (selection: any) => {
    setMembershipSelectValue(selection);
  };

  const handleLineupSelectChange = (selection: any) => {
    setLineupSelectValue(selection);
  };

  const handleEventTypeSelectChange = (selection: any) => {
    setEventTypeSelectValue(selection);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      console.log(`ivu(${string}) -> `, true);
      return true;
    } catch (err) {
      console.log(`ivu(${string}) -> `, false);
      return false;
    }
  };

  const handleAfterBookingRedirectUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    if (isValidUrl(input) || !input) {
      setInvalidAfterBookingRedirectUrl(false);
      setAfterBookingRedirectUrl(input);
    } else {
      setInvalidAfterBookingRedirectUrl(true);
    }
  };

  const handleUrlFormSubmit: React.FormEventHandler<HTMLFormElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const url = event.target["routers_rule[destination_url]"].value.trim();

    if (url && url !== props.rule["destination_url"]) {
      if (props.onUpdate)
        props.onUpdate({
          ...props.rule,
          destination_membership_id: null,
          destination_membership: null,
          destination_event_type_id: null,
          destination_event_type: null,
          destination_lineup_id: null,
          destination_lineup: null,
          destination_url: url,
        });

      close();
    }
  };

  const getState = () => {
    if (
      !error &&
      (props.rule["destination_membership_id"] ||
        props.rule["destination_lineup_id"] ||
        props.rule["destination_url"] ||
        props.rule["action"] === "ignore")
    ) {
      return "SUCCESS";
    } else return "DEFAULT";
  };

  const buttonContent = () => {
    if (props.rule["destination_membership_id"])
      return (
        <>
          Destination:{" "}
          <span className="font-light italic">
            {props.rule["destination_event_type"]
              ? `Book ${props.rule["destination_event_type"]["title"]} with ${props.rule["destination_membership"]["name"]}`
              : `Assign to ${props.rule["destination_membership"]["name"]}`}
          </span>
        </>
      );
    else if (props.rule["destination_lineup_id"])
      return (
        <>
          Destination:{" "}
          <span className="font-light italic">
            {props.rule["destination_event_type"]
              ? `Book ${props.rule["destination_event_type"]["title"]} with ${props.rule["destination_lineup"]["name"]}`
              : `Send to ${props.rule["destination_lineup"]["name"]}`}
          </span>
        </>
      );
    else if (props.rule["destination_url"])
      return (
        <>
          Destination:{" "}
          <a className="font-light italic" href={props.rule["destination_url"]}>
            {props.rule["destination_url"]}
          </a>
        </>
      );
    else return "Destination";
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={getState()}
        content={
          <>
            <div>{buttonContent()}</div>
            {!open && <Error simple content={error} />}
          </>
        }
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <Error content={error} />
        <div className="flex flex-row w-full space-x-4">
          <div className="flex-auto flex flex-shrink-0 flex-col w-1/2 space-y-4">
            <div className="flex-shrink-0">
              <DestinationTypeSelect
                rule={props.rule}
                value={destinationTypeSelectValue}
                onChange={handleDestinationTypeSelectChange}
              />
            </div>
          </div>
          {destinationTypeSelectValue?.value === "url" && (
            <div className="flex-auto flex-shrink-0">
              <UrlForm rule={props.rule} onSubmit={handleUrlFormSubmit} />
            </div>
          )}
          <div className="flex-auto space-y-4 w-1/2">
            {destinationTypeSelectValue?.value === "membership" && (
              <MembershipSelect
                onChange={handleMembershipSelectChange}
                value={membershipSelectValue}
              />
            )}
            {destinationTypeSelectValue?.value === "lineup" && (
              <LineupSelect
                onChange={handleLineupSelectChange}
                value={lineupSelectValue}
              />
            )}
            {((destinationTypeSelectValue?.value === "membership" &&
              membershipSelectValue) ||
              (destinationTypeSelectValue?.value === "lineup" &&
                lineupSelectValue)) &&
              triggerContext.triggerType === "Salesform" && (
                <>
                  <div>
                    <div className="font-semibold">Schedule a meeting</div>
                    <EventTypeSelect
                      onChange={handleEventTypeSelectChange}
                      value={eventTypeSelectValue}
                    />
                  </div>
                  {eventTypeSelectValue?.["id"] && (
                    <div className="flex flex-col">
                      <label
                        htmlFor="after_booking_redirect_url"
                        className="font-medium"
                      >
                        After Booking Redirect URL
                      </label>
                      <input
                        id="after_booking_redirect_url"
                        type="url"
                        onChange={debounce(
                          handleAfterBookingRedirectUrlChange,
                          500
                        )}
                        defaultValue={afterBookingRedirectUrl}
                        className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
                      ></input>
                      {invalidAfterBookingRedirectUrl && (
                        <p className="text-red-500">URL is not valid</p>
                      )}
                    </div>
                  )}
                  <div>
                    <label
                      className="block font-semibold"
                      htmlFor="pull_owner_from_crm"
                    >
                      Update Owner of CRM Record
                    </label>
                    <div className="">
                      <Options
                        onChange={(pull_owner_from_crm) => {
                          props.onUpdate({
                            pull_owner_from_crm:
                              pull_owner_from_crm === "true" ? true : false,
                          });
                        }}
                        field="pull_owner_from_crm"
                        htmlPrefix="rule"
                        value={
                          props.rule["pull_owner_from_crm"].toString() ||
                          "false"
                        }
                        options={[
                          {
                            label: "Update Owner",
                            value: "false",
                            help:
                              triggerContext.triggerType === "Salesform" ? (
                                <>
                                  If an incoming lead already exists in your
                                  CRM,{" "}
                                  <span className="font-bold">
                                    the lead's owner in the CRM will be updated
                                  </span>{" "}
                                  to the team member that Salesform routes to.
                                </>
                              ) : (
                                <>
                                  If an incoming CRM record already exists in
                                  Salesform,{" "}
                                  <span className="font-bold">
                                    the record's owner in the CRM will be
                                    updated
                                  </span>{" "}
                                  to the team member that Salesform routes to.
                                </>
                              ),
                          },
                          {
                            label: "Don't update Owner",
                            value: "true",
                            help:
                              triggerContext.triggerType === "Salesform" ? (
                                <>
                                  If an incoming lead already exists in your
                                  CRM,{" "}
                                  <span className="font-bold">
                                    the lead's owner in the CRM will not be
                                    updated
                                  </span>{" "}
                                  to the team member that Salesform routes to.
                                  <div className="p-4 bg-yellow-light text-yellow-darker border-yellow-darker mt-2 rounded-sm shadow-sm">
                                    In this case, Salesform will prioritze
                                    routing the lead to their existing owner
                                    listed in the CRM rather than routing to a
                                    new team member.
                                  </div>
                                </>
                              ) : (
                                <>
                                  If an incoming CRM record already exists in
                                  Salesform,{" "}
                                  <span className="font-bold">
                                    the record's owner in the CRM will not be
                                    updated
                                  </span>{" "}
                                  to the team member that Salesform routes to.
                                  <Notice>
                                    In this case, Salesform will prioritze
                                    routing the CRM record's corresponding lead
                                    to their existing owner listed in the CRM
                                    rather than routing to a new team member.
                                  </Notice>
                                </>
                              ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default Destination;
