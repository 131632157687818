import React from "react";

type Props = {
  children: React.ReactNode;
};

export const RowControl = ({
  hidden,
  children,
  onClick,
}: {
  onClick?: VoidFunction;
  hidden?: boolean;
  children: React.ReactNode;
}) => (
  <div
    onClick={onClick}
    className="inline-block text-center h-5 w-5 ml-1 align-middle cursor-pointer"
  >
    {!hidden && children}
  </div>
);

RowControl.defaultProps = {
  onClick: undefined,
  hidden: false,
};

export const Row = ({ children }: Props) => (
  <div className="flex items-center py-1 h-11">{children}</div>
);

export default Row;
