import React from "react";
import Select, { ActionMeta } from "react-select";

import {
  ClauseOperatorOption,
  ConditionsClause,
} from "../../../../../../../../../../../../../../../types";

type Props = {
  isDisabled: boolean;
  clause: Object;
  options: ClauseOperatorOption[];
  filterOption: (option: any) => boolean;
  value: ClauseOperatorOption;
  isLoading: boolean;
  onChange: (value: any) => void;
};

const OperatorSelect = ({ clause, onChange, ...props }: Props) => {
  const htmlId = `routers/rules/conditions/clause_operator_${clause["id"]}`;

  const handleChange = (
    selection: ClauseOperatorOption,
    _action: ActionMeta<ClauseOperatorOption>
  ) => {
    if (onChange) onChange(selection);
  };

  const validationClasses = () => (props.value ? "" : "invisible");

  const validationContent = () =>
    `${props.value.label.replace(/\(.*?\)/g, "")}`;

  return (
    <>
      <Select
        {...props}
        isClearable
        isSearchable={false}
        id={htmlId}
        styles={{
          option: (provided, { isSelected, isFocused }) => ({
            ...provided,
            backgroundColor: isSelected
              ? '#1c4cc3'
              : isFocused
              ? '#448eef'
              : "transparent",
          }),
        }}
        className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300"
        classNamePrefix="react-select"
        placeholder="Choose operator..."
        onChange={handleChange}
      />
      {props.value?.label && (
        <div
          className={`w-full m-auto mt-1 border rounded-md px-3 text-grey text-center ${validationClasses()}`}
        >
          {validationContent()}
        </div>
      )}
    </>
  );
};

OperatorSelect.defaultProps = {
  filterOption: (option: any) => true,
  isDisabled: false,
  options: [],
  value: undefined,
  onChange: undefined,
};

export default OperatorSelect;
