import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../components/DisclosureButton";

type Props = {
  open: boolean;
  onSubmit: (data: Object) => void;
  close: () => void;
  crmTrigger: Object;
};

const Name = ({ open, close, ...props }: Props) => {
  const [name, setName] = useState(props.crmTrigger?.["name"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (props.crmTrigger) setName(props.crmTrigger["name"]);
  }, [props.crmTrigger]);


  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onSubmit) {
      setIsSubmitting(true);
      props.onSubmit({ name });
      setIsSubmitting(false);
      setIsEditing(false);
    }
    close();
  };

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsEditing(true);
    setName(event.target.value);
  }

  const getState = () => {
    if (isSubmitting) {
      return "LOADING";
    } else if (name && !isEditing) {
      return "SUCCESS";
    } else {
      return "DEFAULT";
    }
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state={getState()}
        content={<span>Name</span>}
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <form className="form">
          {/* <Error content={error} /> */}
          <div className="space-y-1">
            <label
              htmlFor="crm_trigger_name"
              className="block text-sm font-medium dark:text-white text-gray-700"
            >
              Name
            </label>
            <input
              required
              disabled={isSubmitting}
              defaultValue={name}
              onChange={handleNameChange}
              className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
              placeholder="Enter a name for this trigger"
              type="text"
              name="crm_trigger[name]"
              id="crm_trigger_name"
            />
          </div>
          <div className="buttons">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitting}
              className={`${
                isSubmitting ? "disabled opacity-50 cursor-not-allowed" : ""
              } button`}
            >
              Save
            </button>
          </div>
        </form>
      </Disclosure.Panel>
    </>
  );
};

export default Name;
