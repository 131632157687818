import React, { useEffect, useState } from "react";
import { Dialog as Modal } from "@headlessui/react";
import { debounce } from "lodash";

import Close from "../../../../components/Close";
import Options from "../../../../components/Options";
import ButtonToggles from "../../../../components/ButtonToggles";
import { formatISO9075, parseJSON } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

type Props = {
  lineup: Object;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onUpdateLineup: (data: Object) => void;
};

const RoundRobinLineupSettings = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
    if (props.onOpen) props.onOpen();
  };

  const handleClose = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
  };

  const handleUpdateLineup = (data: Object) => {
    if (props.onUpdateLineup) {
      props.onUpdateLineup(data);
    }
  };

  return (
    <>
      <button type="button" className="button space-x" onClick={handleOpen}>
        <span className="text-white-500">
          <i className="fas fa-cog"></i>
        </span>
        <span>Round Robin Settings</span>
      </button>
      <Modal
        as="div"
        className="relative z-20 transition-all"
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Modal.Panel className="w-full lg:w-7/12 h-full transform overflow-hidden rounded-md bg-gray-100 dark:bg-sealBlue-200 text-left align-middle shadow-xl transition-all flex flex-col">
              <div className="flex flex-row items-center justify-between p-3 pr-5 bg-neutral-50 dark:bg-gray-600 border border-solid border-gray-300 dark:border-gray-500 rounded-t-md w-full">
                <Modal.Title as="h2" className="truncate w-3/4 font-semibold">
                  Round Robin Lineup Settings
                  <div className="text-xs italic font-light">
                    last updated:{" "}
                    {formatISO9075(
                      utcToZonedTime(
                        parseJSON(props.lineup["updated_at"]),
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    )}
                  </div>
                </Modal.Title>
                <Close onClick={handleClose} />
              </div>
              <div className="p-5 h-full overflow-auto flex-auto">
                <div className="h-full grid grid-cols-1 gap-y gap-x md:grid-cols-2">
                  <div className="md:col-span-1 pr-0 md:pr-10 md:border-r flex flex-col items-center space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium dark:text-white text-gray-700"
                        htmlFor="lineup_round_robin_optimization_scheme"
                      >
                        Optimize Round Robin for
                      </label>
                      <div className="mt-1.5">
                        <Options
                          onChange={(round_robin_optimization_scheme) => {
                            if (
                              props.lineup[
                                "round_robin_optimization_scheme"
                              ] !== round_robin_optimization_scheme
                            )
                              handleUpdateLineup({
                                round_robin_optimization_scheme,
                              });
                          }}
                          value={
                            props.lineup["round_robin_optimization_scheme"]
                          }
                          field="round_robin_optimization_scheme"
                          htmlPrefix="lineup"
                          options={[
                            {
                              label: "Availability",
                              value: "availability",
                              help: (
                                <>
                                  Inbound leads will be sent to the first
                                  available member, unless another member is
                                  also available—then, whichever member has
                                  higher priority will be assigned (you can
                                  assign priority for individual members by
                                  clicking their photo in the Lineup).
                                </>
                              ),
                            },
                            {
                              label: "Equal Distribution",
                              value: "equal_distribution",
                              help: (
                                <>
                                  Inbound leads will be sent to the first
                                  available team member, unless that team member
                                  has more meetings than your team's
                                  average&mdash;then, whichever available team
                                  member has fewer meetings will be assigned.
                                </>
                              ),
                            },
                          ]}
                        />
                      </div>
                      <p className="mt-1.5 text-xs text-gray-500"></p>
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium dark:text-white text-gray-700"
                        htmlFor="lineup_round_robin_rescheduling_scheme"
                      >
                        Reschedule preference
                      </label>
                      <div className="mt-1.5">
                        <Options
                          onChange={(round_robin_rescheduling_scheme) => {
                            if (
                              props.lineup[
                                "round_robin_rescheduling_scheme"
                              ] !== round_robin_rescheduling_scheme
                            )
                              handleUpdateLineup({
                                round_robin_rescheduling_scheme,
                              });
                          }}
                          value={
                            props.lineup["round_robin_rescheduling_scheme"]
                          }
                          field="round_robin_rescheduling_scheme"
                          htmlPrefix="lineup"
                          options={[
                            {
                              label: "Same Host",
                              value: "same_host",
                              help: "If an invitee reschedules an event, it will be assigned to the event's original host.",
                            },
                            {
                              label: "New Host",
                              value: "new_host",
                              help: "If an invitee reschedules an event, it will be assigned to a new host via round robin.",
                            },
                          ]}
                        />
                      </div>
                      <p className="mt-1.5 text-xs text-gray-500"></p>
                    </div>
                  </div>
                  <div className="md:col-span-1 pr-0 flex flex-col items-center space-y-4">
                    <div className="w-full">
                      <label
                        className="block text-sm font-medium dark:text-white text-gray-700"
                        htmlFor="lineup_booking_cap_enabled"
                      >
                        Enable Meeting Caps?
                      </label>
                      <div className="mt-1.5">
                        <ButtonToggles
                          onToggle={(booking_cap_enabled) => {
                            if (
                              props.lineup["booking_cap_enabled"] !==
                              booking_cap_enabled
                            )
                              handleUpdateLineup({
                                booking_cap_enabled:
                                  booking_cap_enabled === "true",
                              });
                          }}
                          value={props.lineup["booking_cap_enabled"]}
                          field="booking_cap_enabled"
                          htmlPrefix="lineup"
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                        />
                      </div>
                      <p className="mt-1.5 text-xs text-gray-500"></p>
                    </div>
                    {props.lineup["booking_cap_enabled"] && (
                      <>
                        <div className="w-full">
                          <label
                            className="block text-sm font-medium dark:text-white text-gray-700"
                            htmlFor="lineup_booking_cap"
                          >
                            Meeting Cap
                          </label>
                          <div className="mt-1.5">
                            <input
                              className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
                              type="number"
                              min="1"
                              id="lineup_booking_cap"
                              name="lineup[booking_cap]"
                              onChange={debounce((e) => {
                                const booking_cap = e.target.value;
                                if (props.lineup["booking_cap"] !== booking_cap)
                                  handleUpdateLineup({
                                    booking_cap,
                                  });
                              }, 500)}
                              defaultValue={props.lineup["booking_cap"]}
                            />
                          </div>
                          <p className="mt-1.5 text-xs text-gray-500">
                            If meeting caps are enabled, this will limit Members
                            to hosting a this number of meetings per period.
                            Members who exceed this limit will be skipped in the
                            Round Robin until the next period.
                          </p>
                        </div>
                        <div className="-mb-1.5">
                          <label
                            className="block text-sm font-medium dark:text-white text-gray-700"
                            htmlFor="lineup_booking_cap_period"
                          >
                            Meeting tally period
                          </label>
                          <div className="mt-1.5">
                            <Options
                              onChange={(booking_count_period) => {
                                if (
                                  props.lineup["booking_count_period"] !==
                                  booking_count_period
                                )
                                  handleUpdateLineup({
                                    booking_count_period,
                                  });
                              }}
                              value={props.lineup["booking_count_period"]}
                              field="booking_count_period"
                              htmlPrefix="lineup"
                              options={[
                                {
                                  label: "Week-to-week",
                                  value: "week",
                                  help: (
                                    <>
                                      If meeting caps are enabled,{" "}
                                      <span className="font-medium">
                                        the tallies of Members' hosted meetings
                                        will be reset at the start of each week.
                                      </span>
                                    </>
                                  ),
                                },
                                {
                                  label: "Month-to-month",
                                  value: "month",
                                  help: (
                                    <>
                                      If meeting caps are enabled,
                                      <span className="font-medium">
                                        the tallies of Members' hosted meetings
                                        will be reset at the start of each
                                        month.
                                      </span>
                                    </>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Panel>
          </div>
        </div>
      </Modal>
    </>
  );
};

RoundRobinLineupSettings.defaultProps = {
  isOpen: false,
  onOpen: undefined,
  onClose: undefined,
};

export default RoundRobinLineupSettings;
