import React, { useCallback, useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { get, put, destroy } from "@rails/request.js";

import DisclosureButton from "../../../../../../../../../components/DisclosureButton";
import Form from "./components/Form";
import Delete from "../../../../../../../../../components/Delete";
import CrmLink from "../../../../../../../../../../xkit/crm_link";
import Notice from "../../../../../../../../../../alerts/Notice";
import { isEmpty } from "lodash";

type Props = {
  open: boolean;
  close: () => void;
  rule: Object;
  fetchRule: () => void;
  handleRuleUpdate: (update: Object) => void;
};

const PostProcessing = ({ open, ...props }: Props) => {
  const [isConfirmed, setIsConfirmed] = useState(
    props.rule["post_processing_confirmed"]
  );
  const [disclosureState, setDisclosureState] = useState(
    props.rule["post_processing_confirmed"] ? "SUCCESS" : "DEFAULT"
  );
  const [prospectPostProcessings, setProspectPostProcessings] = useState(
    props.rule["prospect_post_processings"]
  );
  const [bookingPostProcessings, setBookingPostProcessings] = useState(
    props.rule["booking_post_processings"]
  );

  useEffect(() => {
    setIsConfirmed(props.rule["post_processing_confirmed"]);
    setDisclosureState(
      props.rule["post_processing_confirmed"] ? "SUCCESS" : "DEFAULT"
    );
  }, [props]);

  const handleRemove = async (postProcessing) => {
    const postProcessingPath = `/account/routers/rules/post_processings/${postProcessing["id"]}`;
    setDisclosureState("LOADING");
    const response = await destroy(`${postProcessingPath}.json`);
    if (response.ok) {
      if (postProcessing["model_type"] === "prospect")
        setProspectPostProcessings((prev) =>
          prev.filter((pp) => postProcessing["id"] !== pp["id"])
        );
      else if (postProcessing["model_type"] === "booking")
        setBookingPostProcessings((prev) =>
          prev.filter((pp) => postProcessing["id"] !== pp["id"])
        );
      if (props.fetchRule) props.fetchRule();
    } else {
      const error = await response.json;
      console.error(error);
      setDisclosureState("DEFAULT");
    }
  };

  const fetchPostProcessings = useCallback(async () => {
    const rulePostProcessingsPath = `/account/routers/rules/${props.rule["id"]}/post_processings`;
    setDisclosureState("LOADING");
    const response = await get(`${rulePostProcessingsPath}.json`);
    if (response.ok) {
      const postProcessings = await response.json;
      setProspectPostProcessings(
        postProcessings.filter(
          (postProcessing) => postProcessing["model_type"] === "prospect"
        )
      );
      setBookingPostProcessings(
        postProcessings.filter(
          (postProcessing) => postProcessing["model_type"] === "booking"
        )
      );
      if (props.fetchRule) props.fetchRule();
    } else {
      const error = await response.json;
      console.error(error);
      setDisclosureState("DEFAULT");
    }
  }, [props.rule]);

  const handleConfirmation = () => {
    if (props.handleRuleUpdate) {
      setDisclosureState("LOADING");
      props.handleRuleUpdate({ post_processing_confirmed: true });
    }
  };

  const hasProspectPostProcessings = () =>
    !!prospectPostProcessings && !isEmpty(prospectPostProcessings);

  const hasBookingPostProcessings = () =>
    !!bookingPostProcessings && !isEmpty(bookingPostProcessings);

  const hasPostProcessings = () =>
    hasProspectPostProcessings() || hasBookingPostProcessings();

  return (
    <>
      <DisclosureButton
        open={open}
        state={disclosureState}
        content="Write to CRM Fields (Optional)"
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <Notice>
          If you do not see the property which you would like to write to your
          CRM, try reconnecting your CRM to Salesform in your{" "}
          <a href={`/account/teams/${props.rule["team_id"]}/edit`}>
            Team Settings
          </a>
          .
        </Notice>
        <div className="space-y-4">
          <div className="font-bold text-md">Leads</div>
          <div
            id={`routers_rule_${props.rule["id"]}_prospect_post_processings`}
            className="space-y-8"
          >
            {prospectPostProcessings
              ?.sort((a, b) => a["id"] - b["id"])
              ?.map((postProcessing) => (
                <div key={postProcessing["id"]} className="grid grid-cols-12">
                  <div className="col-span-11">
                    <Form
                      rule={props.rule}
                      modelType="prospect"
                      postProcessing={postProcessing}
                      fetchPostProcessings={fetchPostProcessings}
                    />
                  </div>
                  <div className="col-span-1">
                    <div className="m-auto w-min">
                      <Delete
                        tooltipContent="Remove"
                        onClick={() => handleRemove(postProcessing)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            <div className="grid grid-cols-12">
              <div className="col-span-11">
                <Form
                  rule={props.rule}
                  postProcessing={null}
                  modelType="prospect"
                  fetchPostProcessings={fetchPostProcessings}
                />
              </div>
              <div className="col-span-1"></div>
            </div>
          </div>
        </div>
        {!!props.rule["destination_event_type"] && (
          <div className="space-y-4">
            <div className="font-bold text-md">Meetings</div>
            <div
              id={`routers_rule_${props.rule["id"]}_booking_post_processings`}
              className="space-y-8"
            >
              {bookingPostProcessings
                ?.sort((a, b) => a["id"] - b["id"])
                ?.map((postProcessing) => (
                  <div key={postProcessing["id"]} className="grid grid-cols-12">
                    <div className="col-span-11">
                      <Form
                        rule={props.rule}
                        modelType="booking"
                        postProcessing={postProcessing}
                        fetchPostProcessings={fetchPostProcessings}
                      />
                    </div>
                    <div className="col-span-1">
                      <div className="m-auto w-min">
                        <Delete
                          tooltipContent="Remove"
                          onClick={() => handleRemove(postProcessing)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              <div className="grid grid-cols-12">
                <div className="col-span-11">
                  <Form
                    rule={props.rule}
                    postProcessing={null}
                    modelType="booking"
                    fetchPostProcessings={fetchPostProcessings}
                  />
                </div>
                <div className="col-span-1"></div>
              </div>
            </div>
          </div>
        )}
        <div className="buttons" hidden={isConfirmed}>
          <CrmLink
            buttonText="Confirm"
            teamID={props.rule["team_id"]}
            afterClick={handleConfirmation}
            platformDomain={process.env.REACT_APP_XKIT_PLATFORM_DOMAIN}
          />

          {!hasPostProcessings() && (
            <button className="button-secondary" onClick={handleConfirmation}>
              Skip
            </button>
          )}
        </div>
      </Disclosure.Panel>
    </>
  );
};

export default PostProcessing;
