import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { isHubspotForm: Boolean };
  static targets = ["pageUrlFieldWrapper", "pageUrlField", "formUrlField"];

  copyValueToPageUrlField(event) {
    if (this.hasPageUrlFieldTarget)
      this.pageUrlFieldTarget.value = event.target.value;
  }

  formUrlFieldTargetConnected(formUrlField) {
    if (!this.isHubspotFormValue) {
      this.copyFunc = this.copyValueToPageUrlField.bind(this);
      formUrlField.addEventListener("input", this.copyFunc);
    }
  }

  handleIsHubspotFormChange(event) {
    event.preventDefault();
    event.stopPropagation();

    const originalEvent = event.detail;
    this.isHubspotFormValue = originalEvent.target.value === "true";
  }

  isHubspotFormValueChanged(checked, prevChecked) {
    if (!this.hasPageUrlFieldWrapperTarget) return;

    this.pageUrlFieldWrapperTarget.hidden = !checked;
    if (!checked && prevChecked) {
      if (this.hasFormUrlFieldTarget && !!this.pageUrlFieldTarget.value) {
        this.formUrlFieldTarget.addEventListener("input", this.copyFunc);
        this.formUrlFieldTarget.value = this.pageUrlFieldTarget.value;
        this.pageUrlFieldTarget.value = "";
      }
      this.pageUrlFieldTarget.required = false;
    } else if (checked && !prevChecked) {
      if (this.hasFormUrlFieldTarget && !!this.formUrlFieldTarget.value) {
        this.formUrlFieldTarget.removeEventListener("input", this.copyFunc);
        this.pageUrlFieldTarget.value = this.formUrlFieldTarget.value;
        if (this.formUrlFieldTarget.value === this.pageUrlFieldTarget.value)
          this.formUrlFieldTarget.value = "";
      }
      this.pageUrlFieldTarget.required = true;
    }
  }
}
