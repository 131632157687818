import React, { useContext, useEffect, useState } from "react";
import { Dialog as Modal } from "@headlessui/react";

import Close from "../../../../../../../../../../components/Close";
import LineupWizard from "../../../../../../../../../../LineupWizard";

type Props = {
  lineup: Object;
  isOpen?: boolean;
  onClose: () => void;
};

// TODO somehow pass the updated lineup back to LineupSelect so the value
// passed to the underyling AsyncSelect can also be updated

const LineupModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [lineup, setLineup] = useState(props.lineup);

  useEffect(() => {
    setLineup(props.lineup);
  }, [props.lineup]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    if (props.onClose) props.onClose();
    setIsOpen(false);
  };

  const handleLineupChange = (lineup: Object) => {
    setLineup(lineup);
  };

  if (lineup)
    return (
      <Modal
        as="div"
        className="relative z-10 transition-all"
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Modal.Panel className="w-5/6 h-full transform overflow-hidden rounded-md bg-gray-100 dark:bg-sealBlue-200 text-left align-middle shadow-xl transition-all flex flex-col">
              <div className="flex flex-row items-center justify-between p-3 pr-5 bg-neutral-50 dark:bg-gray-600 border border-solid border-gray-300 dark:border-gray-500 rounded-t-md w-full">
                <Modal.Title as="h2" className="truncate w-3/4 font-semibold">
                  {lineup["name"]}
                </Modal.Title>
                <Close onClick={handleClose} />
              </div>
              <div className="p-5 h-full overflow-auto flex-auto">
                <LineupWizard
                  teamId={lineup["team_id"]}
                  lineup={props.lineup}
                  isContainerOpen={true}
                  onLineupChange={handleLineupChange}
                />
              </div>
            </Modal.Panel>
          </div>
        </div>
      </Modal>
    );

  return null;
};

LineupModal.defaultProps = {
  isOpen: false,
  disabled: false,
};

export default LineupModal;
