import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

// Connects to data-controller="routers--rules--post-processing--form"
export default class extends Controller {
  static values = { field: String, value: String, mode: String };
  connect() {
    this.initValueInput();
  }

  initValueInput() {
    const controller = this;
    this.valueInput = this.element.querySelector(
      'input[name="routers_rules_post_processing[value]"]'
    );

    // submit when user stops typing a value
    this.valueInput.addEventListener(
      "keyup",
      debounce((event) => {
        controller.valueValue = event.target.value;
        controller.submitForm();
      }, 500)
    );

    // handle non-keyboard events and changing focus
    this.valueInput.addEventListener("input", controller.submitForm);
    this.valueInput.addEventListener("blur", controller.submitForm);
  }

  fieldValueChanged(value, prevValue) {
    if (!prevValue && this.modeValue === "update") return;
    const changed = !!value && prevValue !== value;
    if (changed) this.submitForm();
  }

  setFieldValue({ detail: { event } }) {
    this.fieldValue = $(event.target).select2("data")[0].id;
  }

  submitForm() {
    if (!this.hasFieldValue) return;
    this.element.requestSubmit();
  }
}
