import React from "react";
import Select, { ActionMeta } from "react-select";
import RouterTriggerContext from "../../../../../../../../../../contexts/RouterTriggerContext";

type Props = {
  rule: Object;
  onChange: (value: any) => void;
  value?: any;
};

const DestinationTypeSelect = ({ onChange, ...props }: Props) => {
  const triggerContext = React.useContext(RouterTriggerContext);
  const handleChange = (selection: any, _action: ActionMeta<any>) => {
    if (onChange) onChange(selection);
  };

  const options = () => {
    if (triggerContext.triggerType === "CrmTrigger")
      return [
        { value: "membership", label: "Assign Team Member" },
        { value: "lineup", label: "Send to Lineup" },
      ];
    if (props.rule["action"] === "ignore")
      return [{ value: "url", label: "Redirect to URL" }];
    return [
      { value: "membership", label: "Assign Team Member" },
      { value: "url", label: "Redirect to URL" },
      { value: "lineup", label: "Send to Lineup" },
    ];
  };

  return (
    <Select
      {...props}
      isSearchable={false}
      options={options()}
      styles={{
        option: (provided, { isSelected, isFocused }) => ({
          ...provided,
          backgroundColor: isSelected
            ? '#1c4cc3'
            : isFocused
            ? '#448eef'
            : "transparent",
        }),
      }}
      className="react-select-container block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900"
      classNamePrefix="react-select"
      placeholder="Where should the lead be routed to?"
      onChange={handleChange}
    />
  );
};

export default DestinationTypeSelect;
