import axios, { Method } from "axios";

export const requestUrl = (hostUrl, path = "") => {
  const { protocol, host } = new URL(hostUrl);
  return `${protocol}//${host}${path}`;
};

export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const csrf = () =>
  document.querySelector("meta[name='csrf-token']").getAttribute("content");

export const request = async (
  hostUrl: string,
  path: string,
  data: any = {},
  method: Method = "POST",
  headers: any = {
    ...defaultHeaders,
    "X-CSRF-Token": csrf(),
  }
) =>
  axios({
    method,
    url: requestUrl(hostUrl, path),
    data,
    headers,
    transformResponse: (response) => {
      if (!response) return null;
      const parsedResponse = JSON.parse(response);
      if (Array.isArray(parsedResponse) && parsedResponse[0] > 400)
        throw new Error(JSON.parse(parsedResponse[2][0]));
      else return parsedResponse;
    },
  });
