import React from "react";
import { eachMinuteOfInterval, endOfDay, format, startOfDay } from "date-fns";
import Select from "./base/Select";

type Props = {
  error?: boolean;
  errorMessage: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
};

const TimePicker = ({ errorMessage, ...selectProps }: Props) => {
  const options = () => {
    const now = new Date();
    const interval = {
      start: startOfDay(now),
      end: endOfDay(now),
    };
    return eachMinuteOfInterval(interval, { step: 30 }).map((o) => ({
      key: format(o, "HH:mm"),
      label: format(o, "h:mmaaa"),
      text: format(o, "h:mmaaa"),
      value: format(o, "HH:mm"),
    }));
  };

  return (
    <div className="has-tooltip">
      {selectProps.error && (
        <span className="tooltip rounded shadow-lg p-2 bg-black bg-opacity-75 text-white -mt-10">
          {errorMessage}
        </span>
      )}
      <Select {...selectProps} options={options()} />
    </div>
  );
};

TimePicker.defaultProps = {
  error: false,
};

export default TimePicker;
