import React, { ReactNode } from "react";

type Props = {
  field?: string;
  htmlPrefix?: string;
  value?: string;
  onChange: (value: string) => void;
  options: { value: any; label: string; disabled?: boolean, help?: ReactNode }[];
};

const Options = (props: Props) => {
  return (
    <div
      id={`${props.htmlPrefix}_${props.field}`}
      className="pt-1.5 pb-1 sm:col-span-2"
    >
      <div className="max-w-lg space-y-3">
        {props.options.map((option) => (
          <label
            key={`${props.htmlPrefix}_${props.field}_${option.value}`}
            className="relative flex items-start cursor-pointer"
          >
            <div className="flex items-center h-5">
              <input
                className="focus:ring-blue h-4 w-4 text-blue border-gray-300 cursor-pointer disabled:cursor-not-allowed"
                type="radio"
                value={option.value}
                checked={props.value === option.value}
                onChange={() => props.onChange(option.value)}
                name={`${props.htmlPrefix}[${props.field}]`}
                disabled={option.disabled}
                id={`${props.htmlPrefix}_${props.field}_${option.value}`}
              />
            </div>
            <div className="ml-2.5 text-sm">
              <div className="select-none">{option.label}</div>
              <div className="mt-0.5 text-xs text-gray-500">{option.help}</div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Options;
