import React, { useState } from "react";
import { Dialog as Modal } from "@headlessui/react";

import Edit from "../../../../../../components/Edit";
import Close from "../../../../../../components/Close";
import RuleWizard from "./RuleWizard";

type Props = {
  rule: Object;
  disabled?: boolean;
  onUpdateRule: (data: Object) => void;
};

const EditModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Edit disabled={props.disabled} onClick={handleOpen} />
      <Modal
        as="div"
        className="relative z-10 transition-all"
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Modal.Panel className="w-5/6 h-full transform overflow-hidden rounded-md bg-gray-100 dark:bg-sealBlue-200 text-left align-middle shadow-xl transition-all flex flex-col">
              <div className="flex flex-row items-center justify-between p-3 pr-5 bg-neutral-50 dark:bg-gray-600 border border-solid border-gray-300 dark:border-gray-500 rounded-t-md w-full">
                <Modal.Title as="h2" className="truncate w-3/4 font-semibold">
                  {props.rule["name"]}
                </Modal.Title>
                <Close onClick={handleClose} />
              </div>
              <div className="p-5 h-full overflow-auto flex-auto">
                <RuleWizard rule={props.rule} onUpdate={props.onUpdateRule} />
              </div>
            </Modal.Panel>
          </div>
        </div>
      </Modal>
    </>
  );
};

EditModal.defaultProps = {
  disabled: false,
};

export default EditModal;
