import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "header", "panel", "stateText", "stateIcon"];
  static classes = [
    "loadingStateText",
    "loadingStateIcon",
    "successStateText",
    "successStateIcon",
    "defaultStateText",
    "defaultStateIcon",
    "warningStateText",
    "warningStateIcon",
    "containerIsClosed",
    "headerIsOpen",
  ];
  static values = {
    state: { type: String, default: "DEFAULT" },
    isOpen: { type: Boolean, default: true },
  };

  initialize() {}

  connect() {
    if (this.isOpenValue) {
      this.headerTarget.classList.add(...this.headerIsOpenClasses);
      this.containerTarget.classList.remove(...this.containerIsClosedClasses);
      this.panelTarget.classList.remove("hidden");
      this.stateTextTarget.classList.add("hidden");
    }
  }

  disconnect() {}

  open() {
    this.isOpenValue = true;
  }

  close() {
    this.isOpenValue = false;
  }

  toggle() {
    if (this.isOpenValue) this.close();
    else this.open();
  }

  isOpenValueChanged(isOpen, wasOpen) {
    if (isOpen) {
      this.headerTarget.classList.add(...this.headerIsOpenClasses);
      this.containerTarget.classList.remove(...this.containerIsClosedClasses);
      this.panelTarget.classList.remove("hidden");
      this.stateTextTarget.classList.add("hidden");
    } else {
      this.containerTarget.classList.add(...this.containerIsClosedClasses);
      this.headerTarget.classList.remove(...this.headerIsOpenClasses);
      this.panelTarget.classList.add("hidden");
      this.stateTextTarget.classList.remove("hidden");
    }
  }

  stateValueChanged(state, _oldState) {
    if (
      !this.hasStateValue ||
      !this.hasStateTextTarget ||
      !this.hasStateIconTarget
    )
      return;

    switch (state) {
      case "loading":
        this.stateTextTarget.className = this.loadingStateTextClasses.join(" ");
        this.stateIconTarget.className = this.loadingStateIconClasses.join(" ");
        return;
      case "success":
        this.stateTextTarget.className = this.successStateTextClasses.join(" ");
        this.stateIconTarget.className = this.successStateIconClasses.join(" ");
        return;
      default:
        this.stateTextTarget.className = this.defaultStateTextClasses.join(" ");
        this.stateIconTarget.className = this.defaultStateIconClasses.join(" ");
    }
  }
}
