import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { request } from "../packs/lib/web";
import { isEmpty } from "lodash";

export default class extends Controller {
  static values = {
    // prospect: Object,
    teamId: String,
    prospectId: String,
    bookingId: String,
    eventTypeId: String,
    lineupId: String,
    formResponseId: String,
    ruleId: String,
    prospectsAssignmentLogId: String,
    attendeeMembershipIds: Array,
    // customInputs: Array,
    date: String,
  };

  connect() {
    if (!this.data.get("initialized")) this.initBookingConfirmation();
    // console.log(this)
  }

  disconnect() {
    this.tearDownBookingConfirmation();
  }

  getForm() {
    return document.querySelector("#booking-confirmation-form");
  }

  // paramsFromCustomInputs = (e) => {
  //   if (this.customInputsValue.length > 0) {
  //     const entries = this.customInputsValue.map((input) => {
  //       const htmlInput = e.currentTarget[`custom_${input.name}`];
  //       // if htmlInput is not hidden and rendered pull its value from the DOM
  //       if (htmlInput)
  //         return [
  //           input.name,
  //           input.field_type === "boolean"
  //             ? htmlInput.checked
  //             : htmlInput.value,
  //         ];
  //       // otherwise, it is a hidden field. pull the value from the prospectValue or use an empty/false value
  //       else if (input.hidden) {
  //         if (
  //           this.prospectIdValue &&
  //           this.prospectValue &&
  //           this.prospectValue.info[input.name]
  //         )
  //           return [input.name, this.prospectValue.info[input.name]];
  //         else return [input.name, input.field_type === "boolean" ? false : ""];
  //       }
  //     });
  //     return Object.fromEntries(new Map(entries));
  //   }
  // };

  // createFormResponse = async (e) => {
  //   if (this.customInputsValue.length > 0) {
  //     const [{ salesform_id }, ..._rest] = this.customInputsValue;
  //     const params = {
  //       ...this.paramsFromCustomInputs(e),
  //       source: "booking_link",
  //     };
  //     return await request(
  //       window.location.origin,
  //       `/api/v1/salesforms/${salesform_id}/form_responses`,
  //       this.prospectIdValue
  //         ? { ...params, prospect_id: this.prospectIdValue }
  //         : params
  //     );
  //   }
  //   return null;
  // };

  prospectFromDefaultForm = (form) => {
    let prospect = {};
    if (form.first_name)
      prospect = { ...prospect, first_name: form.first_name.value };

    if (form.last_name)
      prospect = { ...prospect, last_name: form.last_name.value };
    if (form.email) prospect = { ...prospect, email: form.email.value };

    return prospect;
  };

  guestEmailsFromForm = (form) =>
    form.guest_emails.value.split(",").filter((email) => String(email).trim());

  createBooking = async (e) => {
    let bookingPromise;
    const params = {
      start_at: this.dateValue,
      event_type_id: this.eventTypeIdValue,
      lineup_id: this.lineupIdValue,
      creator_id: this.attendeeMembershipIdsValue[0],
      membership_ids: this.attendeeMembershipIdsValue,
      form_response_id: this.formResponseIdValue,
      rule_id: this.ruleIdValue,
      prospects_assignment_log_id: this.prospectsAssignmentLogIdValue,
      prospect_emails: this.guestEmailsFromForm(e.currentTarget),
    };

    if (this.prospectIdValue) {
      bookingPromise = request(
        window.location.origin,
        `/api/v1/teams/${this.teamIdValue}/bookings`,
        { ...params, prospect_ids: [this.prospectIdValue] }
      );
    } else {
      const prospect = this.prospectFromDefaultForm(e.currentTarget);
      bookingPromise = request(
        window.location.origin,
        `/api/v1/teams/${this.teamIdValue}/bookings`,
        { ...params, prospect }
      );
    }

    bookingPromise
      .then(({ data }) => {
        if (data.id || data.data.id) {
          const url = new URL(location.href);
          const dateParam = url.searchParams.get("date");
          const ruleIdParam = url.searchParams.get("rule_id");
          url.search = "";
          url.searchParams.set("date", dateParam);
          if (ruleIdParam) url.searchParams.set("rule_id", ruleIdParam);
          url.searchParams.set("booking_id", data.id || data.data.id);
          url.pathname = `/book/u/${this.attendeeMembershipIdsValue[0]}/success`;
          Turbo.visit(url.href, { action: "replace" });
        } else {
          const url = new URL(document.referrer);
          url.searchParams.set("error", JSON.parse(data.data[2][0])["error"]);
          Turbo.visit(url.href, { action: "replace" });
        }
      })
      .catch(console.error);
  };

  updateBooking = async (e) => {
    let bookingPromise;
    const params = {
      start_at: this.dateValue,
      prospect_emails: this.guestEmailsFromForm(e.currentTarget),
    };

    // if (this.customInputsValue.length > 0) {
    //   const {
    //     data: { prospect_id },
    //   } = await this.createFormResponse(e);
    //   bookingPromise = request(
    //     window.location.origin,
    //     `/api/v1/bookings/${this.bookingIdValue}`,
    //     { ...params, prospect_id },
    //     "PUT"
    //   );
    // }

    const prospect = this.prospectFromDefaultForm(e.currentTarget);
    bookingPromise = request(
      window.location.origin,
      `/api/v1/bookings/${this.bookingIdValue}`,
      isEmpty(prospect) ? { ...params } : { ...params, prospect },
      "PUT"
    );
    bookingPromise
      .then(({ data }) => {
        if (data.id || data.data.id) {
          const url = new URL(location.href);
          const dateParam = url.searchParams.get("date");
          url.search = "";
          url.searchParams.set("date", dateParam);
          url.pathname = `/reschedule/${data.id || data.data.id}/success`;
          Turbo.visit(url.href, { action: "replace" });
        } else {
          const url = new URL(document.referrer);
          url.searchParams.set("error", JSON.parse(data.data[2][0])["error"]);
          Turbo.visit(url.href, { action: "replace" });
        }
      })
      .catch(console.error);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("submit").className += " cursor-not-allowed";
    document.getElementById("submit-loader").className.baseVal = document
      .getElementById("submit-loader")
      .className.baseVal.replace("hidden", "");
    this.bookingIdValue ? this.updateBooking(e) : this.createBooking(e);
  };

  initBookingConfirmation() {
    // if (this.customInputsValue.length > 0) {
    //   const urlFields = this.customInputsValue.filter(
    //     (input) => !input.hidden && input.field_type === "url"
    //   );
    //   if (urlFields.length > 0) {
    //     const urlInputs = urlFields.map((input) =>
    //       document.getElementById(`custom_${input.name}`)
    //     );

    //     const clearCustomValidity = (e) => {
    //       const target = e.target;
    //       if (target) target.setCustomValidity("");
    //     };

    //     const handleInvalidURLInput = (e) => {
    //       const target = e.target;
    //       if (target) {
    //         if (target.validity.patternMismatch || target.validity.typeMismatch)
    //           target.setCustomValidity(
    //             'Please enter a valid URL that begins with "https://" or "http://"'
    //           );
    //         else target.setCustomValidity("");
    //       }
    //     };

    //     urlInputs.forEach((input) => {
    //       input.addEventListener("invalid", handleInvalidURLInput);
    //       input.addEventListener("input", clearCustomValidity);
    //     });
    //   }
    // }

    this.getForm().addEventListener("submit", this.handleSubmit);

    // Mark that we've been initialized
    this.data.set("initialized", true);
  }

  tearDownBookingConfirmation() {
    this.data.set("initialized", false);
  }
}
