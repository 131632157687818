import React, { ReactNode } from "react";

type Props = {
  name: string;
  label: string | ReactNode;
  checked: boolean;
  onChange: (target: EventTarget & HTMLInputElement) => void;
};

const Checkbox = ({ label, onChange, ...inputProps }: Props) => {
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target);
  };
  return (
    <div className="flex items-start">
      <div className="h-5 flex items-center">
        <input
          {...inputProps}
          onChange={handleChange}
          type="checkbox"
          className="focus:ring-blue-dark h-4 w-4 text-blue border-gray-300 rounded cursor-pointer"
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={inputProps.name}
          className={inputProps.checked ? "text-gray-700 dark:text-gray-300" : "text-gray-500 dark:text-gray-500"}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
