import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { startAt: String };

  setTime(event) {
    event.preventDefault()
    const startAtField = document.getElementById('booking_start_at')
    startAtField.value = this.startAtValue;
    $(startAtField).trigger('change');
  }

  setClass(event) {
    event.preventDefault()
    document.querySelectorAll(`[data-controller="${this.identifier}"]`).forEach(el => {
      $(el).removeClass("button").addClass("button-alternative");
    })
    $(this.element).removeClass("button-alternative").addClass("button");
  }
}
