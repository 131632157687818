import { debounce } from "lodash";
import React, { useEffect, useState, useReducer } from "react";
import { csrf } from "../../../../../../../lib/web";

type Props = {
  router: Object;
};

const RouterSearch = (props: Props) => {
  const [query, setQuery] = useState("");
  const [selectedRouter, setSelectedRouter] = useState(null);
  const [selectedRules, setSelectedRules] = useState([]);
  const [searchResults, setSearchResults] = useState({
    pagination: { more: true },
    results: [],
  });

  const getSearchResults = async () => {
    const options = { method: "GET", headers: { "X-CSRF-Token": csrf() } };
    const response = await fetch(
      `/account/teams/${
        props.router["team_id"]
      }/routers/search?limit=20&excluded_ids[]=${
        props.router["id"]
      }&trigger_type=${props.router["trigger_type"]}&${
        query ? `query=${query}` : ""
      }`,
      options
    );
    return await response.json();
  };

  const getSelectedRouterRules = async () => {
    if (!selectedRouter) return [];

    const options = { method: "GET", headers: { "X-CSRF-Token": csrf() } };
    const response = await fetch(
      `/account/routers/${props.router["id"]}/rules.json`,
      options
    );
    return await response.json();
  };

  useEffect(() => {
    const fetch = async () => {
      const { pagination, results } = await getSearchResults();
      setSearchResults((prev) => ({ ...prev, pagination, results }));
      setSelectedRouter(null);
    };

    void fetch();
  }, [query]);

  useEffect(() => {
    const fetch = async () => {
      const selectedRouterRules = await getSelectedRouterRules();
      setSelectedRules(selectedRouterRules);
    };

    void fetch();
  }, [selectedRouter]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setSearchResults((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, more: true },
    }));
  };

  return (
    <div className="space-y-3">
      <input
        className={`block w-full rounded-md shadow-sm font-light text-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900`}
        type="text"
        name="query"
        placeholder="Search for Routers"
        onChange={debounce(handleQueryChange, 500)}
      />
      <div className="grid grid-cols-2 gap-x">
        <div className="overflow-auto space-y-2">
          {searchResults.results.map((router) => (
            <div
              className={`cursor-pointer text-lg border p-4 shadow-md bg-white dark:bg-sealBlue-300 rounded-md focus:ring-blue focus:border-blue ${
                selectedRouter?.id == router.id &&
                `ring-blue border-blue dark:border-sealBlue-100`
              }`}
              key={router.id}
              onClick={() => setSelectedRouter(router)}
            >
              {router.text}
            </div>
          ))}
        </div>
        <div className="overflow-auto ">
          {selectedRules.map((rule) => (
            <div className="cursor-pointer" key={rule.id}>
              {rule.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default RouterSearch;
