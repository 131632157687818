import React, { useEffect, useState } from "react";

type Props = {
  id: string;
  height?: string;
  data: string | JSON;
  syntaxHighlighter: (data: any) => string;
};

const Debugger = ({ syntaxHighlighter, ...props }: Props) => {
  const setContent = () => {
    const pre = document.querySelector(`#${props.id} > pre`) as HTMLPreElement;
    pre.innerHTML = syntaxHighlighter
      ? syntaxHighlighter(props.data)
      : props.data as string;
  };

  useEffect(setContent, [props.data, syntaxHighlighter]);

  return (
    <div
      id={props.id}
      className="overflow-y-auto w-full block w-full rounded-md shadow-sm font-light text-sm border p-2"
      style={{ height: props.height }}
    >
      <pre className="whitespace-pre-wrap"></pre>
    </div>
  );
};

Debugger.defaultProps = {
  height: "400px",
};

export default Debugger;
