import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";

import DisclosureButton from "../../../../../../../../components/DisclosureButton";

type Props = {
  open: boolean;
  onUpdate: (data: Object) => void;
  close: () => void;
  rule: Object;
};

const Name = ({ open, close, ...props }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onUpdate)
      props.onUpdate({ name: event.target["rule[name]"].value });
    close();
  };

  return (
    <>
      <DisclosureButton
        open={open}
        state="SUCCESS"
        content={
          props.rule?.["name"] ? (
            <span>
              Name:{" "}
              <span className="font-light italic">{props.rule?.["name"]}</span>
            </span>
          ) : (
            <span>Name</span>
          )
        }
      />
      <Disclosure.Panel unmount={false} className="p-8 space-y-4">
        <form className="form" onSubmit={handleSubmit}>
          {/* <Error content={error} /> */}
          <div className="space-y-1">
            <label
              htmlFor="rule_name"
              className="block text-sm font-medium dark:text-white text-gray-700"
            >
              Name
            </label>
            <input
              required
              disabled={isSubmitting}
              defaultValue={props.rule["name"]}
              className="shadow-sm focus:ring-blue focus:border-blue border-gray-300 dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-md w-full"
              placeholder="Enter a name for this rule"
              type="text"
              name="rule[name]"
              id="rule_name"
            />
          </div>
          <div className="buttons">
            <input
              type="submit"
              value="Save"
              disabled={isSubmitting}
              className={`${
                isSubmitting ? "disabled opacity-50 cursor-not-allowed" : ""
              } button`}
            />
          </div>
        </form>
      </Disclosure.Panel>
    </>
  );
};

export default Name;
