import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { prospectId: String };

  subscribeToChannel() {
    if (!this.hasProspectIdValue) return;
    const self = this;

    consumer.subscriptions.create(
      {
        channel: "Enrichment::ProspectChannel",
        prospect_id: self.prospectIdValue,
      },
      {
        received(_data) {
          get(
            `/account/prospects/${self.prospectIdValue}/enrichment`,
            { responseKind: "turbo-stream" }
          );
        },
      }
    );
  }

  connect() {
    this.subscribeToChannel();
  }
}
