import React from "react";

type Props = {
  state: string;
};

const StateIcon = ({ state }: Props) => {
  switch (state) {
    case "LOADING":
      return <i className="fas fa-circle-notch fa-spin fa-2x"></i>;
    case "SUCCESS":
      return (
        <span className="text-green-dark">
          <i className={`fas fa-check-circle fa-2x bg-white rounded-full`} />
        </span>
      );
    default:
      return (
        <span className="text-yellow-dark">
          <i className={`fas fa-exclamation-circle fa-2x bg-white rounded-full`} />
        </span>
      );
  }
};

export default StateIcon;
