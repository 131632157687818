import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {

  updateStatus({ detail: { event } }) {
    const [{ id }] = $(event.target).select2("data");
    switch (id) {
      case "cancelled":
        post(this.element.action + "/cancel");
        return;

      case "reject":
        post(this.element.action + "/reject");
        return;

      case "accept":
        post(this.element.action + "/confirm");
        return;

      case "no_show":
        post(this.element.action + "/no_show");
        return;

      default:
        this.element.requestSubmit();
    }
  }
}
