import { autocomplete } from "@algolia/autocomplete-js";

// Set the InstantSearch index UI state from external events.
export default function initAutocomplete({
  search: search = undefined,
  indexName: indexName = "",
  instantSearchRouter: instantSearchRouter = undefined,
  containerSelector: containerSelector = "#autocomplete",
  placeholder: placeholder = "Search",
}) {
  function setInstantSearchUiState(indexUiState) {
    search.setUiState((uiState) => ({
      ...uiState,
      [indexName]: {
        ...uiState[indexName],
        // We reset the page when the search state changes.
        page: 1,
        ...indexUiState,
      },
    }));
  }

  // Return the InstantSearch index UI state.
  function getInstantSearchUiState() {
    const uiState = instantSearchRouter.read();

    return (uiState && uiState[indexName]) || {};
  }

  const searchPageState = getInstantSearchUiState();

  autocomplete({
    container: containerSelector,
    placeholder,
    detachedMediaQuery: "none",
    classNames: {
      inputWrapperPrefix: "dark:text-gray-600",
      label: "dark:text-gray-600",
      submitButton: "dark:text-gray-600",
      form: "text-lg border-2 border-gray-300 bg-white dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-lg text-gray-400 focus:outline-none w-full",
      input:
        "text-lg border-2 border-gray-300 bg-white dark:bg-sealBlue-300 dark:border-sealBlue-100 dark:text-sealBlue-900 rounded-r-lg text-gray-400 focus:outline-none w-full",
    },
    initialState: {
      query: searchPageState.query || "",
    },
    onSubmit({ state }) {
      setInstantSearchUiState({ query: state.query });
    },
    onReset() {
      setInstantSearchUiState({ query: "" });
    },
    onStateChange({ prevState, state }) {
      if (prevState.query !== state.query) {
        setInstantSearchUiState({ query: state.query });
      }
    },
  });
}
